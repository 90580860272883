import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from 'prop-types';


export default function AlertsDialog({controlObject , setFunc}) {

  const { open, msg, title, showButtontOne, buttontOneTitle, buttonOneFun, showButtontTow, buttontTowTitle, buttonTowFun } = controlObject
  
  const handleClose = () => {
    setFunc({...controlObject,"open":false})
  };

  return (

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ display: !showButtontOne  && 'none' }} onClick={buttonOneFun}>{buttontOneTitle}</Button>
        <Button sx={{ display: !showButtontTow && 'none' }} onClick={buttonTowFun} autoFocus>
          {buttontTowTitle}
        </Button>
      </DialogActions>
    </Dialog>



  )

}


AlertsDialog.propTypes = {
  controlObject: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    msg: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showButtontOne: PropTypes.bool.isRequired,
    buttontOneTitle: PropTypes.string.isRequired,
    buttonOneFun: PropTypes.func.isRequired,
    showButtontTow: PropTypes.bool.isRequired,
    buttontTowTitle: PropTypes.string.isRequired,
    buttonTowFun: PropTypes.func.isRequired
  }) ,

  setFunc : PropTypes.func.isRequired




};