import { CacheProvider } from '@emotion/react'
import { StylesProvider } from '@mui/styles'
import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { useTranslation } from 'react-i18next';
import rtlPlugin from "stylis-plugin-rtl";
import {  useNavigate } from 'react-router-dom';
import { create } from "jss";
import { jssPreset } from '@mui/styles';
import rtl from "jss-rtl";
import NavBar from './Components/NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardTheme } from './Theme';
import { Box } from '@mui/system';
import { prefixer } from 'stylis';
import { getCurrentUser, LogoutActions } from '../redux/slice/User/UserSlice';
import { getAccountActions } from '../redux/slice/Account/AccountSlice';


export default function Dashboard() {




    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


    const { i18n } = useTranslation();
    document.dir = i18n.dir();

    const theme = DashboardTheme(i18n.dir());



    const dispatch = useDispatch();
    const CurrentUserData = useSelector((state) => state.userReduser.CurrentUser);
    const Account = useSelector((state) => state.accountReduser);

    const navigate = useNavigate();



    useEffect(() => {
        dispatch(getCurrentUser())
        dispatch(getAccountActions())


    }, [dispatch])



    useEffect(() => {


        if (CurrentUserData !== "idle" && CurrentUserData === "") {
            navigate("/");
        }


    }, [CurrentUserData, navigate])

    useEffect(() => {


        if (Account.httpCode === 401) {

            dispatch(LogoutActions())
            dispatch(getCurrentUser())

        }

  

    }, [Account,dispatch])






    const cacheRtl = createCache({
        key: theme.direction === "rtl" ? "muirtl" : "muiltrz",
        prepend: true,
        stylisPlugins: theme.direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer],
        
    });

  

    const styleMainSection = {

        backgroundColor: "#ffffff", backgroundImage: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);"
        ,
        minHeight: "100vh",


    }
    return (



        <CacheProvider value={cacheRtl}>

            <StylesProvider jss={jss}>
                <Box component="section" sx={styleMainSection}>

                    <ThemeProvider theme={theme}>
                        <NavBar  />
                    </ThemeProvider>
                </Box>

            </StylesProvider>

        </CacheProvider>



    )
}
