import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import React from 'react'
import AnimatedNumber from "animated-number-react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { defultReprtsPageView } from '../../../hepler';

function TransactionVoidedComp(props) {

    const data = props.data
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

   


    const handleUpdateVoideService = (value) => {

        navigate({

            pathname: "/Dashboard/Reports/"+defultReprtsPageView[3],
            search: "?" + searchParams.toString()+"&VoidService="+value


        })
    }






    const { t } = useTranslation();

    return (
        <Box sx={{ marginTop: 2 }}>

            <CardContent sx={{ display: "flex", flexWrap: "wrap", minWidth: "100%", justifyContent: "center" }}>



                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                    <CardActionArea  onClick={()=>handleUpdateVoideService(1)}>
                        <CardContent  >
                            <Typography  variant='h4'color="error.main" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.Refund} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("Refund").toLocaleUpperCase()}</Typography>


                        </CardContent>

                    </CardActionArea>

                </Card>



                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                    <CardActionArea  onClick={()=>handleUpdateVoideService(2)}>

                        <CardContent  >
                            <Typography variant='h4' color="warning.light" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.Discount} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("Discounts").toLocaleUpperCase()}</Typography>


                        </CardContent>
                    </CardActionArea>



                </Card>


                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                    <CardActionArea  onClick={()=>handleUpdateVoideService(3)}>

                        <CardContent  >
                            <Typography variant='h4' color="secondary.light" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.VoidTaxs} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("Taxes").toLocaleUpperCase()}</Typography>


                        </CardContent>
                    </CardActionArea >



                </Card>


                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>

                    <CardActionArea id={"Void-4"} onClick={()=>handleUpdateVoideService(4)}>

                        <CardContent  >
                            <Typography variant='h4' color="error.main" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.VoidService} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("Services").toLocaleUpperCase()}</Typography>


                        </CardContent>

                    </CardActionArea>



                </Card>



                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                    <CardActionArea  onClick={()=>handleUpdateVoideService(5)}>

                        <CardContent  >
                            <Typography variant='h4' color="warning.light" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.MinCharge} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("MinimumCharge").toLocaleUpperCase()}</Typography>


                        </CardContent>

                    </CardActionArea>



                </Card>


                <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                    <CardActionArea  onClick={()=>handleUpdateVoideService(6)}>

                        <CardContent  >
                            <Typography variant='h4' color="secondary.light" sx={{ textAlign: "center" }}>

                                <AnimatedNumber formatValue={n => n.toFixed(0)}
                                    value={data.DefaultItem} duration="2000" />

                            </Typography>
                            <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("MandatoryItems").toLocaleUpperCase()}</Typography>


                        </CardContent>

                    </CardActionArea>



                </Card>

            </CardContent>


        </Box>
    )
}

export default TransactionVoidedComp