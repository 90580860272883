import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { set } from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import TransactionVoidedComp from '../Components/TransactionVoidedComp';
import { t } from 'i18next';

function VoidedTransctionsReports() {


  let Reports = {};

  const fullReportByDateReduser = useSelector((state) => state.fullReportByDateReduser)

  let ReportVoidServices = { Refund: 0, Discount: 0, VoidTaxs: 0, VoidService: 0, MinCharge: 0, DefaultItem: 0 }

  if (fullReportByDateReduser.httpCode === 200) {


    Reports = fullReportByDateReduser.response;

    if (fullReportByDateReduser.loading === "succeeded") {


      Reports.reportByDateList.forEach((data) => {

        set(ReportVoidServices, 'Refund', ReportVoidServices.Refund + data.refund)
        set(ReportVoidServices, 'Discount', ReportVoidServices.Discount + data.discount)
        set(ReportVoidServices, 'VoidTaxs', ReportVoidServices.VoidTaxs + data.removeTax)
        set(ReportVoidServices, 'VoidService', ReportVoidServices.VoidService + data.removeService)
        set(ReportVoidServices, 'MinCharge', ReportVoidServices.MinCharge + data.removeMinimumCharge)
        set(ReportVoidServices, 'DefaultItem', ReportVoidServices.DefaultItem + data.removeDefaultItems)

      })


    }

  }


  if (fullReportByDateReduser.loading === "pending" || fullReportByDateReduser.loading === "idle") {

    return (<Progress />)
  }


  if (fullReportByDateReduser.loading === "failed") {
    return (<APIErorr reduser={fullReportByDateReduser} />)
  }



  return (
    <Box>

<Typography variant='h4' color="info.main" sx={{ textAlign: "center", marginBottom: 2, marginTop: 2 }}>{t("Totalvoidedtransactionsbytypevoided").toLocaleUpperCase()}</Typography>

<TransactionVoidedComp data={ReportVoidServices} />



    </Box>

  )
}

export default VoidedTransctionsReports