import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;
//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
};


export const getAllKitchenMonitorSetting = createAsyncThunk(
  "getAllKitchenMonitorSetting/getAllKitchenMonitorSetting",

  async (dispatch, { rejectWithValue }) => {
    try {

      const shopId = dispatch.shopId
      const monitorId = dispatch.monitorId;


      const { data } = await axios.get(url + "/Apps/API/CMS/Settings/KitchenMonitor/getAll?ShopId=" + shopId + "&DeviceId=0&QRKey=0&MonitorId=" + monitorId,
        {
          withCredentials: true
        })
      return data;
    } catch (err) {


      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);








export const GetAllKitchenMonitorSettingSlices = createSlice({

  name: "GetAllKitchenMonitorSetting",
  initialState,

  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [getAllKitchenMonitorSetting.pending]: (state) => {
      state.loading = 'pending';
    },
    [getAllKitchenMonitorSetting.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [getAllKitchenMonitorSetting.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }








  }

})

export const { reset } = GetAllKitchenMonitorSettingSlices.actions

export default GetAllKitchenMonitorSettingSlices.reducer;