import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { useTranslation } from 'react-i18next';
import rtlPlugin from "stylis-plugin-rtl";
import { create } from "jss";
import { jssPreset } from '@mui/styles';
import rtl from "jss-rtl";
import { WebsiteTheme } from './Theme';
import { Box } from '@mui/system';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react'
import { StylesProvider } from '@mui/styles'
import NavBar from './NavBar';
import { Avatar, Button, Card, CardContent, Grid, TextField, Typography, useMediaQuery, CssBaseline, FormGroup, Alert, CircularProgress, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import CalculateIcon from '@mui/icons-material/Calculate';
import audit from './image/audit.jpg'
import audit2 from './image/audit3.jpg'
import audit3 from './image/audit2.jpg'

import pic2 from './image/pic2.jpg'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Personal1 from "./image/Personal1.jpg"
import emailjs from '@emailjs/browser';
import Personal3 from "./image/Personal3.jpg"
import Mohammed from "./image/mohammed.jpeg"
import NezarPic from "./image/Nezar.jpg"
import NaderPic from "./image/Nader.jpeg"
import Personal8 from "./image/AbedAlsalam.jpg"

import ThamerPic from "./image/Thamer.jpg"



import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Marquee from "react-fast-marquee";
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useRef } from 'react';


import LanguageIcon from '@mui/icons-material/Language';
import Progress from '../Dashboard/Components/Progress';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

import BusinessIcon from '@mui/icons-material/Business';
import RecommendIcon from '@mui/icons-material/Recommend';
function WebSite() {



    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });







    const { i18n } = useTranslation();
    document.dir = i18n.dir();
    const theme = WebsiteTheme(i18n.dir());

    const cacheRtl = createCache({
        key: theme.direction === "rtl" ? "muirtl" : "muiltrz",
        prepend: true,
        stylisPlugins: theme.direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer],

    });







    return (


        <CacheProvider value={cacheRtl}>

            <StylesProvider jss={jss}>
                <Box component="section">

                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Body />

                    </ThemeProvider>
                </Box>

            </StylesProvider >

        </CacheProvider >

    )
}




const Body = () => {
    const haederHight = "1100px"

    const [scrollPosition, setScrollPosition] = useState(0);

    const [hideImageList, settHideImageList] = useState(false);

    const [firtsLoad, setFirstLoad] = useState(false);

    const imageListVeiwRef = useRef(null);
    const aboutUsRef = useRef(null);
    const teamWorkRef = useRef(null);
    const extraServicesRef = useRef(null);
    const clientsRef = useRef(null);
    const contactUsRef = useRef(null);
    const callUsRef = useRef(null);
    const servicesRef = useRef(null);
    const form = useRef();

    const theme = useTheme();

    const [showSucssesMsg, setShowSucssesMsg] = useState(null);


    const handleScroll = () => {
        const position = window.pageYOffset;


        if (position > 0 && position < aboutUsRef.current.offsetTop - 50) {
            setScrollPosition(1);


        }


        if (position > 0 && aboutUsRef.current.offsetTop === 0) {
            setScrollPosition(1);

        }

        if (position < 1) {
            setScrollPosition(0);
        }


        if (position > imageListVeiwRef.current.offsetTop - 100) {

            settHideImageList(true);
        } else {
            settHideImageList(false);

        }


        if (matches) {




            if (position >= aboutUsRef.current.offsetTop - 50 && position < servicesRef.current.offsetTop - 50 && aboutUsRef.current.offsetTop > 0) {


                setScrollPosition(2);



            }




            if (position >= servicesRef.current.offsetTop - 50 && position < teamWorkRef.current.offsetTop - 50 && servicesRef.current.offsetTop > 0) {


                setScrollPosition(3);



            }



            if (position >= teamWorkRef.current.offsetTop - 50 && position < extraServicesRef.current.offsetTop - 50 && teamWorkRef.current.offsetTop > 0) {


                setScrollPosition(4);



            }


            if (position >= extraServicesRef.current.offsetTop - 50 && position < clientsRef.current.offsetTop - 50 && extraServicesRef.current.offsetTop > 0) {


                setScrollPosition(5);



            }

            if (position >= clientsRef.current.offsetTop - 50 && position < contactUsRef.current.offsetTop - 50 && clientsRef.current.offsetTop > 0) {


                setScrollPosition(6);



            }

            if (position >= contactUsRef.current.offsetTop - 50 && position < callUsRef.current.offsetTop - 50 && contactUsRef.current.offsetTop > 0) {


                setScrollPosition(7);



            }

        }


    };






    function importAll(r) {
        return r.keys().map(r);
    }

    const imagesList = importAll(require.context('./image/Clients Logs', false, /\.(png|jpe?g|svg)$/));




    const handelScrollDownButton = (ref) => {

        if (ref === "Home") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });

            console.log("Home", ref)

            return;
        }


        switch (ref) {
            case "AboutUs":
                ref = aboutUsRef;
                break;

            case "TeamWork":
                ref = teamWorkRef;
                break;
            case "ExtraServices":
                ref = extraServicesRef;
                break;

            case "Clients":
                ref = clientsRef;
                break;

            case "ContactUs":
                ref = contactUsRef;
                break;
            case "CallUs":
                ref = callUsRef;
                break;

            case "Services":
                ref = servicesRef;
                break;

            default:
                return;
        }
        if (scrollPosition === 0) {

            window.scrollTo({
                top: 500,
                behavior: 'auto',
            });

            setTimeout(() => {

                window.scrollTo({
                    top: ref.current.offsetTop - 35,
                    behavior: 'smooth',
                });


            }, 1000);


            return;
        }




        window.scrollTo({
            top: ref.current.offsetTop - 35,
            behavior: 'smooth',
        });
    };


    useEffect(() => {

        if (!firtsLoad) {

            window.scrollTo({
                top: 0,
            });


            setFirstLoad(true);

        }



    }, [firtsLoad, setFirstLoad]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        setShowSucssesMsg("wait")

        emailjs.sendForm('service_lbvq8uc', 'template_fnmgktr', form.current, 'SWpymYYZcSPH_jjv0')
            .then((result) => {
                setShowSucssesMsg(true)
            }, (error) => {
                setShowSucssesMsg(false)
            });
    };

    const matches = useMediaQuery(() => theme.breakpoints.up('md'));

    return (
        <Box>


            {!firtsLoad ? <Progress /> :

                <Box sx={{ backgroundColor: "customA.main" }} >

                    <NavBar haederHight={haederHight} scrollPosition={scrollPosition} handelScrollDownButton={handelScrollDownButton} />

                    <Box component="section" className='asdadadadas' sx={{ position: "static", backgroundColor: "white", pt: 50 }}>

                        <Box className='AboutUs' ref={aboutUsRef} sx={{ pt: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "customA.main" }}>

                            <Typography color="primary" variant={matches ? "h2" : "h4"}>  نبذة عنّا </Typography>
                            <Divider sx={{ width: matches ? 420 : 200, bgcolor: "secondary.main", m: 2, p: 0.2 }} />
                            <Box component="section" sx={{ display: "flex", flexDirection: matches ? "row" : "column-reverse", justifyContent: "center", alignItems: "center", gap: 4, width: "90%", pt: 2 }}>

                                <Typography color="primary" sx={{ width: matches ? "50%" : "100%", textAlign: matches ? "start" : "center" }} variant='h6'>
                                    تأسست شركة عبيدات و الصالح  من قبل  الشريك نبيل محمد عبيدات و الشريك نادر بديع الصالح و اللذين يملكان خبرة كبيرة في مجال التدقيق و الاستشارات المالية تزيد عن ثلاثين عاما في مكاتب تدقيق كبرى و شركة مالية. و قد تمكنت الشركة من استقطاب الكثير من العملاء لا سيما على مستوى الشركات المساهمة العامة المحدودة . و ذلك لما يملكه الشركاء و الموظفين من خبرات و سمعة طيبة في المجال آلمالي.حيث تم اعتماد الشركة من قبل هيئة الاوراق المالية الاردنية .

                                    <br />


                                    تتميز الشركة بتقديمها لأفضل الخدمات و الحلول المتكاملة في مجال المحاسبة و تدقيق الحسابات بشقيه الداخلي و الخارجي ودراسات الجدوى الاقتصادية و تقييم الشركات وأنشائها  و الدراسات النافية للجهالة و الاستشارات الضريبية. و التي نهدف من خلالها الى تطوير الاعمال وفق آخر التطورات المهنية و أعلى معايير الجودة و الممارسات الدولية. كل ذلك من خلال فريق عمل من المدققين و المستشارين و الخبراء ذوو الكفاءة العلمية و المهنية المتخصصة
                                </Typography>
                                <img style={{ width: matches ? "400px" : "200px", verticalAlign: "middle", borderRadius: "7%" }} src={audit} alt='pic1' />

                            </Box>




                        </Box>

                        <Box component="section" className='Services' ref={servicesRef}>



                            <Box sx={{ backgroundColor: "primary.main", mt: 10, pt: 10, pb: 10, display: matches ? "flex" : "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <Typography color="customA.main" variant={matches ? "h2" : "h4"}>خدمتنا</Typography>
                                <Divider sx={{ width: matches ? 420 : 70, bgcolor: "secondary.main", m: 2, p: 0.2 }} />


                                <Typography color="customA.main" variant={matches ? "h5" : "h6"} mt={1} mb={2} textAlign="center" width={matches ? "100%" : "95%"}>نعمل باستمرار على تحسين جودة جميع خدماتنا والاستثمار في موظفينا،وعملاؤنا</Typography>

                            </Box>


                            <Box sx={{ backgroundColor: "customA.main" }}>


                                <Box component="section" sx={{ minHeight: "400px", display: "flex", flexDirection: "row", flexWrap: !matches && "wrap", justifyContent: "center", alignItems: "center", gap: 4, pt: 4, pr: matches ? 40 : 2, pl: matches ? 40 : 2, maxWidth: matches ? "90%" : "100%" }}>


                                    <img style={{ width: matches ? "300px" : "200px", verticalAlign: "middle", borderRadius: "7%" }} src={audit3} alt='pic1' />

                                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: matches ? "start" : "center", gap: 1 }}>

                                        <Box sx={{ display: "flex", flexFlow: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                            <CalculateIcon color="primary" fontSize='large' />


                                            <Typography color="primary.main" variant={matches ? "h5" : "h6"} pt={2} >

                                                الخدمات المحاسبية

                                            </Typography>

                                        </Box>
                                        <Divider sx={{ width: matches ? 180 : 200, bgcolor: "secondary.main", m: 0, p: 0.1 }} />

                                        <Typography color="primary.main" variant='h6' textAlign={matches ? "start" : "center"}>
                                            وتشمل جميع الخدمات المحاسبية والمحاسبة المحوسبة لجميع المعاملات التجارية لضمان أن يتم إعداد الحسابات والقيود وفقا للمعايير الدولية للتقارير المالية واصدار التقارير الشهرية الممثلة بميزان المراجعة الشهري والتقارير التى تعود بها الفائدة للادارة باتخاذ القرارات المناسبة

                                        </Typography>



                                    </Box>

                                </Box>


                                <Box component="section" sx={{ backgroundColor: "primary.main", display: "flex", flexDirection: matches ? "row" : "column-reverse", justifyContent: "center", alignItems: "center", gap: 4, pr: matches ? 40 : 2, pl: matches ? 40 : 2, mt: !matches && 10, minHeight: matches ? "400px" : "600px" }}>

                                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: matches ? "start" : "center", gap: 2, maxWidth: "90%" }}>


                                        <Box sx={{ display: "flex", flexFlow: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                            <FindInPageIcon color="customA" fontSize='large' />


                                            <Typography color="customA.main" variant={matches ? "h5" : "h6"} pt={2} >
                                                خدمات تدقيق الحسابات(داخلي)
                                            </Typography>

                                        </Box>
                                        <Divider sx={{ width: matches ? 270 : 200, bgcolor: "secondary.main", m: 0, p: 0.1 }} />

                                        <Typography color="customA.main" variant='h6' textAlign={matches ? "start" : "center"}>
                                            يعتبر التدقيق الداخلي من الإجراءات الضرورية الواجب تطبيقها من أجل الحفاظ على حسن سير العمل حسب الأهداف المرجوة و ضمن الحدود القانونية و من أجل التأكد من أن الموظفين و المدراء يقومون بواجباتهم ضمن الحدود المسموح بها و كما يمليه النظام الداخلي للشركة او الوحدة الاقتصادية.

                                        </Typography>
                                    </Box>

                                    <img style={{ width: matches ? "300px" : "200px", verticalAlign: "middle", borderRadius: "7%" }} src={audit2} alt='pic1' />

                                </Box>

                                <Box omponent="section" sx={{ backgroundColor: "customA.main", minHeight: "400px" }}>



                                    <Box sx={{ minHeight: "400px", display: "flex", flexDirection: matches ? "column" : "row", flexWrap: "wrap", justifyContent: "center", alignItems: matches ? "start" : "center", gap: 2, pt: 2, pr: matches ? 40 : 2, pl: matches ? 40 : 2, maxWidth: "100%", backgroundImage: `url(${pic2})`, backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>

                                        <Box sx={{ display: "flex", flexFlow: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                            <TravelExploreIcon color="primary" fontSize='large' />


                                            <Typography color="primary.main" variant={matches ? "h5" : "h6"} pt={2} >
                                                خدمات تدقيق الحسابات(خارجي)

                                            </Typography>

                                        </Box>
                                        <Divider sx={{ width: matches ? 280 : 220, bgcolor: "secondary.main", m: 0, p: 0.2 }} />

                                        <Typography color="primary.main" variant='h6' textAlign={matches ? "start" : "center"}>
                                            إن التدقيق الخارجي هو أحد الخدمات التي تقدمها شركتنا و هي إجراءات و أعمال يقوم بها طاقمنا المهني لإبداء الرأي حول البيانات المالية لعملائنا وفقا لمعايير التدقيق الدولية.
                                            بالإضافة إلى ذلك فإن أعمال التدقيق مصممة لتقديم المشورة لأصحاب الأعمال و المدراء بما يفيدهم في اتخاذ قراراتهم و تحقيق أهداف شركتهم.
                                            جميع أعمال التدقيق نبدأها بفهم وتقييم طبيعة أعمال الشركة المراد تدقيقها. مما يساعدنا على تقديم اقتراحات بناءة لتطوير أعمال الشركة و خططها الإستراتيجية و أنظمة المعلومات و الرقابة الداخلية لديها. و من ثم تطبيق إجراءات التدقيق التي تتماشى مع متطلبات الأنظمة و القوانين المحلية و معايير التدقيق الدولية.
                                        </Typography>



                                    </Box>
                                </Box>





                            </Box>
                        </Box>


                        <Box component="section" className="TeamWork" sx={{ backgroundColor: "customA.main", mt: !matches && 20 }} ref={teamWorkRef}>
                            <Box className='Services' sx={{ backgroundColor: "primary.main", height: 300, display: matches ? "flex" : "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <Typography color="customA.main" variant={matches ? "h2" : "h4"}>فريقنا</Typography>
                                <Divider sx={{ width: matches ? 420 : 70, bgcolor: "secondary.main", m: 2, p: 0.2 }} />




                            </Box>

                            <Box>

                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 4 }}>
                                    <Typography color="secondary.main" textAlign="center" variant={matches ? "h4" : "h5"}>مجلس ادارة الشركة</Typography>


                                </Box>


                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap", p: 4, justifyContent: "center", alignItems: "center" }}>


                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={Personal1} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main" textAlign="center"> نبيل محمد محمود عبيدات</Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">الشريك التنفيذي (JCPA)</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>


                                                حاصل على بكالوريوس محاسبة: و يتمتع بخبرة تزيد عن ثلاثين عامًا قام خلالها بأعمال المحاسبة و التدقيق لمجموعة كبيرة من
                                                الشركات الصناعية و الخدمية و التجارية و المؤسسات الحكومية و الجمعيات و البيئات الحكومية. و هوشريك سابق في شركة
                                                العباسي و شركاه وكلاء شركة موور العالمية (Moore Global) .


                                            </Typography>




                                        </CardContent>

                                    </Card>

                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={NaderPic} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main"> نادر بديع الصالح</Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">الشريك</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>

                                                حاصل على بكالوريوس محاسبة و اقتصاد: و يتمتع بخبرة تزيد عن 25 عامًا في مجال تدقيق الحسابات و تقييم الاعمال»
                                                عمل مديرًا عامًا للعديد من الشركات المالية » و شغل منصب عضو في مجالس ادارات شركات مساهمة عامةو لجان تدقيق
                                                و هو عضو سابق في مجلس ادارة جمعية مدققي الحسابات الاردنيين .


                                            </Typography>




                                        </CardContent>

                                    </Card>

                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={Personal3} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main"> مؤيد بركات</Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">مدير تدقيق / شريك</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>


                                                حاصل على بكالوريوس محاسبة : عمل لدى ( شركة التجمع المهني سابقً) »و يتمتع بخبرة تزيد عن 20 عامًا قام خلالها بأعمال
                                                التدقيق لمجموعة كبيرة من الشركات الصناعية و الخدمية و المؤسسات الحكومية و الجمعيات والهيئات غيرالحكومية والمنظمات
                                                الدولية و الاوروبية» كما عمل خبيرًا لدى هيئة مكافحة الفساد» .

                                            </Typography>




                                        </CardContent>

                                    </Card>



                                </Box>
                            </Box>


                            <Box>



                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 4 }}>
                                    <Typography color="secondary.main" textAlign="center" variant={matches ? "h4" : "h5"}>إدارة التدقيق والاستشارات العليا  </Typography>


                                </Box>


                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap", p: 4, justifyContent: "center", alignItems: "center" }}>


                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={Personal8} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main" textAlign="center"> محمد نبيل محمد عبيدات  </Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main"> مساعد مدقق  </Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>

                                                حاصل على شهادة بكالوريوس محاسبة جامعة البلقاء تقدير جيد جدا  التحق بالشركة في آذار ٢٠٢٢


                                            </Typography>




                                        </CardContent>

                                    </Card>

                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={ThamerPic} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main"> ثامر محمد محمود عبيدات</Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">مدقق حسابات رئيسي</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>


                                                حاصل على بكالوريوس محاسبة و اقتصاد : و يتمتع بخبرة تزيد عن خمسة عشر عاما قام خلالها بأعمال المحاسبة
                                                و التدقيق لمجموعة كبيرة من الشركات الصناعية و الخدمية و التجارية و المؤسسات و البيئات الحكومية و الجمعيات
                                                و هوموظف سابق في شركة العباسي و شركاه وكلاء شركة موور العالمية
                                                (Moore Global)

                                            </Typography>




                                        </CardContent>

                                    </Card>

                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={NezarPic} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main"> نزار خالد فواز بطاينه</Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">  مستشار مالي وضريبي</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>

                                                حاصل على بكالوريوس محاسبة: و يتمتع بخبرة تزيد عن خمسة عشر عاما قَام خلالها بأعمال المحاسبة والاستشارات الضريبيه و التدقيق لمجموعة كبيرة من الشركات الصناعية و الخدمية و التجارية و المؤسسات و البيئات الحكومية و الجمعيات

                                            </Typography>




                                        </CardContent>

                                    </Card>
                                    <Card sx={{ width: matches ? "25%" : "100%", height: 600, borderRadius: 4, backgroundColor: "primary.main" }}>

                                        <CardContent>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: "primary.main", width: 200, height: 200, position: "static" }} src={Mohammed} aria-label="recipe">

                                                </Avatar>
                                                <Typography variant={matches ? 'h4' : "h5"} color="secondary.main">   محمد احمد السلام  </Typography>

                                                <Typography variant={matches ? 'h5' : "body1"} color="customA.main">  مدقق حسابات رئيسي</Typography>

                                                <Divider sx={{ width: "75%", bgcolor: "secondary.main", m: 1, p: 0.1 }} />

                                            </Box>

                                            <Typography variant='body1' sx={{ color: "customA.main", textAlign: "center", pt: 2 }}>

                                                يتمتع بخبرة تزيد عن 18 سنة قام خلالها بأعمال التدقيق لمجموعة كبيرة من الشركات الصناعية، الخدمية، المؤسسات الحكومية، الجمعيات والهيئات غير الحكومية، مسؤول عن القيام بإجراء دراسات تحليلية للمعلومات، مراجعة وتقييم وتوثيق الأنظمة المحاسبية والمالية والتكاليف.
                                            </Typography>




                                        </CardContent>

                                    </Card>



                                </Box>
                            </Box>








                        </Box>



                        <Box component="section" className='ExtraServices' sx={{ backgroundColor: "primary.main" }} ref={extraServicesRef}>
                            <Box className='Services' sx={{ backgroundColor: "primary.main", height: 200, display: matches ? "flex" : "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <Typography color="customA.main" variant={matches ? "h2" : "h4"}>خدمات اخرى</Typography>
                                <Divider sx={{ width: matches ? 270 : 70, bgcolor: "secondary.main", m: 2, p: 0.2 }} />




                            </Box>

                            <Box sx={{ display: "flex", flexFlow: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "start", gap: 4, height: matches ? "470px" : "1900px" }}>

                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <RemoveRedEyeIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>

                                    <Typography color="customA.main" variant='h5' textAlign="center" >خدمات الفحص
                                        النافي <br /> للجهالة</Typography>

                                </Box>

                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <BookmarkAddedIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h5' textAlign="center" > خدمات الاستشارات
                                        الضريبية </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <AutoGraphIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h5' textAlign="center">خدمات الاستشارات المالية <br />و الادارية و الاخرى   </Typography>

                                </Box>

                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        < DesignServicesIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h5' textAlign="center">   دراسه الجدوى   </Typography>

                                </Box>

                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <BusinessIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h5' textAlign="center"> انشاء الشركات بأنواعها   </Typography>

                                </Box>

                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <AutoFixHighIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h5' textAlign="center"> تصفيه وشطب الشركات  </Typography>

                                </Box>


                                <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>


                                    <Avatar sx={{ bgcolor: "customA.main", width: 90, height: 90, position: "static" }}>
                                        <RecommendIcon color="secondary" sx={{ fontSize: "70px" }} />
                                    </Avatar>


                                    <Typography color="customA.main" variant='h6' textAlign="center">الاستشارات القانونيه    <br /> (القانون التجاري, قانون العمل , قانون الضمان الاجتماعي )</Typography>

                                </Box>

                            </Box>


                        </Box>

                        <Box component="section" className='Clients' sx={{ backgroundColor: "customA.main", direction: "rtl", height: 400 }} ref={clientsRef}>

                            <Box className='Services' sx={{ height: 200, display: matches ? "flex" : "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Typography color="primary.main" variant={matches ? "h2" : "h4"}> شركاؤنا في النجاح</Typography>
                                <Divider sx={{ width: matches ? 270 : 70, bgcolor: "secondary.main", m: 2, p: 0.2 }} />
                            </Box>

                            <Box ref={imageListVeiwRef} sx={{ opacity: hideImageList ? 0 : 100, transition: `opacity 0.2s` }}>
                                <Marquee pauseOnHover="true" loop={0} gradientWidth={matches ? 200 : 20}  >



                                    {imagesList.map((data, key) => (
                                        <Box component="div" key={key} sx={{ margin: "0px 30px" }} >
                                            <Avatar variant='square' src={data} sx={{ width: 150, height: 150 }}></Avatar>

                                        </Box>

                                    ))}


                                </Marquee>
                            </Box>
                        </Box>

                        <Box component="section" className='ContactUs' sx={{ height: matches && "700px", backgroundColor: "primary.main" }} >

                            <Box>

                                <Grid container spacing={2} sx={{ pt: 10, }}>
                                    <Grid item xs={12} lg={6} sx={{ textAlign: "center" }} ref={contactUsRef}>

                                        <Typography color='customA.dark' variant='h4' sx={{ textAlign: "center" }}>تواصل معنا</Typography>

                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: matches ? "end" : "center", alignItems: matches ? "end" : "center" }}>
                                            <form ref={form} onSubmit={sendEmail} style={{ display: "inline", width: matches ? "75%" : "90%" }} >
                                                <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "start", width: "100%" }}>

                                                    <Typography color='customA.dark' variant='h6' sx={{ textAlign: "center" }}>الأسم </Typography>

                                                    <TextField
                                                        className='sadasdad'
                                                        id="outlined-required"
                                                        type="text"
                                                        name="from_name"
                                                        disabled={showSucssesMsg === "wait"}

                                                        sx={{
                                                            backgroundColor: "customA.main", borderRadius: "7px", width: matches ? "85%" : "95%", position: "static", '& .MuiInputBase-root': { position: hideImageList && "static" }
                                                        }}
                                                    />
                                                    <Typography color='customA.dark' variant='h6' sx={{ textAlign: "center" }}>البريد الالكتروني </Typography>

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-required"
                                                        type="email"
                                                        name="from_email"
                                                        disabled={showSucssesMsg === "wait"}
                                                        sx={{ backgroundColor: "customA.main", width: matches ? "85%" : "95%", position: "static", '& .MuiInputBase-root': { position: hideImageList && "static", } }}
                                                    />
                                                    <Typography color='customA.dark' variant='h6' sx={{ textAlign: "center" }}> نص الرسالة </Typography>

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-required"
                                                        multiline
                                                        rows={4}
                                                        disabled={showSucssesMsg === "wait"}
                                                        name="message"
                                                        sx={{ backgroundColor: "customA.main", borderRadius: "7px", height: "200", width: matches ? "85%" : "95%", position: "static", '& .MuiInputBase-root': { position: hideImageList && "static", } }}
                                                    />
                                                    <br />
                                                    <Alert sx={{ width: matches ? "85%" : "95%", display: (showSucssesMsg === null || showSucssesMsg === "wait") && "none" }} severity={showSucssesMsg ? "success" : "error"}>{showSucssesMsg ? "تم إرسال رسالتك بنجاح" : "خطأ أثناء معالجة الطلب يرجى معاودة ارسال الرسالة"}</Alert>

                                                    <CircularProgress sx={{ display: showSucssesMsg !== "wait" && 'none' }} color="customA" />

                                                    <Button sx={{ display: showSucssesMsg === "wait" && "none" }} variant='outlined' type='submit' value='Send' color='customA'>ارسل الرسالة</Button>
                                                </FormGroup>
                                            </form>

                                        </Box>


                                    </Grid>


                                    <Grid item xs={12} lg={6} sx={{ textAlign: "start" }} ref={callUsRef}>



                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", pl: matches && 20, pb: 10 }}>

                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "start", pl: !matches && 4, pr: !matches && 4, mb: 2 }}>
                                                <Typography color='customA.dark' variant='h4' sx={{ textAlign: "center", p: matches ? 4 : 10, pt: 1, pb: 1 }}> اتصل بنا</Typography>

                                                <a href="tel:+962-6-585-50-99" style={{ display: "flex", textDecoration: 'none', flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>

                                                    <Avatar variant='circular' sx={{ backgroundColor: "customA.dark", width: 25, height: 25 }} ><LocalPhoneIcon color='primary' fontSize='small' /></Avatar>
                                                    <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "center" }}>
                                                        99 50 585 6 962+  </Typography>
                                                </a>


                                                <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                    <Avatar variant='circular' sx={{ backgroundColor: "customA.dark", width: 25, height: 25 }} ><FaxIcon color='primary' fontSize='small' /></Avatar>
                                                    <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "center" }}>
                                                        99 60 585 6 962+                                                        </Typography>
                                                </Box>

                                                <a href="mailto:info@obasaaudit.com" style={{ display: "flex", textDecoration: 'none', flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                    <Avatar variant='circular' sx={{ backgroundColor: "customA.dark", width: 25, height: 25 }} ><EmailIcon color='primary' fontSize='small' /></Avatar>
                                                    <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "center" }}>
                                                        info@obasaaudit.com
                                                    </Typography>
                                                </a>

                                                <a href="https://www.obasaaudit.com" style={{ display: "flex", textDecoration: 'none', flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                    <Avatar variant='circular' sx={{ backgroundColor: "customA.dark", width: 25, height: 25 }} ><LanguageIcon color='primary' fontSize='small' /></Avatar>
                                                    <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "start" }}>
                                                        www.obasaaudit.com

                                                    </Typography>

                                                </a>


                                                <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", flex: "wrap", justifyContent: "start", }}>
                                                    <Avatar variant='circular' sx={{ backgroundColor: "customA.dark", width: 25, height: 25 }} ><AddLocationIcon color='primary' fontSize='small' /></Avatar>
                                                    <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "start" }}>
                                                        المملكة الأردنية الهاشمية , عمان
                                                        <br />
                                                        الصويفية شارع الوكالات , مجمع
                                                        <br />
                                                        مجمع غداف سنتر بناية رقم 15
                                                        <br />
                                                        الطابق الاول

                                                    </Typography>

                                                </Box>






                                            </Box>


                                        </Box>


                                    </Grid>




                                </Grid>

                                <Typography color='customA.dark' variant='subtitle1' sx={{ textAlign: "center" }}>
                                    Design by <a href='http://pinetechs.com/' style={{ color: "white" }}> Pinetechs</a> © 2022. All rights reserved

                                </Typography>

                            </Box>





                        </Box>

                    </Box>

                </Box>
            }

        </Box>

    )


}



export default WebSite