import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
};


export const deleteNoteAndExtraSetting = createAsyncThunk(
  "deleteNoteAndExtraSetting/deleteNoteAndExtraSetting",

  async (dispatch, { rejectWithValue }) => {
    try {

      const shopId = dispatch.shopId
      const NoteAndExtraObject = dispatch.NoteAndExtraObject






      const { data } = await axios.post(url + "/Apps/API/CMS/Settings/NoteAndExtra/delete?ShopId=" + shopId + "&DeviceId=0&QRKey=0",
        {
          "id": NoteAndExtraObject.id,
          "name": NoteAndExtraObject.name,
          "tybe": NoteAndExtraObject.tybe,
        
        }
        ,
        {
          withCredentials: true
        })
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);






export const DeleteNoteAndExtraSettingSlices = createSlice({

  name: "DeleteNoteAndExtraSetting",
  initialState,

  reducers: {
    reset: () => initialState,




  },



  extraReducers: {
    [deleteNoteAndExtraSetting.pending]: (state) => {
      state.loading = 'pending';
    },
    [deleteNoteAndExtraSetting.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [deleteNoteAndExtraSetting.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }








  }

})




export const { reset } = DeleteNoteAndExtraSettingSlices.actions

export default DeleteNoteAndExtraSettingSlices.reducer;