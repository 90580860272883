import { Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import ItemsListWithCheckComp from '../Components/ItemsListWithCheckComp'
import { t } from 'i18next';
import { serviceName } from '../../../hepler'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getAllKitchenMonitorSetting } from '../../../../redux/slice/Settings/KitchenMonitor/getAllKitchenMonitorSettingSlices'
import APIErorr from '../../../Components/APIErorr'
import Progress from '../../../Components/Progress'
import AlertsDialog from '../../../Components/AlertsDialog'
import { saveKitchenMonitorSetting } from '../../../../redux/slice/Settings/KitchenMonitor/saveKitchenMonitorSettingSlices'
import SuccessMsg from '../../../Components/SuccessMsg'

function KitchenMonitor() {
    const [searchParams] = useSearchParams();

    const ArrayList = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const shopId = searchParams.get("ShopId")
    const dispatch = useDispatch();
    const [dataObject, setDataObject] = useState(null)


    const [monitorId, setMonitorId] = useState(1);
    const getAllKitchenMonitorSettingReduser = useSelector((sate) => sate.getAllKitchenMonitorSettingReduser)
    const saveKitchenMonitoSettingReduser = useSelector((state) => state.saveKitchenMonitoSettingReduser)



    const [dalilogAlretObject, setDalilogAlretObject] = useState(
        {
            open: false,
            msg: t("MSG9"),
            title: t("SaveChanges"),
            showButtontOne: true,
            buttontOneTitle: t("Yes"),
            buttonOneFun: () => { },
            showButtontTow: true,
            buttontTowTitle: t("No"),
            buttonTowFun: () => {
                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
            },
        }


    )


    const handelChangeMonitorId = (e) => {




        setDalilogAlretObject({
            ...dalilogAlretObject, buttonOneFun: async () => {

                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
                await dispatch(handelSaveAsync())
                setMonitorId(e.target.value)
            }, open: true, buttonTowFun: () => {
                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
                setMonitorId(e.target.value)
            }
        })


    }




    useEffect(() => {

        setDataObject(null)

        dispatch(getAllKitchenMonitorSetting({ shopId, monitorId }))

    }, [shopId, monitorId, dispatch])

    useEffect(() => {
        if (getAllKitchenMonitorSettingReduser.loading === "succeeded") {



            setDataObject(getAllKitchenMonitorSettingReduser.response)

        }

    }, [getAllKitchenMonitorSettingReduser])



    const handelChangePriority = (e) => {

        const monitorPrioritySettingsEntity = { ...dataObject.monitorPrioritySettingsEntity, [e.target.name]: Number(e.target.value) }
        setDataObject({ ...dataObject, monitorPrioritySettingsEntity })



    }

    const handelChangeShowAllItems = (e, value) => {
        const monitorPrioritySettingsEntity = { ...dataObject.monitorPrioritySettingsEntity, [e.target.name]: Boolean(value) }
        setDataObject({ ...dataObject, monitorPrioritySettingsEntity })

    }



    const handelSave = () => {
        const dataSaved = dataObject
        dispatch(saveKitchenMonitorSetting({ shopId, monitorId, dataSaved }))
    }



    const handelSaveAsync = () => async (dispatch) => {
        const dataSaved = dataObject
        await dispatch(saveKitchenMonitorSetting({ shopId, monitorId, dataSaved }))

    }








    if (getAllKitchenMonitorSettingReduser.loading === "failed") {
        return (<APIErorr reduser={getAllKitchenMonitorSettingReduser} />)
    }
    if (getAllKitchenMonitorSettingReduser.loading === "pending" || getAllKitchenMonitorSettingReduser.loading === "idle" || dataObject === null) {


        return (<Progress />)
    }



    return (
        <Card sx={{ m: 1 }}>
            <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", gap: 2, m: 1 }}>
                {saveKitchenMonitoSettingReduser.loading === "succeeded" && <SuccessMsg msg={t("MSG3")} />}
                {saveKitchenMonitoSettingReduser.loading === "pending" && <Progress />}
                {saveKitchenMonitoSettingReduser.loading === "failed" && <APIErorr reduser={saveKitchenMonitoSettingReduser} />}




                <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} fullWidth >
                    <InputLabel id="demo-simple-select-label">{t("MonitorDeviceNumber")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("MonitorDeviceNumber")}
                        value={monitorId}
                        onChange={handelChangeMonitorId}
                        
                    >
                        {ArrayList.map((data, index) => (
                            <MenuItem key={index} value={data}>{data}</MenuItem>



                        ))}

                    </Select>




                </FormControl>
                <Divider sx={{ mt: 2, mr: 2, ml: 2 }} />

                <Typography variant='h6' sx={{ textAlign: "center" }} color={"primary"} >{t("MSG10")}</Typography>
                <Typography sx={{ mb: 2 }} variant='subtitle2' color={"error"} >{t("MSG11")}</Typography>

                <Box sx={{ display: "flex", flexDirection: "row", rowGap: 2, columnGap: 1, flexFlow: "wrap", alignContent: "center", justifyContent: "center" }}>

                    <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityTakeAway_L">{t(serviceName[1])}</InputLabel>
                        <Select
                            labelId="priorityTakeAway_L"
                            id="priorityTakeAway"
                            name="priorityTakeAway"
                            label={t(serviceName[1])}
                            onChange={handelChangePriority}
                            value={dataObject.monitorPrioritySettingsEntity.priorityTakeAway}
                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}

                        </Select>




                    </FormControl>

                    <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityTable_L">{t(serviceName[2])}</InputLabel>
                        <Select
                            labelId="priorityTable_L"
                            id="priorityTable"
                            name="priorityTable"
                            onChange={handelChangePriority}
                            label={t(serviceName[2])}
                            value={dataObject.monitorPrioritySettingsEntity.priorityTable}
                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}
                        </Select>




                    </FormControl>
                    <FormControl  disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityOnDail_L">{t(serviceName[3])}</InputLabel>
                        <Select
                            labelId="priorityOnDail_L"
                            id="priorityOnDail"
                            name="priorityOnDail"
                            onChange={handelChangePriority}

                            label={t(serviceName[3])}
                            value={dataObject.monitorPrioritySettingsEntity.priorityOnDail}

                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}
                        </Select>




                    </FormControl>
                    <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityDelivery_L">{t(serviceName[4])}</InputLabel>
                        <Select
                            labelId="priorityDelivery_L"
                            id="priorityDelivery"
                            name="priorityDelivery"
                            onChange={handelChangePriority}

                            label={t(serviceName[4])}
                            value={dataObject.monitorPrioritySettingsEntity.priorityDelivery}

                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}
                        </Select>




                    </FormControl>
                    <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityDriveThru_L">{t(serviceName[5])}</InputLabel>
                        <Select
                            labelId="priorityDriveThru_L"
                            id="priorityDriveThru"
                            name="priorityDriveThru"
                            onChange={handelChangePriority}

                            label={t(serviceName[5])}
                            value={dataObject.monitorPrioritySettingsEntity.priorityDriveThru}

                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}
                        </Select>




                    </FormControl>
                    <FormControl disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} sx={{ width: "33%" }}>
                        <InputLabel id="priorityOnline_L">{t(serviceName[6])}</InputLabel>
                        <Select
                            labelId="priorityOnline_L"
                            id="priorityOnline"
                            name="priorityOnline"
                            onChange={handelChangePriority}

                            label={t(serviceName[6])}
                            value={dataObject.monitorPrioritySettingsEntity.priorityOnline}

                        >
                            {ArrayList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}
                        </Select>




                    </FormControl>





                </Box>
                <Divider sx={{ mr: 2, ml: 2, mt: 1, mb: 1 }} />

                <FormGroup  >
                    <FormControlLabel label={t("ShowAllItems")}
                        control={<Checkbox name="showAllItem"
                        disabled ={saveKitchenMonitoSettingReduser.loading === "pending"}
                            onChange={handelChangeShowAllItems}
                            checked={dataObject.monitorPrioritySettingsEntity.showAllItem}

                        />} />
                </FormGroup>

                {<ItemsListWithCheckComp listType="1" control={[dataObject, setDataObject]} monitorId={monitorId} disabled={ saveKitchenMonitoSettingReduser.loading === "pending" ? true : dataObject.monitorPrioritySettingsEntity.showAllItem} />}

            </CardContent>



            <Box >

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text'  disabled ={saveKitchenMonitoSettingReduser.loading === "pending"} onClick={handelSave}>{t("Save")}</Button>




                </Box>


            </Box>


            <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

        </Card>

    )
}

export default KitchenMonitor