import { Avatar, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SquareIcon from '@mui/icons-material/Square';
import { useState } from 'react';
import ImageDialog from '../Components/ImageDialog';
import ColorDialog from '../Components/ColorDialog';
import { getDefultItemImage } from '../../../../assets/DefultItemImage';
import { getHexColor } from '../../../hepler';

import APIErorr from '../../../Components/APIErorr';


import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditItemSettings, reset as restAddEditItemSettings } from '../../../../redux/slice/Settings/Items/addEditItemSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';






const getAvatar = (color, name, image, isCategory) => {



    if (color === 0) {

        if (isCategory) {

            return <Avatar variant={isCategory ? 'circular' : 'square'} alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isCategory ? 25 : 150, height: isCategory ? 25 : 100 }} />

        }

        return <img alt={name} src={`data:image/jpeg;base64,${image}`} style={{ width: isCategory ? 25 : 150, height: isCategory ? 25 : 100 }} />


    }


    if (color !== null) {



        return <Avatar variant={isCategory ? 'circular' : 'square'} alt={name} sx={{ width: isCategory ? 25 : 150, height: isCategory ? 25 : 100, bgcolor: getHexColor(color), marginLeft: isCategory && 1, marginRight: isCategory && 1 }} > </Avatar>


    }

    return <Avatar variant={isCategory ? 'circular' : 'square'} alt={name} sx={{ width: isCategory ? 25 : 150, height: isCategory ? 25 : 100, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}
const CategoryMenu = (prop) => {




    const categoryList = prop.data

    const [itemObject, setItemObject] = prop.categoryId


    const addEditItemSettingReduser = useSelector((state) => state.addEditItemSettingReduser);








    const handleChange = (event) => {


        setItemObject({ ...itemObject, [event.target.name]: event.target.value });

    };



    return (
        <>
            <FormControl disabled={addEditItemSettingReduser.loading === 'pending'} fullWidth  >
                <Select
                    value={itemObject.categoryId}
                    onChange={handleChange}
                    displayEmpty
                    name='categoryId'
                    inputProps={{ 'aria-label': 'Without label' }}
                >



                    {categoryList.map((data, index) => (

                        <MenuItem key={index} value={data.id}>
                            <Box sx={{ display: "flex" }}>
                                {getAvatar(data.color, data.name, data.image, true)}
                                <Typography sx={{ marginLeft: 2 }}>{data.name}</Typography>

                            </Box>
                        </MenuItem>



                    ))}
                </Select>
            </FormControl>

        </>
    )




}



function AddEditItems(props) {

    const [setsaveItemFun, handleClose] = props.control || [null, null];
    const itemData = props.item
    const categoryList = props.categoryList
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);

    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        textName: false
    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditItemSettingReduser = useSelector((state) => state.addEditItemSettingReduser);



    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [openColorDialog, setOpenColorDialog] = useState(false)

    const [itemObject, setItemObject] = useState(itemData === null ?

        {
            "id": 0,
            "name": "",
            "tybe": null,
            "priceTakeAway": 0,
            "priceTable": 0,
            "priceDelivery": 0,
            "priceOnDial": 0,
            "priceOnline": 0,
            "priceDriveThru": 0,
            "positions": 0,
            "parcode": 0,
            "categoryId": searchParams.get("CategoryId") || categoryList[0].id,
            "image": "",
            "color": -11419154,
            "printers": "0,1,2,3,4"
        } : itemData


    )






    const handelSaveItems = () => async (dispatch) => {

        if (itemObject.name === '') {
            setShowAlertMsg({ ...showAlertMsg, textName: true })


            return;
        }


        setShowsuccessMsg(false)
        await dispatch(addEditItemSettings({ shopId, itemObject }))


    }








    useEffect(() => {
        if (addEditItemSettingReduser.loading === "succeeded") {
            if (itemObject.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                setItemObject({
                    "id": 0,
                    "name": "",
                    "tybe": null,
                    "priceTakeAway": 0,
                    "priceTable": 0,
                    "priceDelivery": 0,
                    "priceOnDial": 0,
                    "priceOnline": 0,
                    "priceDriveThru": 0,
                    "positions": 0,
                    "parcode": 0,
                    "categoryId": itemObject.categoryId,
                    "image": "",
                    "color": -11419154,
                    "printers": "0,1,2,3,4"
                })







            }

            dispatch(restAddEditItemSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditItemSettingReduser])















    useEffect(() => {
        ///For desktop window size only

        if (setsaveItemFun != null) {

            setsaveItemFun(() => () => dispatch(handelSaveItems()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemObject, setsaveItemFun])





    ///For Mobile window size only
    const handelSaveItemButton = () => {
        dispatch(handelSaveItems());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/Items",
            search: "?" + searchParams.toString()


        })

    }





    const onChangePicture = e => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setItemObject({ ...itemObject, color: 0, imageTemp: reader.result });

                setOpenImageDialog(true);


            });



            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleChange = (event) => {

        let value = event.target.value;

        if (event.target.name === "priceTakeAway") {

            if (value === '') {
                value = 0
            }

            setItemObject({
                ...itemObject, "priceTakeAway": value,
                "priceTable": itemObject.priceTable === 0 || itemObject.priceTable === itemObject.priceTakeAway ? value : itemObject.priceTable,
                "priceDelivery": itemObject.priceDelivery === 0 || itemObject.priceDelivery === itemObject.priceTakeAway ? value : itemObject.priceDelivery,
                "priceOnDial": itemObject.priceOnDial === 0 || itemObject.priceOnDial === itemObject.priceTakeAway ? value : itemObject.priceOnDial,
                "priceOnline": itemObject.priceOnline === 0 || itemObject.priceOnline === itemObject.priceTakeAway ? value : itemObject.priceOnline,
                "priceDriveThru": itemObject.priceDriveThru === 0 || itemObject.priceDriveThru === itemObject.priceTakeAway ? value : itemObject.priceDriveThru
            });



            return;
        }

        if (event.target.name === "activeAllPrinter") {
            if (event.target.checked) {

                setItemObject({ ...itemObject, printers: "0,1,2,3,4" });
            } else {
                setItemObject({ ...itemObject, printers: "" });
            }
            return;
        }

        if (event.target.name.startsWith('Printer')) {

            if (event.target.checked) {

                const printerNumber = event.target.name.split("_")[1];

                if (itemObject.printers.split(",").findIndex(element => element === printerNumber) === -1) {
                    let prinertValue = itemObject.printers;

                    if (prinertValue !== "") {

                        prinertValue = prinertValue + "," + printerNumber
                    } else {

                        prinertValue = printerNumber

                    }

                    setItemObject({ ...itemObject, printers: prinertValue });

                }



            } else {


                let prinertValue = itemObject.printers;
                const printerNumber = event.target.name.split("_")[1];
                if (prinertValue.search("," + printerNumber) !== -1) {
                    prinertValue = prinertValue.replace("," + printerNumber, "")
                } else if (prinertValue.search(printerNumber + ",") !== -1) {

                    prinertValue = prinertValue.replace(printerNumber + ",", "")

                } else {
                    prinertValue = prinertValue.replace(printerNumber, "")


                }


                setItemObject({ ...itemObject, printers: prinertValue });


            }



            return;

        }


        if (event.target.name === "buttons-group-imageType") {


            if (value === 'color') {

                setItemObject({ ...itemObject, color: itemObject.color === 0 ? -11419154 : itemObject.color });



            } else {


                setItemObject({ ...itemObject, color: 0, image: itemObject.image === '' ||  itemObject.image === null ? getDefultItemImage : itemObject.image });


            }


            return
        }

        if (event.target.name === "name" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, textName: false })


        }




        setItemObject({ ...itemObject, [event.target.name]: value });


    };




    return (
        <Box component='form' sx={{ margin: setsaveItemFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}

            {addEditItemSettingReduser.loading === 'pending' && <Progress />}
            {addEditItemSettingReduser.loading === 'failed' && <APIErorr reduser={addEditItemSettingReduser} />}



            <CategoryMenu data={categoryList} categoryId={[itemObject, setItemObject]} />
            <Divider sx={{ margin: 2 }} />

            <TextField disabled={addEditItemSettingReduser.loading === 'pending'} error={showAlertMsg.textName} helperText={itemObject.name === '' && t("MSG2")} onChange={handleChange} value={itemObject.name} name="name" inputProps={{ size: 15, maxLength: "15", required: true }} id="name" sx={{ margin: 1 }} fullWidth label={t("ItemName")} variant="standard" type="text" />
            <Divider sx={{ margin: 2 }} />

            <Box disabled={addEditItemSettingReduser.loading === 'pending'} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignContent: "start", flexFlow: "wrap", marginTop: 2 }}>
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceTakeAway} id="priceTakeAway" name="priceTakeAway" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceTakeAway")} variant="standard" />
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceTable} id="priceTable" name="priceTable" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceTables")} variant="standard" />
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceOnDial} id="priceOnDial" name="priceOnDial" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceOnDial")} variant="standard" />
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceDelivery} id="priceDelivery" name="priceDelivery" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceDelivery")} variant="standard" />
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceDriveThru} id="priceDriveThru" name="priceDriveThru" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceDrive-Throw")} variant="standard" />
                <TextField disabled={addEditItemSettingReduser.loading === 'pending'} onChange={handleChange} value={itemObject.priceOnline} id="priceOnline" name="priceOnline" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceOnline")} variant="standard" />

            </Box>
            <Divider sx={{ margin: 2 }} />

            <FormControl disabled={addEditItemSettingReduser.loading === 'pending'} sx={{ m: setsaveItemFun !== null && 3 }} component="fieldset" variant="standard" >
                <FormControlLabel control={<Switch onChange={handleChange} name="activeAllPrinter" defaultChecked={itemObject.printers.split(",").length === 5} checked={itemObject.printers.split(",").length === 5} />} label={t("ActivateAllPrinters")} />
                <FormLabel component="legend">{t("KitchenPrinters")}</FormLabel>

                <FormControl component="fieldset" variant="standard" disabled={itemObject.printers.split(",").length === 5 || addEditItemSettingReduser.loading === 'pending'}>


                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox name="Printer_0" onChange={handleChange} checked={itemObject.printers.split(",").findIndex(element => element === "0") !== -1} defaultChecked={itemObject.printers.split(",").findIndex(element => element === "0") !== -1} />
                            }
                            label={t("Printer") + " 1"}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox name="Printer_2" onChange={handleChange} checked={itemObject.printers.split(",").findIndex(element => element === "2") !== -1} defaultChecked={itemObject.printers.split(",").findIndex(element => element === "2") !== -1} />
                            }
                            label={t("Printer") + " 3"}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox name="Printer_4" onChange={handleChange} checked={itemObject.printers.split(",").findIndex(element => element === "4") !== -1} defaultChecked={itemObject.printers.split(",").findIndex(element => element === "4") !== -1} />
                            }
                            label={t("Printer") + " 5"}
                        />
                    </FormGroup>
                </FormControl>
            </FormControl>

            <FormControl sx={{ m: setsaveItemFun !== null && 3, mt: setsaveItemFun !== null ? 10 : 8 }} component="fieldset" variant="standard" disabled={itemObject.printers.split(",").length === 5 || addEditItemSettingReduser.loading === 'pending'}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox name="Printer_1" onChange={handleChange} checked={itemObject.printers.split(",").findIndex(element => element === "1") !== -1} defaultChecked={itemObject.printers.split(",").findIndex(element => element === "1") !== -1} />
                        }
                        label={t("Printer") + " 2"}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox name="Printer_3" onChange={handleChange} checked={itemObject.printers.split(",").findIndex(element => element === "3") !== -1} defaultChecked={itemObject.printers.split(",").findIndex(element => element === "3") !== -1} />
                        }
                        label={t("Printer") + " 4"}
                    />

                </FormGroup>
            </FormControl>
            <Divider sx={{ margin: 2 }} />

            <FormControl disabled={addEditItemSettingReduser.loading === 'pending'} fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">{t("ImageType")}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="buttons-group-imageType"
                    value={itemObject.color === 0 ? "image" : "color"}
                    onChange={handleChange}
                >
                    <FormControlLabel value="color" control={<Radio />} label={t("Color")} />
                    <FormControlLabel value="image" control={<Radio />} label={t("Image")} />

                </RadioGroup>
            </FormControl>

            <Box sx={{ width: 150, hidden: 100 }}>




                {getAvatar(itemObject.color, itemObject.name, itemObject.image, false)}
                <ImageDialog control={[openImageDialog, setOpenImageDialog]} data={[itemObject, setItemObject]} />
                <ColorDialog control={[openColorDialog, setOpenColorDialog]} data={[itemObject, setItemObject]} />

                <Button
                    disabled={addEditItemSettingReduser.loading === 'pending'}
                    component="label"
                    onClick={() => setOpenColorDialog(true)}
                    variant="contained" sx={{ padding: "4px", height: "100%", display: itemObject.color === 0 && 'none' }} >{t("SelectColor")}
                </Button>

                <Button
                    disabled={addEditItemSettingReduser.loading === 'pending'}
                    component="label"
                    variant="contained" sx={{ padding: "4px", height: "100%", display: itemObject.color !== 0 && 'none' }} >
                    {t("UploadImage")}
                    <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        hidden
                        onChange={onChangePicture}
                        name="contentPath"

                    />

                </Button>




            </Box>
            <Box sx={{ display: setsaveItemFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' onClick={handelSaveItemButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>


        </Box>
    )
}

export default AddEditItems