import React, {  useState } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import moment from 'moment';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { getShiftName, getTransactType } from '../../../hepler';

import 'moment-timezone';
import { useSearchParams } from 'react-router-dom';

function createData(businessDayName,userName ,transactType ,shiftName,dateTime) {
  return {businessDayName,userName ,transactType ,shiftName,dateTime}

}



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



const headCells = [


  {
      id: 'businessDayName',
      numeric: false,
      disablePadding: true,
      label: 'CashDayName',
  },
  {
      id: 'userName',
      numeric: true,
      disablePadding: false,
      label: 'UserName',
  },

  {
    id: 'transactType',
    numeric: true,
    disablePadding: false,
    label: 'Transactions',
},


  {
      id: 'shiftName',
      numeric: true,
      disablePadding: false,
      label: 'Shift',
  },

  {
      id: 'dateTime',
      numeric: true,
      disablePadding: false,
      label: 'DateAndTime',
  }
];



function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } =
      props;
  const createSortHandler = (property) => (event) => {


      onRequestSort(event, property);
  };

  return (
      <TableHead>
          <TableRow>
             
              {headCells.map((headCell) => (
                  <TableCell
                      key={headCell.id}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      variant='head'
                  >

                      <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                      >
                          {t(headCell.label).toUpperCase()}
                          {orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                          ) : null}
                      </TableSortLabel>
                  </TableCell>
              ))}
          </TableRow>
      </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function StoreLogLitsComp(props) {



  const [searchParams, setSearchParams] = useSearchParams();
   
  const data = props.data;
  const content = data.content;
  const page = Number(searchParams.get(("PageNumber")) || 0)
  const rowsPerPage = Number(searchParams.get(("PageSize")) || 5)
  const order = searchParams.get(("SortOrder")) || "desc"
  const orderBy = searchParams.get(("SortBy")) || "businessDayName"








  const setPage = (value) => {
    searchParams.set("PageNumber", value)
    setSearchParams(searchParams);
}
const setRowsPerPage = (value) => {
    searchParams.set("PageSize", value)
    setSearchParams(searchParams);
}

const setOrder = (value) => {
    searchParams.set("SortOrder", value)
    setSearchParams(searchParams);
}
const setOrderBy = (value) => {
    searchParams.set("SortBy", value)
    setSearchParams(searchParams);
}
















    const shopInfo = props.shopInfo;

    const rows = [];


    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(true);



    












    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);


            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.totalElements) : 0;



      //  const emptyRows = 0 




  
    content.forEach(element => {

      let DateTimeUTC = moment(element.dateTime, "YYYY-MMM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
      DateTimeUTC = moment.utc(DateTimeUTC, 'YYYY-MM-DD HH:mm:ss')

      let DateTime = DateTimeUTC.clone().tz(shopInfo.timeZoneId)

      DateTime = DateTime.format("YYYY-MM-DD HH:mm:ss")

      let ShiftName;


      if (element.transactType !== 4 && element.transactType !== 1) {

        ShiftName = getShiftName(element.shiftName)

      } else {

        ShiftName = "--"

      }
       
        rows.push(createData(moment(element.businessDayName, "YYYY-MMM-DD").format('YYYY-MM-DD'),element.userName , getTransactType(element.transactType) ,ShiftName,DateTime)) ;

    });



    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {rows
                                .map((row, index) => {

                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.businessDayName}
                                            >
                                                <TableCell >{row.businessDayName}</TableCell>
                                                <TableCell >{row.userName}</TableCell>
                                                <TableCell >{t(row.transactType)}</TableCell>
                                                <TableCell >{row.shiftName}</TableCell>
                                                <TableCell >{row.dateTime}</TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25 ,50]}
                    component="div"
                    count={data.totalElements}
                    rowsPerPage={rowsPerPage}
                    
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}

                      ActionsComponent={TablePaginationActions}


                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={t("Densepadding")}
            />
        </Box>
    );
}
