import { t } from 'i18next';
import React from 'react'
import ReactApexChart from 'react-apexcharts';

export default function LineChartComp( props) {

    const data = props.data ; 
    const shopInfo = props.shopInfo ; 



    const Currency =  typeof shopInfo.currency === 'string' ? shopInfo.currency.toUpperCase() : shopInfo.currency ;


    const options = {

        series: [{
            name: t("CashDayName"),
            data: data.PriceArray
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: data.Name,
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: data.labelsArray,
            } , 


            yaxis: {
                labels: {
                  formatter: function (value) {
                    return value.toFixed(3) + Currency;
                  }
                },
              },
        },


    };


    return (
        <ReactApexChart options={options.options} series={options.series} type="line" height={350} />

    )
}
