import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import config from '../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
};





export const getFullReportByDate = createAsyncThunk(
  "FullReportByDate/getFullReportByDate",

  async (dispatch, { rejectWithValue }) => {
    try {

      const shopId = dispatch.shopId
      let ShiftName = dispatch.shiftName;
      let PriceType = dispatch.priceType;
      let voidService = dispatch.voidService
      let PaymentMethod = dispatch.paymentMethod 



      if (ShiftName === null || ShiftName === undefined || ShiftName === 'unset') {
        ShiftName = ''
      }


      if (PriceType === null || PriceType === undefined || PriceType === 'unset') {
        PriceType = ''
      }



      
      if (PaymentMethod === null){

        PaymentMethod = ''

      }



      voidService = ''






      const FromDate = moment(dispatch.from, "YYYY-MM-DD").format('YYYY-MMM-DD');
      const ToDate = moment(dispatch.to, "YYYY-MM-DD").format('YYYY-MMM-DD');

      const { data } = await axios.get(url + "/Apps/API/Reports/getMainFull?ShopId=" + shopId + "&DeviceId=0&QRKey=0&DayNameStart=" + FromDate + "&DayNameEnd=" + ToDate + "&ShiftName=" + ShiftName + "&PriceType=" + PriceType + "&VoidService=" + voidService + "&PaymentMethod="+PaymentMethod ,
        {
          withCredentials: true
        })
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);















export const FullReportsSlices = createSlice({

  name: "FullReportByDate",
  initialState,

  reducers: {
    Rest: (state, action) => {

      state.response = {}
      state.loading = 'idle'
      state.httpCode = 0
      state.errorMsg = ""
      state.errorData = {}

    }



  },



  extraReducers: {
    [getFullReportByDate.pending]: (state) => {
      state.loading = 'pending';
    },
    [getFullReportByDate.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [getFullReportByDate.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }








  }

})


export const { Rest } = FullReportsSlices.actions

export default FullReportsSlices.reducer;
