import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, useTheme } from '@mui/material'
import React from 'react'
import { t } from 'i18next';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCategorySettingAndItems } from '../../../../redux/slice/Settings/Category/getAllCategorySettingSlices';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import PropTypes from 'prop-types';
import SquareIcon from '@mui/icons-material/Square';
import { deleteItems, getAllItemsSetting } from '../../../../redux/slice/Settings/Items/getAllItemsSettingSlices';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddEditItems from './AddEditItems';
import { getHexColor } from '../../../hepler';
import { deleteItemSetting, reset as RestdeleteItemSetting } from '../../../../redux/slice/Settings/Items/deleteItemSettingSlices';
import AlertsDialog from '../../../Components/AlertsDialog';


function TablePaginationActions(props) {


  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t("FirstPage")}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t("PreviousPage")}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t("NextPage")}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t("LastPage")}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function createData(id, image, name, color) {
  return { id, image, name, color }

}

const headCells = [


  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    textAlignEnd: false,
    label: 'Id',
  },
  {
    id: 'image',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'Image',
  },


  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'ItemName',
  },



  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    textAlignEnd: true,
    label: 'Actions',
  },
];



function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {


    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant='head'
            sx={{ textAlign: headCell.textAlignEnd && 'End' }}
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label).toUpperCase()}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



const getAvatar = (color, name, image, isCategory) => {



  if (color === 0) {
    return <Avatar alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (color !== null) {



    return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(color), marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}



function AddEditDialog(props) {



  const [open, setOpen] = props.control
  const itemsData = props.data

  const categoryList = props.categoryList
  const dispatch = useDispatch();


  const addEditItemSettingReduser = useSelector((state) => state.addEditItemSettingReduser);

  const [saveItemFun, setsaveItemFun] = useState(() => { });

  const handleSave = () => {

    saveItemFun();

  }



  const handleClose = (isEdit) => {

    if (isEdit) {

      if (addEditItemSettingReduser.loading === 'succeeded') {
        setOpen(false);
        const [shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder] = props.searchParamsData
        dispatch(getAllItemsSetting({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))


      }


      
    } else {

      setOpen(false);
      const [shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder] = props.searchParamsData
      dispatch(getAllItemsSetting({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))



    }



  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => handleClose(false)}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{itemsData === null ? t("AddNew") : itemsData.name}</DialogTitle>
        <DialogContent dividers={true}>

          <AddEditItems control={[setsaveItemFun, handleClose]} item={itemsData} categoryList={categoryList} searchParamsData={props.searchParamsData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>{t("Save")}</Button>
          <Button onClick={() => handleClose(false)}>{t("Cancel")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




function ItemsDesktop() {


  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const itemsList = []
  const [openDialog, setOpenDialog] = useState(false);
  const [EditRowData, setEditRowData] = useState(null);
  const [deleteItemPrgress, setDeleteItemPrgress] = useState(null);

  const pageNumber = Number(searchParams.get(("PageNumber")) || 0)
  const pageSize = Number(searchParams.get(("PageSize")) || 5)
  const sortOrder = searchParams.get(("SortOrder")) || "desc"
  const sortBy = searchParams.get(("SortBy")) || "id"
  const [dense, setDense] = useState(true);
  const shopId = searchParams.get("ShopId")
  const categoryId = searchParams.get("CategoryId") || null;
  const itemsListReduser = useSelector((state) => state.getAllItemsSettingReduser);
  const categoryReduser = useSelector((state) => state.getAllCategorySettingReduser);
  const deleteItemSettingReduser = useSelector((state) => state.deleteItemSettingReduser);

  const [dalilogAlretObject, setDalilogAlretObject] = useState({
    open: false,
    msg: "",
    title: "",
    showButtontOne: false,
    buttontOneTitle: "",
    buttonOneFun: () => { },
    showButtontTow: false,
    buttontTowTitle: "",
    buttonTowFun: () => { }
  })


  const [isFisrtLunch, setFisrtLunch] = useState(true)


  useEffect(() => {
    dispatch(getAllCategorySettingAndItems({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!isFisrtLunch) {
      searchParams.delete("PageNumber")
      setSearchParams(searchParams)
      searchParams.delete("PageSize")
      setSearchParams(searchParams)
      searchParams.delete("SortOrder")
      setSearchParams(searchParams)
      searchParams.delete("SortBy")
      setSearchParams(searchParams)
    }





    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId])




  useEffect(() => {
    if (!isFisrtLunch) {
      searchParams.delete("CategoryId")
      setSearchParams(searchParams)
      const categoryId = null;
      const pageNumber = 0;
      const pageSize = 5;
      const sortBy = 'id';
      const sortOrder = 'desc';
      dispatch(getAllCategorySettingAndItems({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))
    }
    setFisrtLunch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId])


  useEffect(() => {

    if (deleteItemSettingReduser.loading === 'succeeded') {

      dispatch(deleteItems(deleteItemPrgress))
      setDeleteItemPrgress(null)
      dispatch(RestdeleteItemSetting())

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItemSettingReduser.loading])


  useEffect(() => {


    if (categoryReduser.loading === 'succeeded') {

      dispatch(getAllItemsSetting({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, pageNumber, pageSize, sortBy, sortOrder])




  if (categoryReduser.loading === "failed") {
    return (<APIErorr reduser={categoryReduser} />)
  }

  if (((categoryReduser.loading === "pending" || categoryReduser.loading === "idle" || itemsListReduser.loading === "pending" || itemsListReduser.loading === "idle")) || shopId === null) {


    return (<Progress />)
  }


  if (itemsListReduser.loading === "failed") {
    return (<APIErorr reduser={itemsListReduser} />)
  }



  const totalElements = itemsListReduser.response.totalElements


  const setPageNumber = (value) => {
    searchParams.set("PageNumber", value)
    setSearchParams(searchParams);
  }
  const setPageSize = (value) => {
    searchParams.set("PageSize", value)
    setSearchParams(searchParams);
  }

  const setSortOrder = (value) => {
    searchParams.set("SortOrder", value)
    setSearchParams(searchParams);
  }
  const setSortBy = (value) => {
    searchParams.set("SortBy", value)
    setSearchParams(searchParams);
  }




  itemsListReduser.response.content.forEach((data) => {
    itemsList.push(createData(data.id, data.image, data.name, data.color))
  })





  const CategoryMenu = (prop) => {

    const [searchParams, setSearchParams] = useSearchParams();



    const categoryList = prop.data





    const categoryId = searchParams.get("CategoryId") || '';


    const handleChange = (event) => {
      const value = event.target.value;
      if (value === '') {

        searchParams.delete("CategoryId")
        setSearchParams(searchParams)


      } else {

        searchParams.set("CategoryId", value)
        setSearchParams(searchParams)


      }
    };



    return (
      <>
        <FormControl sx={{ margin: 1, minWidth: 250 }}>
          <Select
            value={categoryId}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={''}>
              <em>{t("NotSpecified")}</em>
            </MenuItem>


            {categoryList.map((data, index) => (

              <MenuItem key={index} value={data.id}>
                <Box sx={{ display: "flex" }}>
                  {getAvatar(data.color, data.name, data.image, true)}
                  <Typography sx={{ marginLeft: 2 }}>{data.name}</Typography>

                </Box>
              </MenuItem>



            ))}
          </Select>
        </FormControl>

      </>
    )




  }


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = itemsList.map((n) => n.name);


      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);

  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const emptyRows = pageNumber > 0 ? Math.max(0, (1 + pageNumber) * pageSize - totalElements) : 0;

  const handleRequestSort = (event, property) => {

    if (property !== "actions") {
      const isAsc = sortBy === property && sortOrder === 'asc';

      setSortOrder(isAsc ? 'desc' : 'asc');
      setSortBy(property);
    }
  };


  const handAddNewButton = () => {
    setEditRowData(null);
    setOpenDialog(true)
  }


  const handEditButton = (index) => {

    setEditRowData(itemsListReduser.response.content[index]);

    setOpenDialog(true)

  }





  const handeleDeleteButtonAsync = (index) => async (dispatch) => {
    const itemObject = itemsListReduser.response.content[index]


    setDalilogAlretObject({ ...dalilogAlretObject, "open": false })
    setDeleteItemPrgress(itemObject.id)

    await dispatch(deleteItemSetting({ shopId, itemObject }))






  }








  const handeleDeleteButton = (index) => {


    setDalilogAlretObject({
      ...dalilogAlretObject,
      "open": true,
      "title": t("Delete"),
      "msg": t("MSG1"),
      "showButtontTow": true,
      "buttontTowTitle": t("Yes"),
      "buttonTowFun": () => dispatch(handeleDeleteButtonAsync(index)),
      "showButtontOne": true,
      "buttontOneTitle": t("No"),
      "buttonOneFun": () => setDalilogAlretObject({ ...dalilogAlretObject, "open": false }),
    })




  }



  return (

    <Box sx={{ width: '100%', padding: 2 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={sortOrder}
              orderBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={itemsList.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {itemsList
                .map((row, index) => {

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell >{row.id}</TableCell>
                        <TableCell >{getAvatar(row.color, row.name, row.image, false)}</TableCell>
                        <TableCell >{row.name}</TableCell>
                        <TableCell sx={{ textAlign: "end" }}>
                          <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>
                            <IconButton edge="start" aria-label="edit" onClick={() => handEditButton(index)} sx={{ display: 'flex', flexDirection: "column" }}>
                              <Avatar sx={{ bgcolor: "success.main" }} variant="square" >
                                <EditIcon />
                              </Avatar>
                              <Typography variant='body2'>{t("Edit")}</Typography>
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handeleDeleteButton(index)} sx={{ display: 'flex', flexDirection: "column" }}>
                              <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >
                                <DeleteIcon />
                              </Avatar>
                              <Typography variant='body2'>{t("Delete")}</Typography>
                            </IconButton>


                          </Box>


                          {(deleteItemPrgress === row.id && deleteItemSettingReduser.loading === 'pending') && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                            <Progress />

                          </Box>
                          }



                          {(deleteItemPrgress === row.id && deleteItemSettingReduser.loading === 'failed') && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                            <APIErorr reduser={deleteItemSettingReduser} />
                          </Box>
                          }


                        </TableCell>

                      </TableRow>

                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalElements}
          rowsPerPage={pageSize}

          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          SelectProps={{
            inputProps: {
              'aria-label': t('RowsPerPage'),
            },
            native: true,
          }}

          ActionsComponent={TablePaginationActions}


        />
      </Paper>
      <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignContent: "end" }}>


        <CategoryMenu data={categoryReduser.response} />

        <Box sx={{ display: "flex" }}>



          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label={t("Densepadding")}
          />
          <Button onClick={handAddNewButton} variant='contained' sx={{ width: 100, height: 40, marginTop: 2 }}>{t("AddNew")}</Button>

        </Box>

      </Box>

      <AddEditDialog control={[openDialog, setOpenDialog]} searchParamsData={[shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder]} data={EditRowData} categoryList={categoryReduser.response} />
      <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />
    </Box>

  )
}

export default ItemsDesktop