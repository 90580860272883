import React from 'react'


import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from './image/logo.png'
import pic1 from './image/London-UK.jpg'

import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const ToolBarFullScreent = (props) => {
    const scrollPosition = props.scrollPosition;
    const transitionAnmie = props.transitionAnmie;
    const handelScrollDownButton = props.handelScrollDownButton




    return (


        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "center" }}>



            <Box sx={{ pr: 2, pl: 2 }}>

                <img src={Logo} alt="Logo" style={{ height: scrollPosition > 0 ? 90 : 70, transition: `height ${transitionAnmie}` }} />



            </Box>

            <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "end", gap: 2, pr: 2, pl: 2 }}  >
                <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column" }} onClick={() => handelScrollDownButton("Home")}>
                    <Typography sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} variant='h6' >الصفحه الرائسية</Typography>
                    <Divider sx={{ display: scrollPosition !== 1 && 'none', width: "100%", bgcolor: "secondary.main", p: 0.09 }} />

                </Button>
                <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column" }} onClick={() => handelScrollDownButton("AboutUs")}>

                    <Typography sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} variant='h6'> من نحن</Typography>
                    <Divider sx={{ display: scrollPosition !== 2 && 'none', width: "100%", bgcolor: "secondary.main", p: 0.09 }} />

                </Button>
                <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column" }} onClick={() => handelScrollDownButton("Services")}>

                    <Typography sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} variant='h6'>  خدمتنا</Typography>
                    <Divider sx={{ display: scrollPosition !== 3 && scrollPosition !== 5 && 'none', width: "100%", bgcolor: "secondary.main", p: 0.09 }} />

                </Button>


                <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column" }} onClick={() => handelScrollDownButton("TeamWork")}>

                    <Typography sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} variant='h6'>  الفريق</Typography>
                    <Divider sx={{ display: scrollPosition !== 4 && 'none', width: "100%", bgcolor: "secondary.main", p: 0.09 }} />

                </Button>

                <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column" }} onClick={() => handelScrollDownButton("ContactUs")}>

                    <Typography sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} variant='h6'>  الاتصال</Typography>

                    <Divider sx={{ display: scrollPosition !== 6 && 'none', width: "100%", bgcolor: "secondary.main", p: 0.09 }} />

                </Button>


                <IconButton onClick={() => handelScrollDownButton("ContactUs")}>

                    <EmailIcon sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} fontSize='large' />

                </IconButton>

                <a href="tel:+962-6-585-50-99" style={{ paddingTop: 6 }}>

                    <LocalPhoneIcon sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} fontSize='large' />

                </a>



            </Box>

        </Box>



    )


}




const ToolBarMobiel = (props) => {
    const scrollPosition = props.scrollPosition;
    const transitionAnmie = props.transitionAnmie;
    const [open, setOpen] = props.open;

    const handelScrollDownButton = props.handelScrollDownButton

    const handelClick = (ref) => {

        handelScrollDownButton(ref)

        setOpen(false);


    }


    return (
        <Box>

            <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "center" , p:2 }}>


                <IconButton edge="start" aria-label="open drawer" onClick={() => setOpen(!open)}>
                    <MenuIcon sx={{ mr: 1, ml: 1, color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} />

                </IconButton>
                <Box sx={{pl:2}}>

                    <img src={Logo} alt="Logo" style={{ height: "50px" }} />



                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <IconButton sx={{ display: "flex", flexFlow: "row", justifyContent: "end", gap: 2, pr: 2, pl: 2 }} onClick={() => handelClick("ContactUs")}>
                        <EmailIcon sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} />




                    </IconButton>
                    <a href="tel:+962-6-585-50-99" style={{paddingTop:4}}>

                        <LocalPhoneIcon sx={{ color: scrollPosition > 0 ? "primary.main" : "customA.main", transition: `color ${transitionAnmie}` }} />

                    </a>


                </Box>




            </Box>

            <Box className='Menue' sx={{ display: !open && 'none', width: open ? "100%" : 0, minHeight: open ? "100%" : 0, backgroundColor: "white",top:0 ,position: open ? "fixed" : "absolute", opacity: open ? 100 : 0, transition: `height 0ms ${transitionAnmie}, opacity ${transitionAnmie} 0ms` }}>


                <IconButton onClick={() => setOpen(!open)}>
                    <CloseIcon fontSize='large' sx={{ mr: 1, ml: 1, color: "primary.main", transition: `color ${transitionAnmie}` }} />



                </IconButton>


                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column", minHeight: "90vh", width: "100%", gap: 2 }}>
                    <IconButton onClick={() => handelClick("Home")}>
                        <Typography sx={{ color: "secondary.main" }} variant="h5" >الصفحه الرائسية</Typography>

                    </IconButton>
                    <IconButton onClick={() => handelClick("AboutUs")}>
                        <Typography sx={{ color: "secondary.main" }} variant="h5" > من نحن</Typography>

                    </IconButton>


                    <IconButton onClick={() => handelClick("Services")}>
                        <Typography sx={{ color: "secondary.main" }} variant="h5" > خدمتنا</Typography>

                    </IconButton>

                    <IconButton onClick={() => handelClick("TeamWork")}>
                        <Typography sx={{ color: "secondary.main" }} variant="h5" > الفريق</Typography>

                    </IconButton>

                    <IconButton onClick={() => handelClick("CallUs")}>
                        <Typography sx={{ color: "secondary.main" }} variant="h6" > الاتصال</Typography>

                    </IconButton>

                </Box>
            </Box>
        </Box>

    )


}



export default function NavBar(props) {
    const { i18n } = useTranslation();


    const haederHight = props.haederHight;

    const [open, setOpen] = useState(false)

    const handelScrollDownButton = props.handelScrollDownButton;


    const transitionAnmie = "1s"

    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));


    const scrollPosition = props.scrollPosition

















    return (
        <Box>
            <Box sx={{ backgroundColor: scrollPosition > 0 && "customA.main", transition: `background-color (${transitionAnmie})`, display: 'flex', flexFlow: "column", alignContent: "space-around", gap: 2, height: open ? "100%" : matches ? "100px" :"70px", width: "100%", justifyContent: "center", position: "fixed", boxShadow: "7px 7px 50px grey", zIndex: 1030, top: 0 }}>

                {matches ? <ToolBarFullScreent handelScrollDownButton={handelScrollDownButton} scrollPosition={scrollPosition} transitionAnmie={transitionAnmie} /> : <ToolBarMobiel handelScrollDownButton={handelScrollDownButton} scrollPosition={scrollPosition} transitionAnmie={transitionAnmie} open={[open, setOpen]} />}

            </Box>

            <Box sx={{ position: "relative" }}>


                <Box className='parallax-image' sx={{ backgroundImage: `url(${pic1})`, position: "absolute", backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100%", height: haederHight, opacity: "0.5" }} > </Box>




                <Box sx={{ display: "flex", flexFlow: "column", position: "relative", justifyContent: "center", alignItems: "center", top: "100px", height: "650px", width: "100%", transition: `position ${transitionAnmie}`, zIndex: "10" }}>
                    <img src={Logo} alt="Logo" style={{ height: "200px" }} />


                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>


                        <Typography color="primary" variant={matches ? "h1" : "h3"} >عبيدات</Typography>
                        <Typography color="secondary" variant={matches ? "h1" : "h3"}>و</Typography>
                        <Typography color="primary" variant={matches ? "h1" : "h3"}>الصالح</Typography>

                    </Box>

                    <Typography color="primary" variant={matches ? "h2" : "h4"}>محاسبون قانونيون</Typography>




                </Box>


            </Box>





        </Box>

    );
}

