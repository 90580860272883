import { Card, Fab, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAccountActions } from '../../../redux/slice/Account/AccountSlice';
import APIErorr from '../../Components/APIErorr';
import Progress from '../../Components/Progress';
import FilterSettingComp from './Components/FilterSettingComp';
import ItemsDesktop from './Pages/ItemsDesktop';
import ItemsMobile from './Pages/ItemsMobile';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import i18n from '../../../i18n';
import { defultSettingsPageView, getFindIndexSettingsPageView } from '../../hepler';
import CardErorr from '../../Components/CardErorr';
import { t } from 'i18next';
import CategoryDesktop from './Pages/CategoryDesktop';
import CategoryMobile from './Pages/CategoryMobile';
import NoteAndExtraDesktop from './Pages/NoteAndExtraDesktop';
import NoteAndExtraMobile from './Pages/NoteAndExtraMobile';
import UserPermissionsMobile from './Pages/UserPermissionsMobile';
import MandatoryItems from './Pages/MandatoryItems';
import KitchenMonitor from './Pages/KitchenMonitor';
import UserPermissionsDesktop from './Pages/UserPermissionsDesktop';
import DescribeReasonMessagesDesktop from  './Pages/DescribeReasonMessagesDesktop' ;
import DescribeReasonMessagesMobile from './Pages/DescribeReasonMessagesMobile';
import Store from './Pages/Store';

function Setting() {

  const dispatch = useDispatch();
  const [searchParams, setsearchParams] = useSearchParams();
  let { viewPage } = useParams();

  let { viewSubPage } = useParams();

  const [shopId, setShopId] = useState(searchParams.get(("ShopId")) || null)



  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));


  const Account = useSelector((state) => state.accountReduser);

  let ShopInfo = null;



  const [offset, setOffset] = useState(0);




  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);




  useEffect(() => {

    if (Account.loading === "idle") {

      dispatch(getAccountActions())


    }

  }, [Account, dispatch])


  useEffect(() => {

    setShopId(searchParams.get(("ShopId")))

    if (shopId === null && ShopInfo === null && Account.httpCode === 200) {

      setShopId(Account.response.shopsEntities[0].id)
      searchParams.set("ShopId", Account.response.shopsEntities[0].id)
      setsearchParams(searchParams);

    }


  }, [searchParams, setShopId, ShopInfo, Account, setsearchParams, shopId])







  if (Account.httpCode === 200) {
    Account.response.shopsEntities.forEach(data => {

      if (data.id === Number(shopId)) {
        ShopInfo = data;

      }

    });

  }




  if (Account.loading === "pending" || Account.loading === "idle" || shopId === null) {

    return (<Progress />)
  }
  if (Account.loading === "failed") {
    return (<APIErorr reduser={Account} />)
  }


  const FabScrolling = () => (

    <Fab sx={{
      display: offset > 200 ? "fixed" : "none",
      position: "fixed",
      right: i18n.dir() !== 'rtl' && 20,
      bottom: (theme) => theme.spacing(10),
    }}

      color="primary" aria-label="add"
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}
      size='medium'




    >
      <KeyboardArrowUpSharpIcon sx={{ fontSize: "30px" }} />
    </Fab>



  )



  return (

    <Card sx={{ backgroundColor: "rgb(255, 255, 255 , .6)"}}>

     { viewSubPage=== undefined && <FilterSettingComp /> }


      {viewPage === defultSettingsPageView[0] ? matches ? <ItemsDesktop /> : <> <FabScrolling /> <ItemsMobile /></> :""}
      {viewPage === defultSettingsPageView[1] ? matches ? <CategoryDesktop /> : <> <FabScrolling /> <CategoryMobile /></> :""}
      {viewPage === defultSettingsPageView[2] ? matches ? <NoteAndExtraDesktop /> : <> <FabScrolling /> <NoteAndExtraMobile /></> :""}
      {viewPage === defultSettingsPageView[3] && <MandatoryItems/>  }
      {viewPage === defultSettingsPageView[4] && <KitchenMonitor/>  }
      {viewPage === defultSettingsPageView[5] ? matches ? <UserPermissionsDesktop /> : <> <FabScrolling /> <UserPermissionsMobile /></> :""}

      {viewPage === defultSettingsPageView[6] ? matches ? <DescribeReasonMessagesDesktop /> : <> <FabScrolling /> <DescribeReasonMessagesMobile /></> :""}
      {viewPage === defultSettingsPageView[7] && <Store/>  }



      {getFindIndexSettingsPageView(viewPage) === -1 && <CardErorr Msg={t("PageNotfound")}></CardErorr>}


    </Card>

  )
}

export default Setting