import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import config from '../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const getInvoicesReports = createAsyncThunk(
    "InvoicesReports/InvoicesReports",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        let ShiftName = dispatch.shiftName ;
        let PriceType = dispatch.priceType ; 

        let VoidService = dispatch.voidService ; 
        let PaymentMethod = dispatch.paymentMethod 
        let PageNumber = dispatch.pageNumber 
        let PageSize = dispatch.pageSize ; 
        let SortBy = dispatch.sortBy ; 
        let SortOrder = dispatch.sortOrder ; 



        if (ShiftName === null){
           ShiftName = ''

        }
        if (PriceType === null){

          PriceType = ''

        }



        if (VoidService === null){

          VoidService = ''

        }


        if (PaymentMethod === null){

          PaymentMethod = ''

        }

        if (PageNumber === null){

          PageNumber = 0 ;
        }

        if (PageSize === null){

          PageSize = 5 
        }

        if (SortBy === null){

          SortBy = "invoiceNumber"
        }


        if (SortOrder === null){

          SortOrder = "desc"
        }





        const  FromDate =  moment(dispatch.from, "YYYY-MM-DD").format('YYYY-MMM-DD');             
        const  ToDate =   moment(dispatch.to, "YYYY-MM-DD").format('YYYY-MMM-DD');                                                                                                                                                                                          
  
        const { data } = await axios.get(url + "/Apps/API/Reports/getInvoices?ShopId="+shopId+"&DeviceId=0&QRKey=0&DayNameStart="+FromDate+"&DayNameEnd="+ToDate+"&ShiftName="+ShiftName+"&PriceType="+PriceType+"&PaymentMethod="+PaymentMethod +"&VoidService="+VoidService+"&PageNumber="+PageNumber +"&PageSize="+PageSize+"&SortBy="+SortBy+"&SortOrder="+SortOrder ,
          {
            withCredentials: true
          })
        return data;
      } catch (err) {
  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );



  export const InvoicesReportsSlices = createSlice({

    name: "InvoicesReports",
    initialState,
  
    reducers: {
      Rest: (state, action) => {
  
        state.response = {}
        state.loading = 'idle'
        state.httpCode = 0
        state.errorMsg = ""
        state.errorData = {}
  
      }
  
  
  
    },
  
  
  
    extraReducers: {
      [getInvoicesReports.pending]: (state) => {
        state.loading = 'pending';
      },
      [getInvoicesReports.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [getInvoicesReports.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })
  
  export const { Rest } = InvoicesReportsSlices.actions
  
  export default InvoicesReportsSlices.reducer;