import {Button, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid2 from '@mui/material/Unstable_Grid2';

import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditUserPermissionsSettings, reset as restAddEditUserPermissionsSettings } from '../../../../redux/slice/Settings/UserPermissions/addEditUserPermissionsSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';
import CardErorr from '../../../Components/CardErorr';








function AddEditUserPermissions(props) {

    const [setsaveUserPermissionsFun, handleClose] = props.control || [null, null];
    const userPermissionsData = props.UserPermissions
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);
    const accountTypeList = ["Admin", "Manger", "Casher", "Kitchen Employee", "Waiter"]




    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        userName: false,
        showPassword: false,
        easyAccessKeyMsg1: false,
        easyAccessKeyMsg2: false,
        easyAccessKeyMsg3: false,


    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditUserPermissionsSettingReduser = useSelector((state) => state.addEditUserPermissionsSettingReduser);





    const [dataObject, setdataObject] = useState(userPermissionsData === null ?

        {
            "id": 0,
            "userName": "",
            "easyAccessKey": "",
            "accountType": "Manger",
            "voidServices": false,
            "voidMinimumCharge": false,
            "voidTax": false,
            "refund": false,
            "defaultItem": false,
            "makeDiscount": false,
            "maxDiscountPrice": 0,
            "openAndCloseCashDay": false,
            "openAndEditDeviceSetting": false,
            "viewReports": false,
            "viewExpressReports": false,
            "viewAndEditBackOfficeSetting": false,
            "openWaiterSystem": false,
            "openKitchenMonitor": false,
            "openCashAndPay": false

        } : userPermissionsData


    )






    const handelSaveUserPermissions = () => async (dispatch) => {

        if (dataObject.userName === '') {
            setShowAlertMsg({ ...showAlertMsg, userName: true })


            return;
        }


        if (dataObject.easyAccessKey < 4) {


            setShowAlertMsg({ ...showAlertMsg, easyAccessKeyMsg3: true })

            return;

        }


        setShowsuccessMsg(false)
        await dispatch(addEditUserPermissionsSettings({ shopId, dataObject }))


    }









    useEffect(() => {



        if (addEditUserPermissionsSettingReduser.loading === "succeeded") {
            if (dataObject.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                setdataObject(

                    {
                        "id": 0,
                        "userName": "",
                        "easyAccessKey": "",
                        "accountType": "Manger",
                        "voidServices": false,
                        "voidMinimumCharge": false,
                        "voidTax": false,
                        "refund": false,
                        "defaultItem": false,
                        "makeDiscount": false,
                        "maxDiscountPrice": 0,
                        "openAndCloseCashDay": false,
                        "openAndEditDeviceSetting": false,
                        "viewReports": false,
                        "viewExpressReports": false,
                        "viewAndEditBackOfficeSetting": false,
                        "openWaiterSystem": false,
                        "openKitchenMonitor": false,
                        "openCashAndPay": false

                    })







            }

            dispatch(restAddEditUserPermissionsSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditUserPermissionsSettingReduser])















    useEffect(() => {
        ///For desktop window size only

        if (setsaveUserPermissionsFun != null) {

            setsaveUserPermissionsFun(() => () => dispatch(handelSaveUserPermissions()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataObject, setsaveUserPermissionsFun])





    ///For Mobile window size only
    const handelSaveButton = () => {
        dispatch(handelSaveUserPermissions());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/UserPermissions",
            search: "?" + searchParams.toString()


        })

    }

    const handleChangeSwitchs = (e, value) => {

        setdataObject({ ...dataObject, [e.target.name]: value });


    }



    const handleChange = (event) => {

        let value = event.target.value;




        if (event.target.name === "maxDiscountPrice") {

            if (value === '') {
                value = 0
            } else {

                setdataObject({ ...dataObject, [event.target.name]: Number(value) });

            }
            return;
        }


        if (event.target.name === "accountType") {
            const index = accountTypeList.findIndex(element => element === value);

            switch (index) {


                case 1: {

                    setdataObject({
                        ...dataObject,

                        "voidServices": true,
                        "voidMinimumCharge": true,
                        "voidTax": true,
                        "refund": true,
                        "defaultItem": true,
                        "makeDiscount": true,
                        "openAndCloseCashDay": true,
                        "openAndEditDeviceSetting": true,
                        "viewReports": false,
                        "viewExpressReports": true,
                        "viewAndEditBackOfficeSetting": false,
                        "openWaiterSystem": true,
                        "openKitchenMonitor": true,
                        "openCashAndPay": true,
                        [event.target.name]: value



                    })


                    return;
                }



                
                case 2: {

                    setdataObject({
                        ...dataObject,

                        "voidServices": false,
                        "voidMinimumCharge": false,
                        "voidTax": false,
                        "refund": false,
                        "defaultItem": false,
                        "makeDiscount": false,
                        "openAndCloseCashDay": true,
                        "openAndEditDeviceSetting": false,
                        "viewReports": false,
                        "viewExpressReports": true,
                        "viewAndEditBackOfficeSetting": false,
                        "openWaiterSystem": false,
                        "openKitchenMonitor": false,
                        "openCashAndPay": false,
                        [event.target.name]: value



                    })


                    return;
                }


                case 3: {

                    setdataObject({
                        ...dataObject,

                        "voidServices": false,
                        "voidMinimumCharge": false,
                        "voidTax": false,
                        "refund": false,
                        "defaultItem": false,
                        "makeDiscount": false,
                        "openAndCloseCashDay": false,
                        "openAndEditDeviceSetting": true,
                        "viewReports": false,
                        "viewExpressReports": false,
                        "viewAndEditBackOfficeSetting": false,
                        "openWaiterSystem": false,
                        "openKitchenMonitor": true,
                        "openCashAndPay": false,
                        [event.target.name]: value



                    })


                    return;
                }


                case 4: {

                    setdataObject({
                        ...dataObject,

                        "voidServices": false,
                        "voidMinimumCharge": false,
                        "voidTax": false,
                        "refund": false,
                        "defaultItem": false,
                        "makeDiscount": false,
                        "openAndCloseCashDay": false,
                        "openAndEditDeviceSetting": true,
                        "viewReports": false,
                        "viewExpressReports": false,
                        "viewAndEditBackOfficeSetting": false,
                        "openWaiterSystem": true,
                        "openKitchenMonitor": false,
                        "openCashAndPay": false,
                        [event.target.name]: value



                    })


                    return;
                }

                default:
                    break




            }

        }




        if (event.target.name === "userName" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, userName: false })


        } else {

            setShowAlertMsg({ ...showAlertMsg, userName: true })



        }

        if (event.target.name === "easyAccessKey") {

            if (!/^[0-9]+$/.test(value) && value !== "") {

                setShowAlertMsg({ ...showAlertMsg, easyAccessKeyMsg2: true })
                return;

            }


            if (dataObject.easyAccessKey === "" && value === "0") {

                setShowAlertMsg({ ...showAlertMsg, easyAccessKeyMsg1: true })
                return;

            }


            let easyAccessKeyMsg3 = false;
            if (value.length < 4) {

                easyAccessKeyMsg3 = true;
            }

            setShowAlertMsg({ ...showAlertMsg, easyAccessKeyMsg1: false, easyAccessKeyMsg2: false, easyAccessKeyMsg3 })

            setdataObject({ ...dataObject, [event.target.name]: Number(value) });

            return;
        }




        setdataObject({ ...dataObject, [event.target.name]: value });


    };


    const handleClickShowPassword = () => {


        setShowAlertMsg({ ...showAlertMsg, showPassword: !showAlertMsg.showPassword })


    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const checkShowMenu = (key) => {

        if (key === 0 && dataObject.accountType === "Admin") {
            return true
        }

        if (key !== 0 && dataObject.accountType !== "Admin") {


            return true

        }



        return false





    }


   


    return (
        <Box component='form' sx={{ margin: setsaveUserPermissionsFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}

            {addEditUserPermissionsSettingReduser.loading === 'pending' && <Progress />}
            {addEditUserPermissionsSettingReduser.loading === 'failed' && addEditUserPermissionsSettingReduser.httpCode !== 409 && !addEditUserPermissionsSettingReduser.viewErrorOnForm ? <CardErorr Msg={addEditUserPermissionsSettingReduser.httpCode + " " + addEditUserPermissionsSettingReduser.errorMsg} /> : ''}
            {addEditUserPermissionsSettingReduser.loading === 'failed' && addEditUserPermissionsSettingReduser.httpCode === 409 && !addEditUserPermissionsSettingReduser.viewErrorOnForm ? <CardErorr Msg={t("MSG15")} /> : ''}



            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, flexFlow: "wrap" }}>


                <FormControl disabled={addEditUserPermissionsSettingReduser.loading === "pending"} fullWidth>
                    <InputLabel id="AccountType_L">{t("AccountType")}</InputLabel>
                    <Select
                        labelId="AccountType_L"
                        id="accountType"
                        name="accountType"
                        onChange={handleChange}
                        label={t("AccountType")}
                        value={dataObject.accountType}

                    >
                        {accountTypeList.map((data, key) => (
                            <MenuItem sx={{ display: checkShowMenu(key) === false && "none" }} key={key} value={data}>{t(data)}</MenuItem>

                        ))}

                    </Select>
                </FormControl>


                <TextField fullWidth disabled={addEditUserPermissionsSettingReduser.loading === 'pending'} error={showAlertMsg.userName} helperText={dataObject.userName === '' && t("MSG8")} onChange={handleChange} value={dataObject.userName} name="userName" inputProps={{ size: 15, maxLength: "15", required: true }} id="userName" sx={{ margin: 1 }} label={t("UserName")} variant="standard" type="text" />




                <FormControl error={showAlertMsg.easyAccessKeyMsg1 || showAlertMsg.easyAccessKeyMsg2 || showAlertMsg.easyAccessKeyMsg3} fullWidth variant="standard">
                    <InputLabel htmlFor="easyAccessKey-password">{t("EasyAccessKey")}</InputLabel>
                    <Input
                        id="easyAccessKey-password"
                        type={showAlertMsg.showPassword ? 'text' : 'password'}
                        value={dataObject.easyAccessKey}
                        name="easyAccessKey"
                        disabled={addEditUserPermissionsSettingReduser.loading === 'pending'}
                        onChange={handleChange}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="easyAccessKey password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showAlertMsg.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    {showAlertMsg.easyAccessKeyMsg1 && <FormHelperText id="component-error-text">{t("MSG12")}</FormHelperText>}
                    {showAlertMsg.easyAccessKeyMsg2 && <FormHelperText id="component-error-text">{t("MSG13")}</FormHelperText>}
                    {showAlertMsg.easyAccessKeyMsg3 && <FormHelperText id="component-error-text">{t("MSG14")}</FormHelperText>}

                </FormControl>
                <TextField disabled={addEditUserPermissionsSettingReduser.loading === 'pending' || dataObject.accountType === "Admin"} onChange={handleChange} value={dataObject.maxDiscountPrice} id="maxDiscountPrice" name="maxDiscountPrice" type="number" inputProps={{ type: "number", step: "0.01" }}    sx={{ margin: 1, width: "25%" }} label={t("MaxDiscountPrice")} variant="standard" />

            </Box>


            <Divider sx={{ margin: 2 }} />

            <FormGroup  >
                <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="makeDiscount" checked={dataObject.makeDiscount} />} label={t("Discounts")} />

                    </Grid2>

                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="viewAndEditBackOfficeSetting" checked={dataObject.viewAndEditBackOfficeSetting} />} label={t("BackOffice")} />

                    </Grid2>


                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="refund" checked={dataObject.refund} />} label={t("Refunds")} />

                    </Grid2>

                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="openAndCloseCashDay" checked={dataObject.openAndCloseCashDay} />} label={t("Open/ClosePOS")} />

                    </Grid2>


                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="voidServices" checked={dataObject.voidServices} />} label={t("VoidServices")} />

                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin"|| addEditUserPermissionsSettingReduser.loading === 'pending' } control={<Switch onChange={handleChangeSwitchs} name="openWaiterSystem" checked={dataObject.openWaiterSystem} />} label={t("RunWaiterSystem")} />

                    </Grid2>

                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="voidTax" checked={dataObject.voidTax} />} label={t("VoidTax")} />

                    </Grid2>
                    <Grid2 xs={6}>

                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending' } control={<Switch onChange={handleChangeSwitchs} name="openKitchenMonitor" checked={dataObject.openKitchenMonitor} />} label={t("RunKitchenMonitors")} />
                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="defaultItem" checked={dataObject.defaultItem} />} label={t("VoidTheMandatoryItems")} />

                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="viewExpressReports" checked={dataObject.viewExpressReports} />} label={t("ViewExpressReports")} />

                    </Grid2>



                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="voidMinimumCharge" checked={dataObject.voidMinimumCharge} />} label={t("VoidMinimumCharge")} />

                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="viewReports" checked={dataObject.viewReports} />} label={t("ViewReports")} />

                    </Grid2>

                    <Grid2 xs={6}>
                        <FormControlLabel disabled={dataObject.accountType === "Admin" || addEditUserPermissionsSettingReduser.loading === 'pending'} control={<Switch onChange={handleChangeSwitchs} name="openAndEditDeviceSetting" checked={dataObject.openAndEditDeviceSetting} />} label={t("WaitrAndKitchenDeviceSettings")} />

                    </Grid2>


                </Grid2>


            </FormGroup>



            <Box sx={{ display: setsaveUserPermissionsFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text'  onClick={handelSaveButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>


        </Box>
    )
}

export default AddEditUserPermissions