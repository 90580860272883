import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom'
import { getAccountActions } from '../../../redux/slice/Account/AccountSlice';
import { getFullReportByDate } from '../../../redux/slice/Reports/fullReportsSlices';
import { getInvoicesReports } from '../../../redux/slice/Reports/invoicesReportsSlices';
import { getItemsSoldReports } from '../../../redux/slice/Reports/itemsSoldSlice';
import { getStoreLogsReports } from '../../../redux/slice/Reports/storeLogsReportsSlice';
import APIErorr from '../../Components/APIErorr';
import CardErorr from '../../Components/CardErorr';
import Progress from '../../Components/Progress';
import { defultReprtsPageView, getFindIndexReportPageView } from '../../hepler';
import FilterReportsComp from './Components/FilterReportsComp';
import InvoicesReports from './Pages/InvoicesReports';
import ItemsSoldReports from './Pages/ItemsSoldReports';
import MainReport from './Pages/MainReport';
import StoreLogsReports from './Pages/StoreLogsReports';
import VoidedTransctionsReports from './Pages/VoidedTransctionsReports';
import { t } from 'i18next';

function Report() {
  const dispatch = useDispatch();

  let { viewPage } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();






  const shopId = searchParams.get(("ShopId")) || null
  const from = searchParams.get(("From")) || null
  const to = searchParams.get(("To")) || null
  const priceType = searchParams.get(("PriceType")) || null
  const shiftName = searchParams.get(("ShiftName")) || null
  const voidService = searchParams.get(("VoidService")) || null
  const paymentMethod = searchParams.get('PaymentMethod') || null

  const pageNumber = searchParams.get('PageNumber') || 0
  const pageSize = searchParams.get('PageSize') || 5
  const sortBy = searchParams.get('SortBy') !== null ? searchParams.get('SortBy') : viewPage === defultReprtsPageView[3] ? 'invoiceNumber' : 'businessDayName'
  const sortOrder = searchParams.get('SortOrder') || 'desc'




  const Account = useSelector((state) => state.accountReduser);




  useEffect(() => {
    if (shopId === null && Account.httpCode === 200) {
      searchParams.set("ShopId", Account.response.shopsEntities[0].id)
      setSearchParams(searchParams);
    }

    if (from === null || to === null) {

      searchParams.set("From", new Date().toISOString().split("T")[0])
      setSearchParams(searchParams);


      searchParams.set("To", new Date().toISOString().split("T")[0])
      setSearchParams(searchParams);
    }



  }, [searchParams, Account, from, to, setSearchParams, shopId])





  let ShopInfo = {};



  useEffect(() => {

    if (Account.loading === "idle") {

      dispatch(getAccountActions())


    }

  }, [Account, dispatch])




  useEffect(() => {



    if (shopId !== null) {

      switch (viewPage) {
        case defultReprtsPageView[0]:

          dispatch(getFullReportByDate({ shopId, from, to, priceType, shiftName, voidService, paymentMethod }))
          break;

        case defultReprtsPageView[1]:

          dispatch(getFullReportByDate({ shopId, from, to, priceType, shiftName, voidService, paymentMethod }))
          break;



        case defultReprtsPageView[2]:
          dispatch(getItemsSoldReports({ shopId, from, to, priceType, shiftName, paymentMethod }))
          break;


        case defultReprtsPageView[3]:
          dispatch(getInvoicesReports({ shopId, from, to, priceType, shiftName, voidService, paymentMethod, pageNumber, pageSize, sortBy, sortOrder }))
          break;
        case defultReprtsPageView[4]:
          dispatch(getStoreLogsReports({ shopId, from, to, pageNumber, pageSize, sortBy, sortOrder }))
          break;
        default:
          break;
      }


    }
  }, [shopId, from, to, priceType, shiftName, voidService, viewPage, pageNumber, pageSize, sortBy, sortOrder, paymentMethod, dispatch])










  if (Account.httpCode === 200) {

    Account.response.shopsEntities.forEach(data => {


      if (data.id === parseInt(shopId)) {
        ShopInfo = data;
      }


    });


  }





  if (Account.loading === "failed") {
    return (<APIErorr reduser={Account} />)
  }

  if (Account.loading === "pending" || Account.loading === "idle" || shopId === null) {

    return (<Progress />)
  }


  if (viewPage !== "")




    return (

      <Box sx={{ backgroundColor: "rgb(255, 255, 255 , .6)", paddingTop: 5 }}>

        <Box sx={{ paddingLeft: 3, paddingRight: 3 }}>
          <FilterReportsComp />

        </Box>



        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
          {viewPage === defultReprtsPageView[0] && <MainReport ShopInfo={ShopInfo} />}
          {viewPage === defultReprtsPageView[1] && <VoidedTransctionsReports />}
          {viewPage === defultReprtsPageView[2] && <ItemsSoldReports />}
          {viewPage === defultReprtsPageView[3] && <InvoicesReports ShopInfo={ShopInfo} />}
          {viewPage === defultReprtsPageView[4] && <StoreLogsReports ShopInfo={ShopInfo} />}
          {getFindIndexReportPageView(viewPage) === -1 && <CardErorr Msg={t("PageNotfound")}></CardErorr>}

        </Box>




      </Box>





    )
}

export default Report