import {  Button, Divider, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';


import APIErorr from '../../../Components/APIErorr';


import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditDescribeReasonMessagesSettings, reset as restAddEditDescribeReasonMessagesSettings } from '../../../../redux/slice/Settings/DescribeReasonMessages/addEditDescribeReasonMessagesSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';








function AddEditDescribeReasonMessages(props) {

    const [setSaveDescribeReasonMessagesFun, handleClose] = props.control || [null, null];
    const describeReasonMessagesData = props.DescribeReasonMessages
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);

    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        msg: false
    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditDescribeReasonMessagesSettingReduser = useSelector((state) => state.addEditDescribeReasonMessagesSettingReduser);



   
    const [dataObject, setdataObject] = useState(describeReasonMessagesData === null ?

        {
            "id": 0,
            "msg": "",
          

        } : describeReasonMessagesData


    )






    const handelSaveDescribeReasonMessages = () => async (dispatch) => {

        if (dataObject.msg === '') {
            setShowAlertMsg({ ...showAlertMsg, msg: true })


            return;
        }


        setShowsuccessMsg(false)
       await dispatch(addEditDescribeReasonMessagesSettings({ shopId, dataObject }))


    }









    useEffect(() => {



        if (addEditDescribeReasonMessagesSettingReduser.loading === "succeeded") {
            if (dataObject.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                setdataObject({

                    "id": 0,
                    "msg": "",
                  
                })







            }

             dispatch(restAddEditDescribeReasonMessagesSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditDescribeReasonMessagesSettingReduser])















    useEffect(() => {
        ///For desktop window size only

        if (setSaveDescribeReasonMessagesFun != null) {

            setSaveDescribeReasonMessagesFun(() => () => dispatch(handelSaveDescribeReasonMessages()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataObject, setSaveDescribeReasonMessagesFun])





    ///For Mobile window size only
    const handelSaveButton = () => {
        dispatch(handelSaveDescribeReasonMessages());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/DescribeReasonMessages",
            search: "?" + searchParams.toString()


        })

    }




    const handleChange = (event) => {

        let value = event.target.value;







        if (event.target.name === "msg" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, msg: false })


        }




        setdataObject({ ...dataObject, [event.target.name]: value });


    };




    return (
        <Box component='form' sx={{ margin: setSaveDescribeReasonMessagesFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}

            {addEditDescribeReasonMessagesSettingReduser.loading === 'pending' && <Progress />}
            {addEditDescribeReasonMessagesSettingReduser.loading === 'failed' && <APIErorr reduser={addEditDescribeReasonMessagesSettingReduser} />}




            <TextField  multiline maxRows={2} disabled={addEditDescribeReasonMessagesSettingReduser.loading === 'pending'} error={showAlertMsg.msg} helperText={dataObject.msg === '' && t("MSG17")} onChange={handleChange} value={dataObject.msg} name="msg" inputProps={{  required: true }} id="msg" sx={{ margin: 1 }} fullWidth label={t("DescribeReasonMessagesMSG")} variant="standard" type="text" />

         

       

         
            <Box sx={{ display: setSaveDescribeReasonMessagesFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' disabled={addEditDescribeReasonMessagesSettingReduser.loading === 'pending'} onClick={handelSaveButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>


        </Box>
    )
}

export default AddEditDescribeReasonMessages