import { Box } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import CardErorr from './CardErorr';
import PropTypes from 'prop-types';

export default function APIErorr(porps) {


    const reduser = porps.reduser;


    let isScrroling = porps.isScrroling; 
     if (isScrroling === null) {

        isScrroling = true ; 
     } 


    const myRef = useRef(null)


    useEffect(() => {

        if (isScrroling){

            myRef.current.scrollIntoView()   

        }
      }, [isScrroling])





    return (
        <Box  ref={myRef}>

            {reduser.errorData.errors !== undefined && reduser.errorData.errors != null ?
                reduser.errorData.errors.map((data , i) => (



                    <CardErorr  key={i} Msg={data.toUpperCase()} />





                )) : <CardErorr Msg={reduser.errorMsg.toUpperCase() + " (CODE : " + reduser.httpCode + ")"} />




            }

            {}


        </Box>
    )
}


APIErorr.propTypes = {
    isScrroling : PropTypes.bool ,
    reduser : PropTypes.func.isRequired

}