import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;
//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const getNoteAndExtraSetting = createAsyncThunk(
    "getAllNoteAndExtraSetting/getAllNoteAndExtraSetting",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
    

        let PageNumber = dispatch.pageNumber 
        let PageSize = dispatch.pageSize ; 
        let SortBy = dispatch.sortBy ; 
        let SortOrder = dispatch.sortOrder ; 



        if (PageNumber === null){

          PageNumber = 0 ;
        }

        if (PageSize === null){

          PageSize = 5 
        }

        if (SortBy === null){

          SortBy = "id"
        }


        if (SortOrder === null ){

          SortOrder = "desc"
        }





        const { data } = await axios.get(url + "/Apps/API/CMS/Settings/NoteAndExtra/getAll?ShopId="+shopId+"&DeviceId=0&QRKey=0&PageNumber="+PageNumber +"&PageSize="+PageSize+"&SortBy="+SortBy+"&SortOrder="+SortOrder ,
          {
            withCredentials: true
          })
        return data;
      } catch (err) {

  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




 



  export const GetAllNoteAndExtraSettingSlices = createSlice({

    name: "getAllNoteAndExtraSetting",
    initialState,
  
    reducers: {
      reset: () => initialState, 
      deleteNoteAndExtra : (state ,actions) =>{
  

        const id = actions.payload


        state.response.content = state.response.content.filter(function( obj ) {
          return obj.id !== id;
        });

      }
    },
  
  
  
    extraReducers: {
      [getNoteAndExtraSetting.pending]: (state) => {
        state.loading = 'pending';
      },
      [getNoteAndExtraSetting.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [getNoteAndExtraSetting.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })
  
  export const { reset ,deleteNoteAndExtra } = GetAllNoteAndExtraSettingSlices.actions
  
  export default GetAllNoteAndExtraSettingSlices.reducer;