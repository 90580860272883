import { Avatar, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SquareIcon from '@mui/icons-material/Square';
import { useState } from 'react';
import ImageDialog from '../Components/ImageDialog';
import ColorDialog from '../Components/ColorDialog';
import { getDefultItemImage } from '../../../../assets/DefultItemImage';
import { getHexColor } from '../../../hepler';

import APIErorr from '../../../Components/APIErorr';


import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditNoteAndExtraSettings, reset as restAddEditNoteAndExtraSettings } from '../../../../redux/slice/Settings/NoteAndExtra/addEditNoteAndExtraSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';






const getAvatar = (color, name, image, isNoteAndExtra) => {



    if (color === 0) {

        if (isNoteAndExtra) {

            return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100 }} />

        }

        return <img alt={name} src={`data:image/jpeg;base64,${image}`} style={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100 }} />


    }


    if (color !== null) {



        return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100, bgcolor: getHexColor(color), marginLeft: isNoteAndExtra && 1, marginRight: isNoteAndExtra && 1 }} > </Avatar>


    }

    return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}



function AddEditNoteAndExtra(props) {

    const [setsaveNoteAndExtraFun, handleClose] = props.control || [null, null];
    const NoteAndExtraData = props.NoteAndExtra
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);

    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        textName: false
    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditNoteAndExtraSettingReduser = useSelector((state) => state.addEditNoteAndExtraSettingReduser);



    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [openColorDialog, setOpenColorDialog] = useState(false)

    const [categorObject, setCategorObject] = useState(NoteAndExtraData === null ?

        {
            "id": 0,
            "name": "",
            "tybe": null,
            "image": "",
            "color": -11419154,

        } : NoteAndExtraData


    )






    const handelSaveNoteAndExtra = () => async (dispatch) => {

        if (categorObject.name === '') {
            setShowAlertMsg({ ...showAlertMsg, textName: true })


            return;
        }


        setShowsuccessMsg(false)
       await dispatch(addEditNoteAndExtraSettings({ shopId, categorObject }))


    }









    useEffect(() => {



        if (addEditNoteAndExtraSettingReduser.loading === "succeeded") {
            if (categorObject.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                setCategorObject({

                    "id": 0,
                    "name": "",
                    "tybe": null,
                    "image": "",
                    "color": -11419154,

                })







            }

             dispatch(restAddEditNoteAndExtraSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditNoteAndExtraSettingReduser])















    useEffect(() => {
        ///For desktop window size only

        if (setsaveNoteAndExtraFun != null) {

            setsaveNoteAndExtraFun(() => () => dispatch(handelSaveNoteAndExtra()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorObject, setsaveNoteAndExtraFun])





    ///For Mobile window size only
    const handelSaveButton = () => {
        dispatch(handelSaveNoteAndExtra());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/NoteAndExtra",
            search: "?" + searchParams.toString()


        })

    }





    const onChangePicture = e => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setCategorObject({ ...categorObject, color: 0, imageTemp: reader.result });

                setOpenImageDialog(true);


            });



            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleChange = (event) => {

        let value = event.target.value;






        if (event.target.name === "buttons-group-imageType") {


            if (value === 'color') {

                setCategorObject({ ...categorObject, color: categorObject.color === 0 ? -11419154 : categorObject.color });



            } else {


                setCategorObject({ ...categorObject, color: 0, image: categorObject.image === '' || categorObject.image === null ? getDefultItemImage : categorObject.image });


            }


            return
        }

        if (event.target.name === "name" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, textName: false })


        }




        setCategorObject({ ...categorObject, [event.target.name]: value });


    };




    return (
        <Box component='form' sx={{ margin: setsaveNoteAndExtraFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}

            {addEditNoteAndExtraSettingReduser.loading === 'pending' && <Progress />}
            {addEditNoteAndExtraSettingReduser.loading === 'failed' && <APIErorr reduser={addEditNoteAndExtraSettingReduser} />}




            <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} error={showAlertMsg.textName} helperText={categorObject.name === '' && t("MSG8")} onChange={handleChange} value={categorObject.name} name="name" inputProps={{ size: 15, maxLength: "15", required: true }} id="name" sx={{ margin: 1 }} fullWidth label={t("NoteAndExtraName")} variant="standard" type="text" />
            <Divider sx={{ margin: 2 }} />

         

            <FormControl disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">{t("ImageType")}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="buttons-group-imageType"
                    value={categorObject.color === 0 ? "image" : "color"}
                    onChange={handleChange}
                >
                    <FormControlLabel value="color" control={<Radio />} label={t("Color")} />
                    <FormControlLabel value="image" control={<Radio />} label={t("Image")} />

                </RadioGroup>
            </FormControl>

            <Box sx={{ width: 150, hidden: 100 }}>




                {getAvatar(categorObject.color, categorObject.name, categorObject.image, false)}
                <ImageDialog control={[openImageDialog, setOpenImageDialog]} data={[categorObject, setCategorObject]} />
                <ColorDialog control={[openColorDialog, setOpenColorDialog]} data={[categorObject, setCategorObject]} />

                <Button
                    disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'}
                    component="label"
                    onClick={() => setOpenColorDialog(true)}
                    variant="contained" sx={{ padding: "4px", height: "100%", display: categorObject.color === 0 && 'none' }} >{t("SelectColor")}
                </Button>

                <Button
                    disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'}
                    component="label"
                    variant="contained" sx={{ padding: "4px", height: "100%", display: categorObject.color !== 0 && 'none' }} >
                    {t("UploadImage")}
                    <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        hidden
                        onChange={onChangePicture}
                        name="contentPath"

                    />

                </Button>




            </Box>
            <Box sx={{ display: setsaveNoteAndExtraFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' onClick={handelSaveButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>


        </Box>
    )
}

export default AddEditNoteAndExtra