import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React from 'react'
import { useSelector } from 'react-redux'
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import ItemViewListComp from '../Components/ItemViewListComp';

function ItemsSoldReports() {
    let Reports = {};



    const ItemsSoldReportsReduser = useSelector((state) => state.itemsSoldReportsReduser)

    if (ItemsSoldReportsReduser.httpCode === 200) {

        Reports = ItemsSoldReportsReduser.response;

    }


    if (ItemsSoldReportsReduser.loading === "failed") {
        return (<APIErorr reduser={ItemsSoldReportsReduser} />)
    }


    if (ItemsSoldReportsReduser.loading === "pending" || ItemsSoldReportsReduser.loading === "idle") {

        return (<Progress />)
    }


    return (

        <Box>

            

            <Typography variant='h4' color="info.main" sx={{ textAlign: "center" }}>{t("SoldItemReports").toLocaleUpperCase()}</Typography>


            <ItemViewListComp data={Reports.reportItemQtyList} />
            <Divider sx={{ margin: 2 }} />
            <Typography variant='h4' color="info.main" sx={{ textAlign: "center" }}>{t("AddedItemsSoldReport").toLocaleUpperCase()}</Typography>

            <ItemViewListComp data={Reports.reportNoteAndExtraQtyList} />
            <Divider sx={{ margin: 2 }} />



        </Box>


    )
}

export default ItemsSoldReports