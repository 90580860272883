import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { getHexColor } from '../../../hepler';

const columns = [
    { id: 'id', label: 'Id', minWidth: 50, align: 'center' },

    {
        id: 'Image', label: 'Image', minWidth: 100, isImage: true, align: 'center', format: (value,dir) => {


            if (value.color === 0) {

                return <img alt='ItemImage' src={`data:image/jpeg;base64,${value.image}`} style={{ width: "100px", height: "80px", clear: "both%", margin: "0 auto" }} />

            }


            if (value.color !== null) {



                return <div style={{ backgroundColor: getHexColor(value.color), width: "100px", height: "80px", clear: "both%", margin: "0 auto" ,  float: dir === "rtl" ? "right" : "left"  }} > </div>

            }


            return <div style={{ backgroundColor: "rgb(231, 67, 182)", width: "100px", height: "80px", clear: "both%", margin: "0 auto" , float:  dir === "rtl" ? "right" : "left",   }} > </div>





        }
    },

    { id: 'Name', label: 'ItemName', minWidth: 100, align: 'center' },
    {
        id: 'Quantity',
        label: 'QuantitySold',
        minWidth: 50,
        align: 'center',
    },

];




function descendingComparator(a, b, orderBy) {



    if (b[orderBy] < a[orderBy]) {

        return -1;
    }
    if (b[orderBy] > a[orderBy]) {

        return 1;
    }

    return 0;
}


function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {





    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);

        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function ItemViewListComp(pros) {

    const { i18n } = useTranslation();

    const data = pros.data;


    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('Quantity');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    
    const createSortHandler = (property) => (event) => {


        handleRequestSort(event, property);
    };

    function createData(id, Name, Image, Quantity) {
        return { id, Name, Image, Quantity };
    }
    let rows = [];




    if (data !== undefined) {
        data.forEach((data) => {


            rows.push(createData(data.itemId, data.itemName, { image: data.image, color: data.color }, data.qty))




        })


    }



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>



                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    style={{ minWidth: column.minWidth }}
                                     variant='head'                                    sortDirection={orderBy === column.id ? order : false}

                                >

                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id)}
                                    >

                                        {orderBy === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}


                                        {column.label != null ? t(column.label).toUpperCase() : 'column.label'}
                                    </TableSortLabel>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                    {stableSort(rows, getComparator(order, orderBy))
                  
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row,index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={index} >
                                                    {column.format && column.isImage === true
                                                        ? column.format(value,i18n.dir())
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100 , 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );



}

export default ItemViewListComp