import { Button, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import { t } from 'i18next';
import { getTimeZonesList } from '../../../hepler'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import APIErorr from '../../../Components/APIErorr'
import Progress from '../../../Components/Progress'
import AlertsDialog from '../../../Components/AlertsDialog'
import SuccessMsg from '../../../Components/SuccessMsg'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import { getAllStoreSetting } from '../../../../redux/slice/Settings/Store/getAllStoreSettingSlices'
import { saveStoreSettingAndRestAccount } from '../../../../redux/slice/Settings/Store/saveStoreSettingSlices';
import CardErorr from '../../../Components/CardErorr';

function Store() {



    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));






    const [searchParams] = useSearchParams();

    const shopId = searchParams.get("ShopId")
    const dispatch = useDispatch();
    const [dataObject, setDataObject] = useState(null)
    const [showAlertMsg, setShowAlertMsg] = useState({

        storeName: false,
        monitorDurationAlarmStart: false,
        currency: false,
        minimumChargesPrice: false,


    })

    const getAllStoreSettingReduser = useSelector((sate) => sate.getAllStoreSettingReduser)
    const saveStoreSettingReduser = useSelector((state) => state.saveStoreSettingReduser)




    const [dalilogAlretObject, setDalilogAlretObject] = useState(
        {
            open: false,
            msg: t("MSG20"),
            title: t("MSG19"),
            showButtontOne: true,
            buttontOneTitle: t("Ok"),
            buttonOneFun: () => {
                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
            },
            showButtontTow: false,
            buttontTowTitle: t("No"),
            buttonTowFun: () => {
                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
            },
        }


    )






    useEffect(() => {

        setDataObject(null)

        dispatch(getAllStoreSetting({ shopId }))

    }, [shopId, dispatch])

    useEffect(() => {
        if (getAllStoreSettingReduser.loading === "succeeded") {



            setDataObject(getAllStoreSettingReduser.response)

            if (getAllStoreSettingReduser.response.canBeChange === false) {


                setDalilogAlretObject({ ...dalilogAlretObject, open: true });
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllStoreSettingReduser])




  











    const handelSave = () => {
        let dataSaved = dataObject






        if (dataObject.shopsEntity.settingsById.priceButton1 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton1: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }

        if (dataObject.shopsEntity.settingsById.priceButton2 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton2: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }

        if (dataObject.shopsEntity.settingsById.priceButton3 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton3: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }

        if (dataObject.shopsEntity.settingsById.priceButton4 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton4: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }

        if (dataObject.shopsEntity.settingsById.priceButton5 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton5: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }

        if (dataObject.shopsEntity.settingsById.priceButton6 === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, priceButton6: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }


        if (dataObject.shopsEntity.settingsById.minimumChargesPrice === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, minimumChargesPrice: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }


        if (dataObject.shopsEntity.settingsById.monitorDurationAlarmStart === "") {
            const settingsById = { ...dataSaved.shopsEntity.settingsById, monitorDurationAlarmStart: 0 }
            const shopsEntity = { ...dataSaved.shopsEntity, settingsById }
            dataSaved = { ...dataSaved, shopsEntity }
        }


        setDataObject(dataSaved)

        dispatch(saveStoreSettingAndRestAccount({ shopId, dataSaved }))
    }







    const handleChange = (event) => {

        let value = event.target.value;

        if (event.target.name === "name") {

            if (value === '') {
                setShowAlertMsg({ ...AlertsDialog, storeName: true });
            } else {
                setShowAlertMsg({ ...AlertsDialog, storeName: false });
            }
        }



        if (event.target.name === "currency") {

            if (value === '') {
                setShowAlertMsg({ ...AlertsDialog, currency: true });
            } else {
                setShowAlertMsg({ ...AlertsDialog, currency: false });
            }

            if (/[0-9]/.test(value)) {
                return
            }
        }









        const shopsEntity = { ...dataObject.shopsEntity, [event.target.name]: value }

        setDataObject({ ...dataObject, shopsEntity });


    };





    const handleChangeSettingsById = (event) => {

        let value = event.target.value;
        const name = event.target.name;

        if (name === "addedServicePrice" || name === "addedTaxPrice") {




            if (value === "" || Number(value) >= 1) {

                value = 0.99
            }






            value = parseFloat(value)


        }


        if (name === "monitorDurationAlarmStart") {


            if (value !== "") {

                value = Number(value)
                setShowAlertMsg({ ...AlertsDialog, monitorDurationAlarmStart: false });

            } else {

                setShowAlertMsg({ ...AlertsDialog, monitorDurationAlarmStart: true });


            }



        }



        if (name === "minimumChargesPrice") {


            if (value !== "") {

                value = Number(value)
                setShowAlertMsg({ ...AlertsDialog, minimumChargesPrice: false });

            } else {

                setShowAlertMsg({ ...AlertsDialog, minimumChargesPrice: true });


            }



        }





        if (name === "minimumCharges") {


            value = Number(value)

        }

        if (name.startsWith("priceButton")) {

            if (value !== "") {

                value = Number(value)

            }


        }





        const settingsById = { ...dataObject.shopsEntity.settingsById, [event.target.name]: value }
        const shopsEntity = { ...dataObject.shopsEntity, settingsById }
        setDataObject({ ...dataObject, shopsEntity });



    };



    const handleChangeSettingsByIdChecked = (event) => {
        let value = event.target.checked;
        const settingsById = { ...dataObject.shopsEntity.settingsById, [event.target.name]: value }
        const shopsEntity = { ...dataObject.shopsEntity, settingsById }
        setDataObject({ ...dataObject, shopsEntity });



    };





    const handleChangeCuntryCode = (value, country, e, formattedValue) => {

        const settingsById = { ...dataObject.shopsEntity.settingsById, countryCode: value }


        const shopsEntity = { ...dataObject.shopsEntity, settingsById }

        setDataObject({ ...dataObject, shopsEntity });



    };





    if (getAllStoreSettingReduser.loading === "failed") {
        return (<APIErorr reduser={getAllStoreSettingReduser} />)
    }
    if (getAllStoreSettingReduser.loading === "pending" || getAllStoreSettingReduser.loading === "idle" || dataObject === null) {


        return (<Progress />)
    }


    return (
        <Card sx={{ m: 1 }}>
            <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", gap: 2, m: 1 }}>
                {saveStoreSettingReduser.loading === "succeeded" && <SuccessMsg msg={t("MSG3")} />}
                {saveStoreSettingReduser.loading === "pending" && <Progress />}
                {saveStoreSettingReduser.loading === 'failed' && saveStoreSettingReduser.httpCode !== 409 && !saveStoreSettingReduser.viewErrorOnForm ? <APIErorr reduser={saveStoreSettingReduser} /> : ''}
                {saveStoreSettingReduser.loading === 'failed' && saveStoreSettingReduser.httpCode === 409 && !saveStoreSettingReduser.viewErrorOnForm ? <CardErorr Msg={t("MSG21")} /> : ''}




                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                    <TextField variant="outlined" onChange={handleChange} disabled={saveStoreSettingReduser.loading === 'pending'} error={showAlertMsg.storeName} helperText={showAlertMsg.storeName && t("MSG18")} value={dataObject.shopsEntity.name} name="name" inputProps={{ size: 15, maxLength: "15", required: true }} id="name" sx={{ width: { md: "49%", xs: "99%" } }} label={t("StoreName")} type="text" />
                    <TextField variant="outlined" onChange={handleChange} disabled={saveStoreSettingReduser.loading === 'pending'} error={showAlertMsg.currency} helperText={showAlertMsg.currency && t("MSG18")} value={dataObject.shopsEntity.currency} name="currency" inputProps={{ size: 4, maxLength: "4", required: true }} id="currency" sx={{ width: { md: "49%", xs: "99%" } }} label={t("Currency")} type="text" />

                    <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        inputProps={{ disabled: true }}
                        containerStyle={{ width: matches ? "49%" : "99%" }}
                        inputStyle={{ width: "100%" }}
                        value={dataObject.shopsEntity.settingsById.countryCode}
                        specialLabel={t("CountryCode")}
                        onChange={handleChangeCuntryCode}
                    />


                    <FormControl sx={{ width: { lg: "49%", xs: "99%" } }} disabled={saveStoreSettingReduser.loading === "pending"}  >
                        <InputLabel id="demo-simple-select-label">{t("TimeZone")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("TimeZone")}
                            value={dataObject.shopsEntity.timeZoneId}
                            onChange={handleChange}
                            name="timeZoneId"
                            disabled={!dataObject.canBeChange}

                        >
                            {getTimeZonesList.map((data, index) => (
                                <MenuItem key={index} value={data}>{data}</MenuItem>



                            ))}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: "99%" }} disabled={saveStoreSettingReduser.loading === "pending"}  >
                        <InputLabel id="demo-simple-select-label">{t("TablesCapacity")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("TablesCapacity")}
                            name="totalTablesAvailable"
                            value={dataObject.shopsEntity.settingsById.totalTablesAvailable}
                            onChange={handleChangeSettingsById}

                        >

                            {[...Array(250)].map((x, i) =>
                                <MenuItem sx={{ display: i === 0 && 'none' }} key={i} value={i}>{i}</MenuItem>
                            )}




                        </Select>
                    </FormControl>
                </Box>
                <Divider sx={{ mt: 2, mr: 2, ml: 2 }} />


                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>

                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", gap: 1, width: { md: "49%", xs: "99%" } }} >
                        <FormControlLabel control={<Switch checked={dataObject.shopsEntity.settingsById.addedServices} onChange={handleChangeSettingsByIdChecked} name="addedServices" />} label={t("AddedServices")} />

                        <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending' || !dataObject.shopsEntity.settingsById.addedServices} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.addedServicePrice} name="addedServicePrice" inputProps={{ step: "0.01", required: true }} id="userName" label={t("AddedServicePercent")} type="number" />

                    </Box>



                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", gap: 1, width: { md: "49%", xs: "99%" } }} >
                        <FormControlLabel control={<Switch checked={dataObject.shopsEntity.settingsById.addedTax} onChange={handleChangeSettingsByIdChecked} name="addedTax" />} label={t("AddedTax")} />

                        <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending' || !dataObject.shopsEntity.settingsById.addedTax} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.addedTaxPrice} name="addedTaxPrice" inputProps={{ step: "0.01", required: true }} id="addedTaxPrice" label={t("AddedTaxPercent")} type="number" />

                    </Box>



                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", gap: { md: 3, xs: 1 }, width: { md: "49%", xs: "99%" } }} >
                        <FormControlLabel control={<Switch checked={dataObject.shopsEntity.settingsById.pendingServices} onChange={handleChangeSettingsByIdChecked} name="pendingServices" />} label={t("PendingQueueServices")} />

                        <TextField variant="outlined" error={showAlertMsg.monitorDurationAlarmStart} helperText={showAlertMsg.monitorDurationAlarmStart && t("MSG18")} disabled={saveStoreSettingReduser.loading === 'pending' || !dataObject.shopsEntity.settingsById.pendingServices} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.monitorDurationAlarmStart} name="monitorDurationAlarmStart" inputProps={{ step: "1", required: true }} id="addedTaxPrice" label={t("DurationAlarmStartOnMonitor")} type="number" />


                    </Box>




                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", gap: 1, width: { md: "49%", xs: "99%" } }} >

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">{t("MinimumCharge")}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="minimumCharges"
                                onChange={handleChangeSettingsById}



                                value={dataObject.shopsEntity.settingsById.minimumCharges}
                            >
                                <FormControlLabel value="0" control={<Radio />} label={t("InActive")} />
                                <FormControlLabel value="1" control={<Radio />} label={t("Active")} />
                                <FormControlLabel value="2" control={<Radio />} label={t("ByTheNumberOfPeople")} />

                            </RadioGroup>

                            <TextField variant="outlined" error={showAlertMsg.minimumChargesPrice} helperText={showAlertMsg.minimumChargesPrice && t("MSG18")} disabled={saveStoreSettingReduser.loading === 'pending' || dataObject.shopsEntity.settingsById.minimumCharges === 0} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.minimumChargesPrice} name="minimumChargesPrice" inputProps={{ step: "0.01", required: true }} id="minimumChargesPrice" label={t("MinimumChargeValue")} type="number" />
                        </FormControl>

                    </Box>
                    <Divider sx={{ margin: 2, width: "99%" }} />

                    <FormLabel sx={{ width: "99%" }} id="demo-row-radio-buttons-group-label">{t("CashButtonsSettings")}</FormLabel>



                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton1} name="priceButton1" inputProps={{ step: "0.01", required: true }} id="priceButton1" label={t("Button1")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />
                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton2} name="priceButton2" inputProps={{ step: "0.01", required: true }} id="priceButton2" label={t("Button2")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />
                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton3} name="priceButton3" inputProps={{ step: "0.01", required: true }} id="priceButton3" label={t("Button3")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />
                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton4} name="priceButton4" inputProps={{ step: "0.01", required: true }} id="priceButton4" label={t("Button4")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />
                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton5} name="priceButton5" inputProps={{ step: "0.01", required: true }} id="priceButton5" label={t("Button5")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />
                    <TextField variant="outlined" disabled={saveStoreSettingReduser.loading === 'pending'} onChange={handleChangeSettingsById} value={dataObject.shopsEntity.settingsById.priceButton6} name="priceButton6" inputProps={{ step: "0.01", required: true }} id="priceButton6" label={t("Button6")} sx={{ width: { md: "49%", xs: "46%" } }} type="number" />

                </Box >









            </CardContent>



            <Box >

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' disabled={saveStoreSettingReduser.loading === "pending" || showAlertMsg.storeName || showAlertMsg.currency} onClick={handelSave}>{t("Save")}</Button>




                </Box>


            </Box>


            <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

        </Card>

    )
}

export default Store