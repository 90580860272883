import React from 'react'
import { useSelector } from 'react-redux';
import { set } from 'lodash';
import { getServiceName, getShiftName } from '../../../hepler';
import { t } from 'i18next';
import Progress from '../../../Components/Progress';
import APIErorr from '../../../Components/APIErorr';
import { Box } from '@mui/system';
import { CardContent, Divider, Typography } from '@mui/material';
import CashComp from '../Components/CashComp';
import PieChartComp from '../Components/PieChartComp';
import TransactionVoidedComp from '../Components/TransactionVoidedComp';
import LineChartComp from '../Components/LineChartComp';

export default function MainReport(props) {

    let Reports = {};

    const ShopInfo = props.ShopInfo ;

       const fullReportByDateReduser = useSelector((state) => state.fullReportByDateReduser)
    let ReportVoidServices = { Refund: 0, Discount: 0, VoidTaxs: 0, VoidService: 0, MinCharge: 0, DefaultItem: 0 }
    let ReportByWorkShift = { labelsArray: [], PriceArray: [], ShiftNumberArray: [], Name: t("RevenueDistributionbyWorkShift") };
    let LinChartReport = { labelsArray: [], PriceArray: [], Name: t("DailySalesTrends") }
    let ReportByServiceType = { labelsArray: [], PriceArray: [], ServiceNumberArray: [], Name: t("RevenueDistributionbyService") };
    let NetTotal = { Total: 0, Orders: 0 };
    let NetTotalCard = { Total: 0, Orders: 0 };
    let NetTotalCash = { Total: 0, Orders: 0 };



    if (fullReportByDateReduser.httpCode === 200) {


        Reports = fullReportByDateReduser.response;

        
        
        if (fullReportByDateReduser.loading === "succeeded") {





            let ShiftNameArray = [];
            let ShiftPriceArray = [];
            let ShiftNumberArray = [];


            let DayNameArray = [];
            let DayNamePriceArray = [];




            Reports.reportByDateList.forEach((data) => {

                set(NetTotal, "Total", NetTotal.Total + data.netPriceCard + data.netPriceCash)
                set(NetTotal, "Orders", NetTotal.Orders + data.countNetPriceCash + data.countNetPriceCard)
                set(NetTotalCard, "Total", NetTotalCard.Total + data.netPriceCard)
                set(NetTotalCard, "Orders", NetTotalCard.Orders + data.countNetPriceCard)
                set(NetTotalCash, "Total", NetTotalCash.Total + data.netPriceCash)
                set(NetTotalCash, "Orders", NetTotalCash.Orders + data.countNetPriceCash)



                let addNewShift = true;
                ShiftNameArray.forEach((Shiftdata, i) => {





                    const fullshiftname = t("Shift") + " " + getShiftName(data.shiftName);

                    if (Shiftdata === fullshiftname) {
                        ShiftNameArray[i] = fullshiftname;
                        ShiftPriceArray[i] = ShiftPriceArray[i] + data.netPriceCard + data.netPriceCash;
                        addNewShift = false;
                        ShiftNumberArray[i] = data.shiftName
                    }

                })

                if (addNewShift) {

                    const fullshiftname = t("Shift") + " " + getShiftName(data.shiftName);

                    ShiftNameArray.push(fullshiftname);
                    ShiftPriceArray.push(data.netPriceCard + data.netPriceCash);
                    ShiftNumberArray.push(data.shiftName);
                }



                set(ReportVoidServices, 'Refund', ReportVoidServices.Refund + data.refund)
                set(ReportVoidServices, 'Discount', ReportVoidServices.Discount + data.discount)
                set(ReportVoidServices, 'VoidTaxs', ReportVoidServices.VoidTaxs + data.removeTax)
                set(ReportVoidServices, 'VoidService', ReportVoidServices.VoidService + data.removeService)
                set(ReportVoidServices, 'MinCharge', ReportVoidServices.MinCharge + data.removeMinimumCharge)
                set(ReportVoidServices, 'DefaultItem', ReportVoidServices.DefaultItem + data.removeDefaultItems)



                let addNewDayaName = true;


                DayNameArray.forEach((DayName, i) => {

                    const fullDayName = data.dayName.split("T")[0];

                    if (DayName === fullDayName) {
                        DayNameArray[i] = fullDayName;
                        DayNamePriceArray[i] = DayNamePriceArray[i] + data.netPriceCard + data.netPriceCash;
                        addNewDayaName = false;
                    }

                })


                if (addNewDayaName) {



                    const fullDayName = data.dayName.split("T")[0];

                    DayNameArray.push(fullDayName);
                    DayNamePriceArray.push(data.netPriceCard + data.netPriceCash);
                }





            })


            ReportByWorkShift = { labelsArray: ShiftNameArray, PriceArray: ShiftPriceArray, ShiftNumberArray: ShiftNumberArray, Name: t("RevenueDistributionbyWorkShift") }
            LinChartReport = { labelsArray: DayNameArray, PriceArray: DayNamePriceArray, Name: t("DailySalesTrends") }


            let ServiceNameArray = [];
            let ServicePriceArray = [];
            let ServiceNumberArray = [];


            Reports.reportByDateGroupByAdapterTypeList.forEach((data, index) => {

                let addNewServiceType = true;



                ServiceNameArray.forEach((ServiceData, i) => {


                    if (ServiceData === getServiceName(data.priceType)) {

                        ServiceNameArray[i] = getServiceName(data.priceType);
                        ServicePriceArray[i] = ServicePriceArray[i] + data.netPriceCash;
                        ServiceNumberArray[i] = data.priceType;


                        addNewServiceType = false;

                    }


                })

                if (addNewServiceType) {

                    ServiceNameArray.push(getServiceName(data.priceType));
                    ServiceNumberArray.push(data.priceType);
                    ServicePriceArray.push(data.netPriceCash)

                }



            })


            ReportByServiceType = { labelsArray: ServiceNameArray, PriceArray: ServicePriceArray, ServiceNumberArray: ServiceNumberArray, Name: t("RevenueDistributionbyService") }

        }


    }

    if (fullReportByDateReduser.loading === "pending" || fullReportByDateReduser.loading === "idle") {

        return (<Progress />)
    }


    if (fullReportByDateReduser.loading === "failed") {
        return (<APIErorr reduser={fullReportByDateReduser} />)
    }




    return (
        <Box>

            <Divider sx={{ margin: 2 }} />

            <CashComp data={{ NetTotal, NetTotalCash, NetTotalCard }} shopInfo={ShopInfo} />
            <Divider sx={{ margin: 2 }} />


            <Box sx={{ marginTop: 2, maxWidth: "100%" }}>
                <CardContent sx={{ display: "flex", flexWrap: "wrap", marginBottom: 4 }}>
                    <PieChartComp data={ReportByServiceType} shopInfo={ShopInfo} />

                    <Divider sx={{ margin: 2 }} />

                    <PieChartComp data={ReportByWorkShift} shopInfo={ShopInfo} />

                </CardContent>

            </Box>





            <Divider sx={{ margin: 2 }} />
            <Typography variant='h4' color="info.main" sx={{ textAlign: "center", marginBottom: 2, marginTop: 2 }}>{t("Totalvoidedtransactionsbytypevoided").toLocaleUpperCase()}</Typography>

            <TransactionVoidedComp data={ReportVoidServices} />






            
            
            
            
            <Divider sx={{ margin: 2 }} />



            
            <Typography variant='h4' color="info.main" sx={{ textAlign: "center" }}>{t("DailySalesTrends").toLocaleUpperCase()}</Typography>


            <LineChartComp data={LinChartReport} shopInfo={ShopInfo} />


        </Box>
    )
}
