/* eslint-disable array-callback-return */
import { Button, Divider, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarDialog from './CalendarDialog'
import { defultReprtsPageView, formatDate, getPaymentMethodName, getServiceName, getShiftName, getVoidServiceName } from '../../../hepler';
import CachedIcon from '@mui/icons-material/Cached';
import GroupIcon from '@mui/icons-material/Group';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useTranslation } from 'react-i18next';


function FilterReportsComp() {

    let { viewPage } = useParams();
    let navigate = useNavigate();
    const { i18n } = useTranslation();




    const [searchParams, setSearchParams] = useSearchParams();
    const [openMenuAnchor, setOpenMenuAnchor] = useState(null);
    const [openDialogStartCalDialog, setOpenStartCalDialog] = useState(false);
    const [openDialogEndCalDialog, setOpenEndCalDialog] = useState(false);

    let [shopId, setshopId] = useState(searchParams.get("ShopId") || null)
    let [startDateValue, setStartDateValue] = useState(searchParams.get("From") || null)
    let [endDateValue, setEndDateValue] = useState(searchParams.get("To") || null)
    const [shiftName, setShiftName] = useState(searchParams.get("ShiftName") || null);
    const [priceType, setPriceType] = useState(searchParams.get("PriceType") || null);
    const [voidService, setVoidService] = useState(searchParams.get("VoidService") || null);
    const [paymentMethod, setPaymentMethod] = useState(searchParams.get("PaymentMethod") || null);
    const refToggleButtonGroup = useRef(null);

    const ListShftNumberAndPriceType = [0, 1, 2, 3, 4, 5, 6];


    const isOpenMenu = Boolean(openMenuAnchor);

    const Account = useSelector((state) => state.accountReduser);





    useEffect(() => {

        setshopId(searchParams.get('ShopId'))
        setStartDateValue(searchParams.get('From'))
        setEndDateValue(searchParams.get('To'))
        setPriceType(searchParams.get('PriceType'))
        setVoidService(searchParams.get('VoidService'))
        setShiftName(searchParams.get('ShiftName'));
        setPaymentMethod(searchParams.get('PaymentMethod'));



    }, [searchParams])














    const handleClickFiltersMenu = (event) => {

        setOpenMenuAnchor(event.currentTarget)
    };



    const handleClickOpenCalDialog = (isStartDate) => {

        if (isStartDate) {
            setOpenStartCalDialog(true);

        } else {
            setOpenEndCalDialog(true)
        }
    };
    const handleCloseCalDialog = (isStartDate) => {

        if (isStartDate) {

            setOpenStartCalDialog(false);
            setOpenEndCalDialog(true);


        } else {

            setOpenEndCalDialog(false)


        }
    };



    const handeChangeDate = (date, isStartDate) => {


        date = formatDate(date)

        if (isStartDate) {
            setStartDateValue(date)
        } else {
            setEndDateValue(date)

        }

        handleCloseCalDialog(isStartDate)

    }


    const handleCloseFilterMenu = () => {


        setOpenMenuAnchor(null)



    };




    const handelChangeFilterMenu = (e, index) => {


        if (e.target.id === "ShopName") {
            setshopId(index)



        }

        if (e.target.id === "PriceType") {

            if (index === -1) {

                setPriceType(null)
            } else {
                setPriceType(index)
            }

        }



        if (e.target.id === "ShiftName") {

            if (index === -1) {

                setShiftName(null)
            } else {

                setShiftName(index)
            }

        }



        if (e.target.id === "VoidServices") {

            if (index === -1) {

                setVoidService(null)
            } else {

                setVoidService(index)
            }

        }


        if (e.target.id === "PaymentMethod") {

            if (index === -1) {

                setPaymentMethod(null)
            } else {

                setPaymentMethod(index)
            }

        }





        handleCloseFilterMenu();

    }



    const updateSearchParms = () => {

        searchParams.set("ShopId", shopId)
        setSearchParams(searchParams);
        searchParams.set("From", startDateValue)
        setSearchParams(searchParams);
        searchParams.set("To", endDateValue)
        setSearchParams(searchParams);
        priceType !== null ? searchParams.set("PriceType", priceType) : searchParams.delete("PriceType")
        setSearchParams(searchParams);
        shiftName !== null ? searchParams.set("ShiftName", shiftName) : searchParams.delete("ShiftName")
        setSearchParams(searchParams);
        voidService !== null ? searchParams.set("VoidService", voidService) : searchParams.delete("VoidService")
        setSearchParams(searchParams);
        paymentMethod !== null ? searchParams.set("PaymentMethod", paymentMethod) : searchParams.delete("PaymentMethod")
        setSearchParams(searchParams);

        if (viewPage === defultReprtsPageView[3] || viewPage === defultReprtsPageView[4]) {
            restSearchParms(3)
        }

    }



    const restSearchParms = (type, SortBy) => {

        if (type === 1) {
            searchParams.delete("VoidService")
            setSearchParams(searchParams);
            searchParams.delete("PageNumber")
            setSearchParams(searchParams);
            searchParams.delete("PageSize")
            setSearchParams(searchParams);
            searchParams.delete("SortOrder")
            setSearchParams(searchParams);
            searchParams.delete("SortBy")
            setSearchParams(searchParams);

        }

        if (type === 2) {

            searchParams.set("PageNumber", 0)
            setSearchParams(searchParams);
            searchParams.set("PageSize", 5)
            setSearchParams(searchParams);
            searchParams.set("SortOrder", "desc")
            setSearchParams(searchParams);
            searchParams.set("SortBy", SortBy)
            setSearchParams(searchParams);
        }



        if (type === 3) {
            searchParams.set("PageNumber", 0)
            setSearchParams(searchParams);
            searchParams.set("PageSize", 5)
            setSearchParams(searchParams);
        }




    }





    const handleChangeButtonGroup = (event, newAlignment) => {


        const maxScrollLeft = refToggleButtonGroup.current.scrollWidth - refToggleButtonGroup.current.clientWidth;
        const value = maxScrollLeft / 5



        if (newAlignment === defultReprtsPageView[0]) {

            if (i18n.dir() === "rtl") {

                refToggleButtonGroup.current.scrollLeft = 0

            } else {
                refToggleButtonGroup.current.scrollLeft = 0
            }

            restSearchParms(1)
        }


        if (newAlignment === defultReprtsPageView[1]) {

            if (i18n.dir() === "rtl") {

                refToggleButtonGroup.current.scrollLeft =  Number("-"+value)

            } else {
                refToggleButtonGroup.current.scrollLeft = value
            }
            restSearchParms(1)

        }

        if (newAlignment === defultReprtsPageView[2]) {


            if (i18n.dir() === "rtl") {

                refToggleButtonGroup.current.scrollLeft =  Number("-"+value * 2)

            } else {
                refToggleButtonGroup.current.scrollLeft = value * 2
            }

            restSearchParms(1)

        }

        if (newAlignment === defultReprtsPageView[4]) {

            if (i18n.dir() === "rtl") {

                refToggleButtonGroup.current.scrollLeft =  Number("-"+maxScrollLeft)

            } else {
                refToggleButtonGroup.current.scrollLeft = maxScrollLeft
            }
            

            restSearchParms(2, "businessDayName")

        }

        if (newAlignment === defultReprtsPageView[3]) {


            if (i18n.dir() === "rtl") {

                refToggleButtonGroup.current.scrollLeft =  Number("-"+value * 4)


            } else {
                refToggleButtonGroup.current.scrollLeft = value * 4
            }

            restSearchParms(2, "invoiceNumber")

        }
       

        newAlignment != null &&
            navigate({

                pathname: "/Dashboard/Reports/" + newAlignment,
                search: "?" + searchParams.toString()


            })



    };




    const getNameReport = () => {


        if (viewPage === defultReprtsPageView[0]) {


            return t("CashReports")
        }

        if (viewPage === defultReprtsPageView[1]) {


            return t("VoidedTransctionsReports")
        }

        if (viewPage === defultReprtsPageView[2]) {


            return t("ItemsSoldReports")
        }

        if (viewPage === defultReprtsPageView[3]) {

            return t("ViewInvoices")

        }



        if (viewPage === defultReprtsPageView[4]) {


            return t("StoreLogsReports")
        }


        return t("PageNotfound")


    }




    const shopName = Account.response.shopsEntities.map((data => {
        const id = data.id.toString();
        if (shopId.toString() === id) {
            return data.name;
        } else {
            return "";
        }
    }))



    return (
        <Box>


            <Typography variant='h4' color="info.main" sx={{ textAlign: "center", marginBottom: "10px" }} >{t("Filters").toUpperCase()}</Typography>


            <Box sx={{ display: 'flexWrap', flexFlow: "row", justifyContent: "center", alignItems: "center", marginTop: "5px", marginBottom: "1px", marginRight: "10px", marginLeft: "-5px" }}>


                <Button onClick={(e) => handleClickFiltersMenu(e)} id="BTNShopMenu" variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><StorefrontIcon />  <Divider sx={{ margin: 1 }} />{shopName}
                </Button>


                <Button onClick={() => handleClickOpenCalDialog(true)} variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><CalendarMonthIcon />  <Divider sx={{ margin: 1 }} />{startDateValue}/{endDateValue} </Button>
                <CalendarDialog open={openDialogStartCalDialog} isStartDate={true} onChangeDateFun={handeChangeDate} value={startDateValue} closeFun={handleCloseCalDialog} title={t("FromDate")} />
                <CalendarDialog open={openDialogEndCalDialog} isStartDate={false} minDate={startDateValue} onChangeDateFun={handeChangeDate} value={endDateValue} closeFun={handleCloseCalDialog} title={t("ToDate")} />


                {viewPage !== defultReprtsPageView[4] ? <Button onClick={handleClickFiltersMenu} id="BTNShiftName" variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><GroupIcon />  <Divider sx={{ margin: 1 }} />{shiftName === null ? t('AllWorkShifts') : t("Shift") + " " + getShiftName(shiftName)}</Button> : ""}
                {viewPage !== defultReprtsPageView[4] ? <Button onClick={handleClickFiltersMenu} id="BTNPriceType" variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><RoomServiceIcon />  <Divider sx={{ margin: 1 }} />{priceType === null ? t('AllServices') : getServiceName(priceType)}</Button> : ""}
                {viewPage === defultReprtsPageView[3] ? <Button onClick={handleClickFiltersMenu} id="BTNVoidServices" variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><CancelIcon />  <Divider sx={{ margin: 1 }} />{voidService === null ? "None" : getVoidServiceName(voidService)}</Button> : ""}
                {viewPage !== defultReprtsPageView[4] ? <Button onClick={handleClickFiltersMenu} id="BTNPaymentMethod" variant='outlined' sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}><PaymentsIcon />  <Divider sx={{ margin: 1 }} />{paymentMethod === null ? t("AllPaymentMethod") : getPaymentMethodName(paymentMethod)}</Button> : ""}

                <Menu
                    id="ShopMenuList"
                    aria-labelledby="ShopMenuList"
                    anchorEl={openMenuAnchor}
                    open={isOpenMenu}
                    onClose={handleCloseFilterMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',


                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box sx={{ minWidth: { xs: "330px", sm: "auto" } }}>

                        {openMenuAnchor !== null ? openMenuAnchor.id === "BTNShopMenu" ?


                            Account.response.shopsEntities.map((data, index) => (

                                <MenuItem sx={{ textAlign: "center" }} id="ShopName" key={index} onClick={(e) => handelChangeFilterMenu(e, data.id)}>{data.name}</MenuItem>

                            )) : ""
                            : ""
                        }



                        {openMenuAnchor !== null ? openMenuAnchor.id === "BTNShiftName" ?


                            <>

                                {ListShftNumberAndPriceType.map((data, index) => {
                                    if (data !== 0) {

                                        return <MenuItem id="ShiftName" key={index} onClick={(e) => handelChangeFilterMenu(e, index)}>{t("Shift") + " " + getShiftName(data)}</MenuItem>
                                    } else {

                                    }
                                })}
                                <MenuItem id="ShiftName" onClick={(e) => handelChangeFilterMenu(e, -1)}>{t("AllWorkShifts")}</MenuItem>
                            </>

                            : ""
                            : ""
                        }




                        {openMenuAnchor !== null ? openMenuAnchor.id === "BTNPriceType" ?


                            <>

                                {ListShftNumberAndPriceType.map((data, index) => {
                                    if (data !== 0) {


                                        return (<MenuItem id="PriceType" key={index} onClick={(e) => handelChangeFilterMenu(e, index)}>{getServiceName(data)}</MenuItem>)

                                    }


                                })}

                                <MenuItem id="PriceType" onClick={(e) => handelChangeFilterMenu(e, -1)}>{t('AllServices')}</MenuItem>
                            </>

                            : ""
                            : ""
                        }




                        {openMenuAnchor !== null ? openMenuAnchor.id === "BTNVoidServices" ?


                            <>

                                {ListShftNumberAndPriceType.map((data, index) => {
                                    if (data !== 0) {


                                        return (<MenuItem id="VoidServices" key={index} onClick={(e) => handelChangeFilterMenu(e, index)}>{getVoidServiceName(data)}</MenuItem>)

                                    }


                                })}

                                <MenuItem id="VoidServices" onClick={(e) => handelChangeFilterMenu(e, -1)}>{t('None')}</MenuItem>
                            </>

                            : ""
                            : ""
                        }




                        {openMenuAnchor !== null ? openMenuAnchor.id === "BTNPaymentMethod" ?


                            <>

                                {ListShftNumberAndPriceType.map((data, index) => {
                                    if (data !== 0 && data < 6) {


                                        return (<MenuItem id="PaymentMethod" key={index} onClick={(e) => handelChangeFilterMenu(e, index)}>{getPaymentMethodName(data)}</MenuItem>)

                                    }


                                })}

                                <MenuItem id="PaymentMethod" onClick={(e) => handelChangeFilterMenu(e, -1)}>{t('AllPaymentMethod')}</MenuItem>
                            </>

                            : ""
                            : ""
                        }



                    </Box>

                </Menu>

            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", minWidth: "100%", justifyContent: "space-around" }}>

                <Button variant='contained' onClick={updateSearchParms} sx={{ padding: 1, margin: 1, minWidth: "100%" }}><CachedIcon />  <Divider sx={{ margin: 1 }} />{t("FetchReports")}</Button>
            </Box>



            <Divider sx={{ marginTop: "20px" }} />



            <Typography variant='h6' color="info.main" sx={{ textAlign: "center", marginTop: "10px", marginBottom: "5px" }} >{getNameReport().toUpperCase()}</Typography>


            <ToggleButtonGroup
                color="primary"
                value={viewPage}
                ref={refToggleButtonGroup}
                exclusive
                onChange={handleChangeButtonGroup}
                aria-label="Platform"
                sx={{ width: "100%", overflow: "auto", cursor: "pointer", justifyContent: { lg: "center", md: "center" } }}

            >
                {defultReprtsPageView.map((pageName, index) => (<ToggleButton key={index} value={pageName}>{t(pageName)}</ToggleButton>))}

            </ToggleButtonGroup>

            <Box sx={{ marginTop: "20px", marginBottom: "30px" }} />







        </Box>
    )
}

export default FilterReportsComp