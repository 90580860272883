import React from 'react'

import { Avatar, Box, Divider, Fab, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';

import i18n from '../../../../i18n';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteDescribeReasonMessages, getDescribeReasonMessagesSetting, reset } from '../../../../redux/slice/Settings/DescribeReasonMessages/getAllDescribeReasonMessagesSettingSlices';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import AlertsDialog from '../../../Components/AlertsDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deleteDescribeReasonMessagesSetting } from '../../../../redux/slice/Settings/DescribeReasonMessages/deleteDescribeReasonMessagesSettingSlices';
import { t } from 'i18next';
import AddEditDescribeReasonMessages from './AddEditDescribeReasonMessages';






function DescribeReasonMessagesMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [searchParams] = useSearchParams();
  const [describeReasonMessagesList, SetDescribeReasonMessagesList] = useState([])
  const [EditRowData, setEditRowData] = useState(null);
  const shopId = searchParams.get("ShopId")
  const describeReasonMessagesReduser = useSelector((state) => state.describeReasonMessagesSettingReduser);
  const [deleteDescribeReasonMessagesPrgress, setDeleteDescribeReasonMessagesPrgress] = useState(null);
  const deleteDescribeReasonMessagesSettingReduser = useSelector((state) => state.deleteDescribeReasonMessagesSettingReduser);
  const [dalilogAlretObject, setDalilogAlretObject] = useState({
    open: false,
    msg: "",
    title: "",
    showButtontOne: false,
    buttontOneTitle: "",
    buttonOneFun: () => { },
    showButtontTow: false,
    buttontTowTitle: "",
    buttonTowFun: () => { }
  })
  let { viewSubPage } = useParams();
  const response = describeReasonMessagesReduser.response;
  const sortBy = "id";
  const sortOrder = "desc"
  const pageSize = 10;

  /// handel Remove Item From Reduser Item List and Rest delete items slices to defult
  useEffect(() => {
    if (deleteDescribeReasonMessagesSettingReduser.loading === 'succeeded') {


      const newListItems = describeReasonMessagesList.filter(function (obj) {
        return obj.id !== deleteDescribeReasonMessagesPrgress;
      });



      SetDescribeReasonMessagesList(newListItems)

      dispatch(deleteDescribeReasonMessages(deleteDescribeReasonMessagesPrgress))
      setDeleteDescribeReasonMessagesPrgress(null)
      //dispatch(RestdeleteItemSetting())

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDescribeReasonMessagesSettingReduser.loading])


  useEffect(() => {

    const pageNumber = 0
    dispatch(reset())
    setHasMore(true);
    setPageNumber(0);
    dispatch(getDescribeReasonMessagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    SetDescribeReasonMessagesList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, viewSubPage])


  useEffect(() => {

    dispatch(getDescribeReasonMessagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch])



  const moveNext = () => {
    if (describeReasonMessagesList.length < response.totalElements) {
      setPageNumber(pageNumber + 1)

    } else {
      setHasMore(false)
    }
  }


  useEffect(() => {


    if (describeReasonMessagesReduser.loading === 'succeeded') {

      SetDescribeReasonMessagesList([...describeReasonMessagesList, ...response.content])



    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [describeReasonMessagesReduser.loading])

  const refresh = () => {
    setHasMore(true);
    SetDescribeReasonMessagesList([]);
    setPageNumber(0);

  }

  if (describeReasonMessagesReduser.loading === "pending" && describeReasonMessagesList.length <= 0) {
    return (<Progress/>)
  }



  if (describeReasonMessagesReduser.loading === "failed") {
    return (<APIErorr reduser={describeReasonMessagesReduser} />)
  }



  if (viewSubPage === "DescribeReasonMessagesAddEdit" && describeReasonMessagesList.length > 0) {


    return <AddEditDescribeReasonMessages DescribeReasonMessages={EditRowData} />
  }









  const handEditButton = (index) => {

    setEditRowData(describeReasonMessagesList[index]);


    navigate({

      pathname: "/Dashboard/Settings/DescribeReasonMessages/DescribeReasonMessagesAddEdit",
      search: "?" + searchParams.toString()


    })
  }

  const handeleDeleteButtonAsync = (index) => async (dispatch) => {
    const dataObject = describeReasonMessagesList[index]

    setDalilogAlretObject({ ...dalilogAlretObject, "open": false })
    setDeleteDescribeReasonMessagesPrgress(dataObject.id)
    await dispatch(deleteDescribeReasonMessagesSetting({ shopId, dataObject }))
  }


  const handelAddNewButton = () => {
    setEditRowData(null);


    navigate({

      pathname: "/Dashboard/Settings/DescribeReasonMessages/DescribeReasonMessagesAddEdit",
      search: "?" + searchParams.toString()


    })
  }


  const handeleDeleteButton = (index) => {


    setDalilogAlretObject({
      ...dalilogAlretObject,
      "open": true,
      "title": t("Delete"),
      "msg": t("MSG7"),
      "showButtontTow": true,
      "buttontTowTitle": t("Yes"),
      "buttonTowFun": () => dispatch(handeleDeleteButtonAsync(index)),
      "showButtontOne": true,
      "buttontOneTitle": t("No"),
      "buttonOneFun": () => setDalilogAlretObject({ ...dalilogAlretObject, "open": false }),
    })




  }

  return (


    <Box >



      <List sx={{ marginTop: 2, height: "100%" }} >

        {describeReasonMessagesList !== undefined &&
          <InfiniteScroll
            dataLength={describeReasonMessagesList.length}
            next={moveNext}
            hasMore={hasMore}
            style={{ display: 'flex', flexDirection: "column" }} //To put endMessage and loader to the top.
            loader={describeReasonMessagesList.length > 0 && <Progress />}
            refreshFunction={refresh}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8593; Release to refresh</h3>
            }
            pullDownToRefreshThreshold={50}

          >

            {describeReasonMessagesList.map((data, index) => (
              <Box key={index}
                sx={{ marginBottom: 2, marginTop: 1 }}>
                <ListItem
                  className="list-item"

                  secondaryAction={
                    <Box sx={{ display: 'flex', flexDirection: "row", flexFlow: "wrap" }}>
                      <IconButton onClick={() => handEditButton(index)} edge="start" aria-label="edit" sx={{ display: 'flex', flexDirection: "column" }}>
                        <Avatar sx={{ bgcolor: "success.main" }} variant="square" >
                          <EditIcon />

                        </Avatar>
                        <Typography variant='body2'>{t("Edit")}</Typography>
                      </IconButton>

                      <IconButton onClick={() => handeleDeleteButton(index)} edge="end" aria-label="delete" sx={{ display: 'flex', flexDirection: "column" }}>
                        <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >

                          <DeleteIcon />

                        </Avatar>
                        <Typography variant='body2'>{t("Delete")}</Typography>

                      </IconButton>


                    </Box>



                  }


                >
           
                  <ListItemText
                    primary={data.msg}

                  />
                </ListItem>

                <ListItem>
                  <Box sx={{ width: "100%" }}>




                    {(deleteDescribeReasonMessagesPrgress === data.id && deleteDescribeReasonMessagesSettingReduser.loading === 'pending') ? <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <Progress isScrroling={false} />

                    </Box> : ""}


                    {(deleteDescribeReasonMessagesPrgress === data.id && deleteDescribeReasonMessagesSettingReduser.loading === 'failed' ) && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <APIErorr isScrroling={false} reduser={deleteDescribeReasonMessagesSettingReduser} />
                    </Box>
                    }



                  </Box>

                </ListItem>
                <Divider />
              </Box>

            ))}

          </InfiniteScroll>}


      </List>



      <Box >


        <Fab onClick={handelAddNewButton} sx={{ position: "fixed", bottom: (theme) => theme.spacing(1), right: i18n.dir() !== 'rtl' && 20, }} color="primary" aria-label="add" size='large'>
          <AddIcon />

        </Fab>
      </Box>


      <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

    </Box>


  )
}

export default DescribeReasonMessagesMobile