import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import AnimatedNumber from "animated-number-react";

function CashComp(props) {
    const { t } = useTranslation();

    const data = props.data;

    const shopInfo = props.shopInfo;




    return (

        <Box sx={{ display: "flex", flexWrap: "wrap", minWidth: "100%", justifyContent: "space-around" }}>

            <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                <CardContent  >
                    <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("PayByCard").toLocaleUpperCase()}</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>

                        <Typography variant='h4' color="secondary.dark" sx={{ textAlign: "center" }}>

                            <AnimatedNumber formatValue={n => n.toFixed(3)}
                                value={data.NetTotalCard.Total} duration="2000" />

                        </Typography>


                        <Typography variant='h6' color="error.dark" sx={{ textAlign: "center" }}>
                            {typeof shopInfo.currency === 'string' ? shopInfo.currency.toUpperCase() : shopInfo.currency}

                        </Typography>

                    </Box >



                    <Typography variant='subtitle1' color="warning.main" sx={{ textAlign: "center" }}>{t("Orders").toUpperCase()} : <AnimatedNumber formatValue={n => n.toFixed(0)}
                        value={data.NetTotalCard.Orders} duration="2000" /></Typography>

                </CardContent>



            </Card>

            <Card sx={{ minWidth: { lg: "45%", md: "45%", xs: "100%" }, margin: 1 }} >
                <CardContent  >
                    <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("TotalCash").toLocaleUpperCase()}</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>


                        <Typography variant='h4' color="success.light" sx={{ textAlign: "center" }}>
                            <AnimatedNumber formatValue={n => n.toFixed(3)}
                                value={data.NetTotal.Total} duration="2000" />

                        </Typography>


                        <Typography variant='h6' color="error.dark" sx={{ textAlign: "center" }}>
                            {typeof shopInfo.currency === 'string' ? shopInfo.currency.toUpperCase() : shopInfo.currency}

                        </Typography>



                    </Box>
                    <Typography variant='subtitle1' color="warning.main" sx={{ textAlign: "center" }}>{t("Orders").toUpperCase()} : <AnimatedNumber formatValue={n => n.toFixed(0)}
                        value={data.NetTotal.Orders} duration="2000" /></Typography>

                </CardContent>



            </Card>

            <Card sx={{ minWidth: { lg: "25%", md: "25%", xs: "100%" }, margin: 1 }}>
                <CardContent  >
                    <Typography variant='h6' color="primary.light" sx={{ textAlign: "center" }}>{t("PayCash").toLocaleUpperCase()}</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>

                        <Typography variant='h4' color="secondary.dark" sx={{ textAlign: "center" }}>
                            <AnimatedNumber formatValue={n => n.toFixed(3)}
                                value={data.NetTotalCash.Total} duration="2000" />

                        </Typography>
                        <Typography variant='h6' color="error.dark" sx={{ textAlign: "center" }}>
                            {typeof shopInfo.currency === 'string' ? shopInfo.currency.toUpperCase() : shopInfo.currency}

                        </Typography>

                    </Box>

                    <Typography variant='subtitle1' color="warning.main" sx={{ textAlign: "center" }}>{t("Orders").toUpperCase()} : <AnimatedNumber formatValue={n => n.toFixed(0)}
                        value={data.NetTotalCash.Orders} duration="2000" /></Typography>

                </CardContent>



            </Card>





        </Box>

    )
}

export default CashComp