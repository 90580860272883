import { Box, Typography } from '@mui/material'
import { t } from 'i18next';
import React from 'react'

import ReactApexChart from "react-apexcharts"
//import { useTheme } from 'styled-components';


function PieChartComp(props) {
   // const theme = useTheme();

    //const bigScreeen = useMediaQuery(theme.breakpoints.up('sm'));
    const bigScreeen = false ;





    let Dataobject = props.data;










    Dataobject.PriceArray.forEach((price, i) => {

        Dataobject.PriceArray[i] = +price.toFixed(3)




    })






    const horizontalAlign = () => {

        if (bigScreeen) {

            return "center"
        }


        return "center"

    }







    const data = {

        series: Dataobject.PriceArray,
        options: {
            labels: Dataobject.labelsArray,


            plotOptions: {
                pie: {
                    customScale: 1
                    ,
                    donut: {
                        labels: {
                            show: true,


                            name: {
                                show: true,
                            }
                            ,
                            value: {
                                show: true,

                            }
                            , total: {
                                show: true,
                                showAlways: false,
                                label: t('Total').toLocaleUpperCase(),

                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        let x = a + b
                                        return +x.toFixed(3) 
                                    }, 0)
                                },



                            },


                        }


                    }

                },
            },



            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    },
                    legend: {
                        show: true
                    }
                }
            }],



            chart: {
                type: 'donut',
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 0,
                    left: 0,
                    blur: 3,
                    color: '#000',
                    opacity: 0.2
                }, toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            }
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    },
                    autoSelected: 'zoom'
                },




            },
            colors: ['#2ecc71', '#f1c40f', '#e74c3c', '#3498db'],

            legend: {
                position: 'bottom',
                horizontalAlign: horizontalAlign(),

                markers: {
                    width: 30,
                    height: 30,
                    radius: 0,
                    colors: ['#2ecc71', '#f1c40f', '#e74c3c', '#3498db',]


                }

            }, dataLabels: { // add this part to remove %
                enabled: true,


            }




        },


    };





    return (


        <Box sx={{ minWidth: { lg: "45%", md: "45%", xs: "100%" } }}>

            <Typography variant='h6' color="info.main" sx={{marginBottom:"20px" , textAlign:"center"}}>{Dataobject.Name}</Typography>
            <ReactApexChart options={data.options} series={data.series} type="donut" width="100%" height="500" />

        </Box>


    )
}

export default PieChartComp