import { Dialog, DialogTitle, IconButton } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Calendar } from 'react-calendar';
import {  styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));





const Title = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



Title.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



function CalendarDialog(props) {

    const handleClose = () => props.closeFun(props.isStartDate);

    const ViewCalendar = () => {

        if (props.minDate !== undefined) {

            return (<Calendar value={new Date(props.value)}   minDate={new Date(props.minDate)}  onChange={(date) => props.onChangeDateFun(date, props.isStartDate)} />)

        }

        return (<Calendar value={new Date(props.value)} onChange={(date) => props.onChangeDateFun(date, props.isStartDate)} />)





    }







    return (

        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
        >


            <Title id="customized-dialog-title" onClose={handleClose}>
                {props.title}

            </Title>

            <ViewCalendar />






        </BootstrapDialog>



    )
}

export default CalendarDialog