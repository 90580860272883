import { Box, Button, Card, CardContent, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Tab, useTheme } from '@mui/material'
import React from 'react'
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { t } from 'i18next';
import { serviceName } from '../../../hepler';
import ItemsListWithCheckComp from '../Components/ItemsListWithCheckComp'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllMandatoryItemsSetting } from '../../../../redux/slice/Settings/MandatoryItems/getAllMandatoryItemsSettingSlices';
import { useSearchParams } from 'react-router-dom';
import { saveMandatoryItemsSetting } from '../../../../redux/slice/Settings/MandatoryItems/saveAllMandatoryItemsSettingSlices';
import AlertsDialog from '../../../Components/AlertsDialog';
import SuccessMsg from '../../../Components/SuccessMsg';
import Progress from '../../../Components/Progress';
import APIErorr from '../../../Components/APIErorr';

const ServicesMenu = (props) => {

    const ServiceName = serviceName

    const handelSaveAsync = props.handelSaveAsync;
    const saveMandatoryItemsSettingReduser = useSelector((sate) => sate.saveMandatoryItemsSettingReduser)
    const dispatch = useDispatch()



    const [dalilogAlretObject, setDalilogAlretObject] = useState(
        {
            open: false,
            msg: t("MSG9"),
            title: t("SaveChanges"),
            showButtontOne: true,
            buttontOneTitle: t("Yes"),
            buttonOneFun: () => { },
            showButtontTow: true,
            buttontTowTitle: t("No"),
            buttonTowFun: () => {
                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
            },
        }


    )

    const theme = useTheme();

    const [value, setValue] = props.curenetServiceUse

    const handleChange = (event, newValue) => {



        setDalilogAlretObject({
            ...dalilogAlretObject, buttonOneFun: async () => {

                setDalilogAlretObject({ ...dalilogAlretObject, open: false })
                await dispatch(handelSaveAsync())
                setValue(newValue);
            }, open: true
        })

    };

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    maxWidth: "100%",
                    bgcolor: 'background.paper',

                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },



                        }, [`& .${tabsClasses.scroller}`]: {
                            [theme.breakpoints.up('md')]: {

                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center"


                            }
                        },

                    }}
                >

                    {ServiceName.map((data, index) => (
                        index > 1 &&


                        <Tab label={t(data)} value={index} key={index} disabled={saveMandatoryItemsSettingReduser.loading === "pending"} />



                    ))}

                </Tabs>

                <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />
            </Box>
        </>
    )




}


const RedioButton = (props) => {




    const curenetServiceUse = props.curenetServiceUse
    const [dataObject, setDataObject] = props.control;

    const saveMandatoryItemsSettingReduser = useSelector((sate) => sate.saveMandatoryItemsSettingReduser)

    const handleChange = (e, value) => {




        setDataObject({ ...dataObject, radioCheck: value })



    }
    return (

        <FormControl value={dataObject.radioCheck} disabled={saveMandatoryItemsSettingReduser.loading === "pending"} >

            <RadioGroup row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ justifyContent: "center" }}

                value={dataObject.radioCheck}
                onChange={handleChange}
            >
                <FormControlLabel value="null" control={<Radio />} label={t("InActive")} />
                <FormControlLabel value="1" control={<Radio />} label={t("Active")} />
                {curenetServiceUse === 2 && <FormControlLabel value="2" control={<Radio />} label={t("ByTheNumberOfPeople")} />}
            </RadioGroup>
        </FormControl>

    )
}


function MandatoryItems() {


    const [curenetServiceUse, setCurenetServiceUse] = useState(2);
    const [searchParams] = useSearchParams();
    const shopId = searchParams.get("ShopId")
    const getAllMandatoryItemsSettingReduser = useSelector((sate) => sate.getAllMandatoryItemsSettingReduser)
    const saveMandatoryItemsSettingReduser = useSelector((sate) => sate.saveMandatoryItemsSettingReduser)

    const dispatch = useDispatch();
    const [dataObject, setDataObject] = useState({
        radioCheck: null,
        defaultItemsEntity: [],
    })




    useEffect(() => {

        const serviceType = curenetServiceUse

        dispatch(getAllMandatoryItemsSetting({ shopId, serviceType }))




    }, [shopId, curenetServiceUse, dispatch])




    useEffect(() => {
        if (getAllMandatoryItemsSettingReduser.loading === "succeeded") {
            const response = getAllMandatoryItemsSettingReduser.response;
            let radioCheckValue = null;
            switch (curenetServiceUse) {

                case 2: {

                    radioCheckValue = response.settingsEntity.tableDefaultItemType
                    if (response.settingsEntity.tableDefaultItem) {

                        if (response.settingsEntity.tableDefaultItemType === 2) {

                            radioCheckValue = 2;
                        } else {
                            radioCheckValue = 1
                        }

                    } else {

                        radioCheckValue = null
                    }

                    break;
                }


                case 3: {
                    if (response.settingsEntity.ondailDefaultItem) {

                        radioCheckValue = 1
                    } else {

                        radioCheckValue = null
                    }


                    break;
                }
                case 4: {

                    if (response.settingsEntity.deliveryDefaultItem) {

                        radioCheckValue = 1
                    } else {

                        radioCheckValue = null
                    }

                    break;
                }




                case 5: {

                    if (response.settingsEntity.drivethruDefaultItem) {

                        radioCheckValue = 1
                    } else {

                        radioCheckValue = null
                    }

                    break;
                }



                case 6: {

                    if (response.settingsEntity.onlineDefaultItem) {

                        radioCheckValue = 1
                    } else {

                        radioCheckValue = null
                    }

                    break;
                }

                default:
                    radioCheckValue = null

                    break;

            }
            setDataObject({
                defaultItemsEntity: response.defaultItemsEntity,
                settingsEntity: response.settingsEntity,
                radioCheck: radioCheckValue
            })
        }


    }, [getAllMandatoryItemsSettingReduser, curenetServiceUse])





    const handelSave = () => {


        let dataSaved = { defaultItemsEntity: dataObject.defaultItemsEntity };

        let settingsEntity = dataObject.settingsEntity;


        switch (curenetServiceUse) {


            case 2: {


                if (dataObject.radioCheck === 'null') {

                    settingsEntity = { ...settingsEntity, tableDefaultItemType: 0, tableDefaultItem: false }


                } else {


                    if (dataObject.radioCheck === "1") {

                        settingsEntity = { ...settingsEntity, tableDefaultItemType: 0, tableDefaultItem: true }


                    } else {

                        settingsEntity = { ...settingsEntity, tableDefaultItemType: 2, tableDefaultItem: true }

                    }

                }



                break;
            }



            case 3: {
                settingsEntity = { ...settingsEntity, ondailDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }


            case 4: {
                settingsEntity = { ...settingsEntity, deliveryDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }



            case 5: {
                settingsEntity = { ...settingsEntity, drivethruDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }



            case 6: {
                settingsEntity = { ...settingsEntity, onlineDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }




            default:
                break;


        }


        dataSaved = { ...dataSaved, "settingsEntity": settingsEntity }
        const serviceType = curenetServiceUse

        dispatch(saveMandatoryItemsSetting({ shopId, serviceType, dataSaved }))

    }



    const handelSaveAsync = () => async (dispatch) => {


        let dataSaved = { defaultItemsEntity: dataObject.defaultItemsEntity };

        let settingsEntity = dataObject.settingsEntity;


        switch (curenetServiceUse) {


            case 2: {


                if (dataObject.radioCheck === 'null') {

                    settingsEntity = { ...settingsEntity, tableDefaultItemType: 0, tableDefaultItem: false }


                } else {


                    if (dataObject.radioCheck === "1") {

                        settingsEntity = { ...settingsEntity, tableDefaultItemType: 0, tableDefaultItem: true }


                    } else {

                        settingsEntity = { ...settingsEntity, tableDefaultItemType: 2, tableDefaultItem: true }

                    }

                }



                break;
            }




            case 3: {
                settingsEntity = { ...settingsEntity, ondailDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }


            case 4: {
                settingsEntity = { ...settingsEntity, deliveryDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }



            case 5: {
                settingsEntity = { ...settingsEntity, drivethruDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }



            case 6: {
                settingsEntity = { ...settingsEntity, onlineDefaultItem: dataObject.radioCheck === null ? false : true }
                break;
            }




            default:
                break;


        }


        dataSaved = { ...dataSaved, "settingsEntity": settingsEntity }
        const serviceType = curenetServiceUse

        await dispatch(saveMandatoryItemsSetting({ shopId, serviceType, dataSaved }))

    }








    return (



        <Card sx={{ m: 1 }}>




            <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", gap: 2, m: 1 }}>
                {saveMandatoryItemsSettingReduser.loading === "succeeded" && <SuccessMsg msg={t("MSG3")} />}
                {saveMandatoryItemsSettingReduser.loading === "pending" && <Progress />}

                {saveMandatoryItemsSettingReduser.loading === "failed" && <APIErorr reduser={saveMandatoryItemsSettingReduser} />}


                <ServicesMenu curenetServiceUse={[curenetServiceUse, setCurenetServiceUse]} handelSaveAsync={handelSaveAsync} />



                <RedioButton curenetServiceUse={curenetServiceUse} control={[dataObject, setDataObject]} />

                <ItemsListWithCheckComp listType="0" control={[dataObject, setDataObject]} adapterTybe={curenetServiceUse} disabled={saveMandatoryItemsSettingReduser.loading === "pending"} />



                <Box >

                    <Divider sx={{ margin: 2 }} />

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                        <Button variant='text' onClick={handelSave} disabled={saveMandatoryItemsSettingReduser.loading === "pending"} >{t("Save")}</Button>




                    </Box>


                </Box>


            </CardContent>





        </Card>

    )
}

export default MandatoryItems