import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const addEditDescribeReasonMessagesSettings = createAsyncThunk(
    "addEditDescribeReasonMessagesSettings/addEditDescribeReasonMessagesSettings",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        const dataObject = dispatch.dataObject

        



        const { data } = await axios.post(url + "/Apps/API/CMS/Settings/DescribeReasonMessages/addEdit?ShopId="+shopId+"&DeviceId=0&QRKey=0", 
        {
           ...dataObject
        } 
         ,
          {
            withCredentials: true
          })
        return data;
      } catch (err) {
  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




  

  export const AddEditDescribeReasonMessagesSettingSlices = createSlice({

    name: "AddEditDescribeReasonMessagesSetting",
    initialState,
  
    reducers: {
      reset: () => initialState,  

  
  
  
    },
  
  
  
    extraReducers: {
      [addEditDescribeReasonMessagesSettings.pending]: (state) => {
        state.loading = 'pending';
      },
      [addEditDescribeReasonMessagesSettings.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [addEditDescribeReasonMessagesSettings.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })



  
  export const { reset } = AddEditDescribeReasonMessagesSettingSlices.actions
  
  export default AddEditDescribeReasonMessagesSettingSlices.reducer;