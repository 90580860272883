import { createTheme } from '@mui/material/styles';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import sx from '@mui/system/sx';


export const LoginTheme = createTheme({
  

    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {

                    background: "linear-gradient(12deg, rgba(67,100,175,1) 0%, rgba(79,171,217,1) 100%) !important",
                    width: '100%',
                    color: "white !important",
                    "&:active": {
                        background: "red"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7


                    }


                },
            },
        },

        MuiCheckbox: {
            defaultProps: {

                icon: <CircleOutlinedIcon fontSize='medium' />,
                indeterminateIcon: <CheckCircleIcon fontSize='medium' />,
                checkedIcon: <CheckCircleIcon fontSize='medium' sx={{ boxShadow: '0 0 9px blue', }} />


            }



        },

    },


});






export const DashboardTheme = (dir) => createTheme({



  

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#1976d2',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        customA: {
            light: '#ffa726' ,
            main: "#FFFFFF",
            dark: '#ef6c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
          }

    },


    direction: dir,



    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                contained: {

                    background: dir === "rtl" ? "linear-gradient(12deg, rgba(67,100,175,1) 100%, rgba(79,171,217,1) 100%) !important" : "linear-gradient(12deg, rgba(67,100,175,1) 0%, rgba(79,171,217,1) 100%)!important",
                    width: '100%',
                    color: "white !important",
                    "&:active": {
                        background: "red"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7


                    }


                },
            },
        },

        MuiCheckbox: {
            defaultProps: {

                icon: <CircleOutlinedIcon fontSize='medium' />,
                indeterminateIcon: <CheckCircleIcon color='#FFFFF' fontSize='medium' />,
                checkedIcon: <CheckCircleIcon color='#FFFFF' fontSize='medium' />






            },


            variants: [
                {
                    props: { variant: 'headCell' }
                    ,
                    style: {
                        color: "#FFFFFF",

                    },
                }
            ],



        },

        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(67,100,175,1)"
                }

            }



        },

        MuiTableCell: {
            styleOverrides: {

                head: sx( {

                    color: "#FFFFFF",
                    top: 0,
                    position: 'sticky',
                    zIndex: 2,
                    display: "table-cell",
                    backgroundColor: "rgba(67,100,175,1)",
                    vertical: "vertical",
                    padding: "16px",
                    textAlign: 'start',
                    fontSize :{
                        xs:"0.9rem",
                        md:"1.2rem"
                    
                    }

                }),
                body :sx({


                    textAlign: 'start',
                    fontSize :{
                        xs:"0.9rem",
                        md:"1rem"
                    
                    }

                }),
                
            }
        } ,

        MuiTypography : {
            styleOverrides :{

                subtitle1 :{
                    textAlign: 'start'


                    
                }




            }





        }
    },


    
});