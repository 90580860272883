import React from 'react'

import { Avatar, Box, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import i18n from '../../../../i18n';
import { getHexColor } from '../../../hepler';
import SquareIcon from '@mui/icons-material/Square';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteCategory, getCategoryPagesSetting, reset } from '../../../../redux/slice/Settings/Category/getAllCategoryPagesSettingSlices';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import AlertsDialog from '../../../Components/AlertsDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deleteCategorySetting } from '../../../../redux/slice/Settings/Category/deleteCategorySettingSlices';
import { t } from 'i18next';
import AddEditCategory from './AddEditCategory';
import CardErorr from '../../../Components/CardErorr';


const getAvatar = (value, isCategory) => {


  const dir = i18n.dir()

  if (value.color === 0) {
    return <Avatar alt={value.name} src={`data:image/jpeg;base64,${value.image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (value.color !== null) {



    return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(value.color), float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}





function CategoryMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [searchParams] = useSearchParams();
  const [categoryList, SetCategoryList] = useState([])
  const [EditRowData, setEditRowData] = useState(null);
  const shopId = searchParams.get("ShopId")
  const categoryReduser = useSelector((state) => state.categoryPagesSettingReduser);
  const [deleteCategoryPrgress, setDeleteCategoryPrgress] = useState(null);
  const deleteCategorySettingReduser = useSelector((state) => state.deleteCategorySettingReduser);
  const [dalilogAlretObject, setDalilogAlretObject] = useState({
    open: false,
    msg: "",
    title: "",
    showButtontOne: false,
    buttontOneTitle: "",
    buttonOneFun: () => { },
    showButtontTow: false,
    buttontTowTitle: "",
    buttonTowFun: () => { }
  })
  let { viewSubPage } = useParams();
  const response = categoryReduser.response;
  const sortBy = "id";
  const sortOrder = "desc"
  const pageSize = 20;

  /// handel Remove Item From Reduser Item List and Rest delete items slices to defult
  useEffect(() => {
    if (deleteCategorySettingReduser.loading === 'succeeded') {


      const newListItems = categoryList.filter(function (obj) {
        return obj.id !== deleteCategoryPrgress;
      });



      SetCategoryList(newListItems)

      dispatch(deleteCategory(deleteCategoryPrgress))
      setDeleteCategoryPrgress(null)
      //dispatch(RestdeleteItemSetting())

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCategorySettingReduser.loading])


  useEffect(() => {

    const pageNumber = 0
    dispatch(reset())
    setHasMore(true);
    setPageNumber(0);
    dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    SetCategoryList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, viewSubPage])


  useEffect(() => {

    dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch])



  const moveNext = () => {
    if (categoryList.length < response.totalElements) {
      setPageNumber(pageNumber + 1)

    } else {
      setHasMore(false)
    }
  }


  useEffect(() => {


    if (categoryReduser.loading === 'succeeded') {

      SetCategoryList([...categoryList, ...response.content])



    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryReduser.loading])

  const refresh = () => {
    setHasMore(true);
    SetCategoryList([]);
    setPageNumber(0);

  }



  if (categoryReduser.loading === "pending" && categoryList.length <= 0) {
    return (<Progress/>)
  }


  if (categoryReduser.loading === "failed") {
    return (<APIErorr reduser={categoryReduser} />)
  }



  if (viewSubPage === "CategoryAddEdit" && categoryList.length > 0) {


    return <AddEditCategory Category={EditRowData} />
  }


  








  const handEditButton = (index) => {

    setEditRowData(categoryList[index]);


    navigate({

      pathname: "/Dashboard/Settings/Category/CategoryAddEdit",
      search: "?" + searchParams.toString()


    })
  }

  const handeleDeleteButtonAsync = (index) => async (dispatch) => {
    const categoryObject = categoryList[index]

  

    setDalilogAlretObject({ ...dalilogAlretObject, "open": false })
    setDeleteCategoryPrgress(categoryObject.id)
    await dispatch(deleteCategorySetting({ shopId, categoryObject }))
  }


  const handelAddNewButton = () => {
    setEditRowData(null);


    navigate({

      pathname: "/Dashboard/Settings/Category/CategoryAddEdit",
      search: "?" + searchParams.toString()


    })
  }


  const handeleDeleteButton = (index) => {


    setDalilogAlretObject({
      ...dalilogAlretObject,
      "open": true,
      "title": t("Delete"),
      "msg": t("MSG4"),
      "showButtontTow": true,
      "buttontTowTitle": t("Yes"),
      "buttonTowFun": () => dispatch(handeleDeleteButtonAsync(index)),
      "showButtontOne": true,
      "buttontOneTitle": t("No"),
      "buttonOneFun": () => setDalilogAlretObject({ ...dalilogAlretObject, "open": false }),
    })




  }

  return (


    <Box >



      <List sx={{ marginTop: 2, height: "100%" }} >

        {categoryList !== undefined &&
          <InfiniteScroll
            dataLength={categoryList.length}
            next={moveNext}
            hasMore={hasMore}
            style={{ display: 'flex', flexDirection: "column" }} //To put endMessage and loader to the top.
            loader={categoryList.length > 0 && <Progress />}
            refreshFunction={refresh}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8593; Release to refresh</h3>
            }
            pullDownToRefreshThreshold={50}

          >

            {categoryList.map((data, index) => (
              <Box key={index}
                sx={{ marginBottom: 2, marginTop: 1 }}>
                <ListItem
                  className="list-item"

                  secondaryAction={
                    <Box sx={{ display: 'flex', flexDirection: "row", flexFlow: "wrap" }}>
                      <IconButton onClick={() => handEditButton(index)} edge="start" aria-label="edit" sx={{ display: 'flex', flexDirection: "column" }}>
                        <Avatar sx={{ bgcolor: "success.main" }} variant="square" >
                          <EditIcon />

                        </Avatar>
                        <Typography variant='body2'>{t("Edit")}</Typography>
                      </IconButton>

                      <IconButton onClick={() => handeleDeleteButton(index)} edge="end" aria-label="delete" sx={{ display: 'flex', flexDirection: "column" }}>
                        <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >

                          <DeleteIcon />

                        </Avatar>
                        <Typography variant='body2'>{t("Delete")}</Typography>

                      </IconButton>


                    </Box>



                  }


                >
                  <ListItemAvatar>
                    {getAvatar(data, false)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={data.name}

                  />
                </ListItem>

                <ListItem>
                  <Box sx={{ width: "100%" }}>




                    {(deleteCategoryPrgress === data.id && deleteCategorySettingReduser.loading === 'pending') ? <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <Progress isScrroling={false} />

                    </Box> : ""}


                    {(deleteCategoryPrgress === data.id && deleteCategorySettingReduser.loading === 'failed' && deleteCategorySettingReduser.httpCode !== 409) && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <APIErorr isScrroling={false} reduser={deleteCategorySettingReduser} />
                    </Box>
                    }



                    {(deleteCategoryPrgress === data.id && deleteCategorySettingReduser.loading === 'failed' && deleteCategorySettingReduser.httpCode === 409) && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <CardErorr Msg={t("MSG6")} />
                    </Box>
                    }
                  </Box>

                </ListItem>
                <Divider />
              </Box>

            ))}

          </InfiniteScroll>}


      </List>



      <Box >


        <Fab onClick={handelAddNewButton} sx={{ position: "fixed", bottom: (theme) => theme.spacing(1), right: i18n.dir() !== 'rtl' && 20, }} color="primary" aria-label="add" size='large'>
          <AddIcon />

        </Fab>
      </Box>


      <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

    </Box>


  )
}

export default CategoryMobile