
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';




import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';

export default function Progress(props) {

    let isScrroling = props.isScrroling; 
     if (isScrroling === null) {

        isScrroling = true ; 
     } 

    const { t } = useTranslation();

    const myRef = useRef(null)


    useEffect(() => {

        if (isScrroling) {
            myRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });  
        }
        

      }, [isScrroling])


    return (
        <Box   ref={myRef} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color="secondary" />
            <Typography>{t("Pleasewait")}</Typography>



        </Box>)
}




Progress.propTypes = {
    isScrroling : PropTypes.bool

}