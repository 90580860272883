import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';

import { useDispatch, useSelector } from 'react-redux';
import { getAllItemsSetting } from '../../../../redux/slice/Settings/Items/getAllItemsSettingSlices';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Progress from '../../../Components/Progress';
import APIErorr from '../../../Components/APIErorr';
import i18n from '../../../../i18n';
import SquareIcon from '@mui/icons-material/Square';
import { getAllCategorySetting, getAllCategorySettingAndItems, reset } from '../../../../redux/slice/Settings/Category/getAllCategorySettingSlices';
import { getHexColor } from '../../../hepler';
import { t } from 'i18next';



const getAvatar = (value, isCategory) => {


  const dir = i18n.dir()

  if (value.color === 0) {
    return <Avatar alt={value.name} src={`data:image/jpeg;base64,${value.image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (value.color !== null) {



    return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(value.color), float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}




const CategoryMenu = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const disabled = props.disabled



  const categoryList = props.data.categoryList



  const categoryId = searchParams.get("CategoryId") || '';


  const handleChange = (event) => {
    const value = event.target.value;
    if (value === '') {

      searchParams.delete("CategoryId")
      setSearchParams(searchParams)


    } else {

      searchParams.set("CategoryId", value)
      setSearchParams(searchParams)


    }
  };



  return (
    <>
      <FormControl disabled={disabled} fullWidth sx={{ margin: 1 }}>
        <Select
          value={categoryId}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={''}>
            <em>{t("NotSpecified")}</em>
          </MenuItem>


          {categoryList.map((data, index) => (

            <MenuItem key={index} value={data.id}>
              <Box sx={{ display: "flex" }}>
                {getAvatar(data, true)}
                <Typography sx={{ marginLeft: 2 }}>{data.name}</Typography>

              </Box>
            </MenuItem>



          ))}
        </Select>
      </FormControl>

    </>
  )




}






function ItemsListWithCheckComp(props) {

  const dispatch = useDispatch();


  const attributeName = [["adapterTybe", "defaultItemsEntity"], ["monitorId", "monitorItemsSettingsEntityList"]]


  const [searchParams, setSearchParams] = useSearchParams();
  const [ItemsList, setItemsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [categoryList, SetCategoryList] = useState([])

  const disabled = props.disabled;
  const [dataObject, setDataObject] = props.control

  const listType = Number(props.listType);

  const lischeck = props["control"][0][attributeName[listType][1]]
  const adapterTybe = props[attributeName[listType][0]]


  const findIdOfItem = (id) => {
    const value = lischeck.find((element) => (element.itemId === id));
    if (value !== undefined) {
      return true;
    }
    return false;
  }




  const handelChangeList = (e, cheked) => {
    const id = Number(e.target.value);
    if (cheked) {
      const data = { "id": 0, "itemId": id, [attributeName[listType][0]]: adapterTybe }
      let tempList = dataObject[attributeName[listType][1]]
      tempList = [...tempList, data];
      setDataObject({ ...dataObject, [attributeName[listType][1]]: tempList })
    } else {


      const tempList = dataObject[attributeName[listType][1]].filter(function (obj) {
        return obj.itemId !== id;
      });


      setDataObject({ ...dataObject, [attributeName[listType][1]]: tempList })
    }

  }





  const shopId = searchParams.get("ShopId")
  const categoryId = searchParams.get("CategoryId") || null;
  const itemsListReduser = useSelector((state) => state.getAllItemsSettingReduser);
  const categoryReduser = useSelector((state) => state.getAllCategorySettingReduser);
  const response = itemsListReduser.response;
  const sortBy = "id";
  const sortOrder = "desc"
  const pageSize = 20;










  useEffect(() => {

    const pageNumber = 0
    dispatch(reset())
    setHasMore(true);
    setItemsList([]);
    SetCategoryList([])
    setPageNumber(0);
    dispatch(getAllCategorySettingAndItems({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))
    setItemsList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, categoryId, adapterTybe])

  useEffect(() => {

    searchParams.delete("CategoryId")
    setSearchParams(searchParams)

    dispatch(getAllCategorySetting({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId])



  useEffect(() => {

    if (categoryReduser.loading === "succeeded") {

      dispatch(getAllItemsSetting({ shopId, categoryId, pageNumber, pageSize, sortBy, sortOrder }))
    } else {


    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch])


  useEffect(() => {

    if (categoryReduser.loading === 'succeeded') {



      SetCategoryList(categoryReduser.response)

    }
  }, [categoryReduser, SetCategoryList])



  const moveNext = () => {

    if (ItemsList.length < response.totalElements) {
      setPageNumber(pageNumber + 1)

    } else {
      setHasMore(false)
    }
  }



  useEffect(() => {


    if (itemsListReduser.loading === 'succeeded') {
      let addedList = ItemsList;
      addedList =  response.content.filter((obj2) => {
        let checkAddNew = true ; 
         ItemsList.forEach((obj) => {
            if ( obj2.id === obj.id){
              checkAddNew  = false ; 
            }
          })
          return  checkAddNew && obj2
      })
      setItemsList([...ItemsList, ...addedList])



    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsListReduser.loading])








  if (categoryReduser.loading === "failed") {
    return (<APIErorr reduser={categoryReduser} />)
  }

  if (((categoryReduser.loading === "pending" || categoryReduser.loading === "idle")) || shopId === null) {


    return (<Progress />)
  }


  if (itemsListReduser.loading === "failed") {
    return (<APIErorr reduser={itemsListReduser} />)
  }











  return (
    <Card >

      <CardHeader subheader={

        <CategoryMenu disabled={disabled} data={{ categoryList }} />


      }>


      </CardHeader>

      <Divider sx={{ margin: 2 }} />




      <CardContent id="scrollableDiv" sx={{ height: 500, overflow: !disabled ? 'auto' : 'hidden' }}>






        <List sx={{ mr: -2, ml: -2 }}  >

          {ItemsList !== undefined &&
            <InfiniteScroll
              dataLength={ItemsList.length}
              next={moveNext}
              hasMore={hasMore}
              style={{ display: 'flex', flexDirection: "column" }} //To put endMessage and loader to the top.
              loader={itemsListReduser.loading === "pending" && <Progress />}
              scrollableTarget="scrollableDiv"



            >

              {ItemsList.map((data, index) => (
                <Box key={index}
                  sx={{ marginBottom: 2, marginTop: 1 }}>
                  <ListItem
                    className="list-item"

                    secondaryAction={

                      <Box sx={{ display: 'flex', flexDirection: "row", flexFlow: "wrap" }}>
                        <FormGroup >
                          <FormControlLabel control={<Checkbox onChange={handelChangeList} disabled={disabled} value={Number(data.id)} checked={findIdOfItem(data.id)} />} labelPlacement="end"
                          />

                        </FormGroup>



                      </Box>


                    }


                  >
                    <ListItemAvatar>
                      {getAvatar(data, false)}
                    </ListItemAvatar>
                    <ListItemText
                      primary={data.name}

                    />
                  </ListItem>


                  <Divider />
                </Box>

              ))}

            </InfiniteScroll>}


        </List>


      </CardContent>



    </Card>

  )
}

export default ItemsListWithCheckComp