
import { Paper, Typography } from '@mui/material';



const CardErorr = ({ Msg }) => {


    return <Paper elevation={4} margin={2} sx={{ minWidth:"90%" , paddingTop:"4px",display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(211, 89, 67, 32%)', marginTop: "10px" , marginBottom: "10px" }} ><Typography sx={{maxWidth:"90%" , maxHeight: "90%" , textAlign :"center"}} variant='body1'>{Msg}</Typography></Paper>



}


export default CardErorr; 