import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const addEditCategorySettings = createAsyncThunk(
    "addEditCategorySettings/addEditCategorySettings",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        const CategoryObject = dispatch.categorObject

        



        const { data } = await axios.post(url + "/Apps/API/CMS/Settings/Category/addEdit?ShopId="+shopId+"&DeviceId=0&QRKey=0", 
        {
            "id": CategoryObject.id,
            "name": CategoryObject.name,
            "tybe": CategoryObject.tybe,
            "image": CategoryObject.image,
            "color": CategoryObject.color,
        } 
         ,
          {
            withCredentials: true
          })
        return data;
      } catch (err) {
  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




  

  export const AddEditCategorySettingSlices = createSlice({

    name: "AddEditCategorySetting",
    initialState,
  
    reducers: {
      reset: () => initialState,  

  
  
  
    },
  
  
  
    extraReducers: {
      [addEditCategorySettings.pending]: (state) => {
        state.loading = 'pending';
      },
      [addEditCategorySettings.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [addEditCategorySettings.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })



  
  export const { reset } = AddEditCategorySettingSlices.actions
  
  export default AddEditCategorySettingSlices.reducer;