import React from 'react'

import { Avatar, Box, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import i18n from '../../../../i18n';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteUserPermissions, getUserPermissionsSetting, reset } from '../../../../redux/slice/Settings/UserPermissions/getAllUserPermissionsSettingSlices';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import AlertsDialog from '../../../Components/AlertsDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deleteUserPermissionsSetting } from '../../../../redux/slice/Settings/UserPermissions/deleteUserPermissionsSettingSlices';
import { t } from 'i18next';
import AddEditUserPermissions from './AddEditUserPermissions';






function UserPermissionsMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [searchParams] = useSearchParams();
  const [userPermissionsList, SetUserPermissionsList] = useState([])
  const [EditRowData, setEditRowData] = useState(null);
  const shopId = searchParams.get("ShopId")
  const userPermissionsReduser = useSelector((state) => state.userPermissionsSettingReduser);
  const [deleteUserPermissionsPrgress, setDeleteUserPermissionsPrgress] = useState(null);
  const deleteUserPermissionsSettingReduser = useSelector((state) => state.deleteUserPermissionsSettingReduser);
  const [dalilogAlretObject, setDalilogAlretObject] = useState({
    open: false,
    msg: "",
    title: "",
    showButtontOne: false,
    buttontOneTitle: "",
    buttonOneFun: () => { },
    showButtontTow: false,
    buttontTowTitle: "",
    buttonTowFun: () => { }
  })
  let { viewSubPage } = useParams();
  const response = userPermissionsReduser.response;
  const sortBy = "id";
  const sortOrder = "desc"
  const pageSize = 10;

  /// handel Remove Item From Reduser Item List and Rest delete items slices to defult
  useEffect(() => {
    if (deleteUserPermissionsSettingReduser.loading === 'succeeded') {


      const newListItems = userPermissionsList.filter(function (obj) {
        return obj.id !== deleteUserPermissionsPrgress;
      });



      SetUserPermissionsList(newListItems)

      dispatch(deleteUserPermissions(deleteUserPermissionsPrgress))
      setDeleteUserPermissionsPrgress(null)
      //dispatch(RestdeleteItemSetting())

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserPermissionsSettingReduser.loading])


  useEffect(() => {

    const pageNumber = 0
    dispatch(reset())
    setHasMore(true);
    setPageNumber(0);
    dispatch(getUserPermissionsSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    SetUserPermissionsList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, viewSubPage])


  useEffect(() => {

    dispatch(getUserPermissionsSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch])



  const moveNext = () => {
    if (userPermissionsList.length < response.totalElements) {
      setPageNumber(pageNumber + 1)

    } else {
      setHasMore(false)
    }
  }


  useEffect(() => {


    if (userPermissionsReduser.loading === 'succeeded') {

      SetUserPermissionsList([...userPermissionsList, ...response.content])



    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsReduser.loading])

  const refresh = () => {
    setHasMore(true);
    SetUserPermissionsList([]);
    setPageNumber(0);

  }

  if (userPermissionsReduser.loading === "pending" && userPermissionsList.length <= 0) {
    return (<Progress />)
  }



  if (userPermissionsReduser.loading === "failed") {
    return (<APIErorr reduser={userPermissionsReduser} />)
  }



  if (viewSubPage === "UserPermissionsAddEdit" && userPermissionsList.length > 0) {


    return <AddEditUserPermissions UserPermissions={EditRowData} />
  }









  const handEditButton = (index) => {

    setEditRowData(userPermissionsList[index]);


    navigate({

      pathname: "/Dashboard/Settings/UserPermissions/UserPermissionsAddEdit",
      search: "?" + searchParams.toString()


    })
  }

  const handeleDeleteButtonAsync = (index) => async (dispatch) => {
    const userPermissionsObject = userPermissionsList[index]

    setDalilogAlretObject({ ...dalilogAlretObject, "open": false })
    setDeleteUserPermissionsPrgress(userPermissionsObject.id)
    await dispatch(deleteUserPermissionsSetting({ shopId, userPermissionsObject }))
  }


  const handelAddNewButton = () => {
    setEditRowData(null);


    navigate({

      pathname: "/Dashboard/Settings/UserPermissions/UserPermissionsAddEdit",
      search: "?" + searchParams.toString()


    })
  }


  const handeleDeleteButton = (index) => {


    setDalilogAlretObject({
      ...dalilogAlretObject,
      "open": true,
      "title": t("Delete"),
      "msg": t("MSG16"),
      "showButtontTow": true,
      "buttontTowTitle": t("Yes"),
      "buttonTowFun": () => dispatch(handeleDeleteButtonAsync(index)),
      "showButtontOne": true,
      "buttontOneTitle": t("No"),
      "buttonOneFun": () => setDalilogAlretObject({ ...dalilogAlretObject, "open": false }),
    })




  }

  return (


    <Box >



      <List sx={{ marginTop: 2, height: "100%" }} >

        {userPermissionsList !== undefined &&
          <InfiniteScroll
            dataLength={userPermissionsList.length}
            next={moveNext}
            hasMore={hasMore}
            style={{ display: 'flex', flexDirection: "column" }} //To put endMessage and loader to the top.
            loader={userPermissionsList.length > 0 && <Progress />}
            refreshFunction={refresh}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>&# 8593; Release to refresh</h3>
            }
            pullDownToRefreshThreshold={50}

          >

            {userPermissionsList.map((data, index) => (
              <Box key={index}
                sx={{ marginBottom: 2, marginTop: 1 }}>
                <ListItem
                  className="list-item"

                  secondaryAction={
                    <Box sx={{ display: 'flex', flexDirection: "row", flexFlow: "wrap" }}>
                      <IconButton onClick={() => handEditButton(index)} edge="start" aria-label="edit" sx={{ display: 'flex', flexDirection: "column" }}>
                        <Avatar sx={{ bgcolor: "success.main" }} variant="square" >
                          <EditIcon />

                        </Avatar>
                        <Typography variant='body2'>{t("Edit")}</Typography>
                      </IconButton>


                      {data.accountType !== "Admin" &&

                        <IconButton onClick={() => handeleDeleteButton(index)} edge="end" aria-label="delete" sx={{ display: 'flex', flexDirection: "column" }}>
                          <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >

                            <DeleteIcon />

                          </Avatar>
                          <Typography variant='body2'>{t("Delete")}</Typography>

                        </IconButton>
                        }


                    </Box>



                  }


                >
                  <ListItemAvatar>
                    <Typography variant='subtitle1'> {data.userName}</Typography>
                  </ListItemAvatar>
                  <ListItemText
                    primary={data.name}

                  />
                </ListItem>

                <ListItem>
                  <Box sx={{ width: "100%" }}>




                    {(deleteUserPermissionsPrgress === data.id && deleteUserPermissionsSettingReduser.loading === 'pending') ? <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <Progress isScrroling={false} />

                    </Box> : ""}


                    {(deleteUserPermissionsPrgress === data.id && deleteUserPermissionsSettingReduser.loading === 'failed') && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                      <APIErorr isScrroling={false} reduser={deleteUserPermissionsSettingReduser} />
                    </Box>
                    }



                  </Box>

                </ListItem>
                <Divider />
              </Box>

            ))}

          </InfiniteScroll>}


      </List>



      <Box >


        <Fab onClick={handelAddNewButton} sx={{ position: "fixed", bottom: (theme) => theme.spacing(1), right: i18n.dir() !== 'rtl' && 20, }} color="primary" aria-label="add" size='large'>
          <AddIcon />

        </Fab>
      </Box>


      <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

    </Box>


  )
}

export default UserPermissionsMobile