import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../../utils/confg.json'
import { getAllItemsSetting ,   reset as RestItems } from "../Items/getAllItemsSettingSlices";
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const getAllCategorySetting = createAsyncThunk(
    "getAllCategorySetting/getAllCategorySetting",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        



        const { data } = await axios.get(url + "/Apps/API/CMS/Settings/Category/getAll?ShopId="+shopId+"&DeviceId=0&QRKey=0",
          {
            withCredentials: true
          })
        return data;
      } catch (err) {
  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




  export const getAllCategorySettingAndItems = (params) => async (dispatch) => {



    await dispatch(RestItems())
    await dispatch(getAllCategorySetting(params))
    return await dispatch(getAllItemsSetting(params))
  }
  

  export const GetAllCategorySettingSlices = createSlice({

    name: "GetAllCategorySetting",
    initialState,
  
    reducers: {
      reset: () => initialState,  

  
  
  
    },
  
  
  
    extraReducers: {
      [getAllCategorySetting.pending]: (state) => {
        state.loading = 'pending';
      },
      [getAllCategorySetting.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [getAllCategorySetting.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })



  
  export const { reset } = GetAllCategorySettingSlices.actions
  
  export default GetAllCategorySettingSlices.reducer;