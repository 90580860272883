import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'
import APIErorr from '../../../Components/APIErorr'
import Progress from '../../../Components/Progress'
import InvoicesComp from '../Components/InvoicesComp'



function InvoicesReports(props) {

  let Reports = {};
  const ShopInfo = props.ShopInfo ;

  const InvoicesReportsReduser = useSelector((state) => state.invoicesReportsReduser)



  if (InvoicesReportsReduser.httpCode === 200) {

    Reports = InvoicesReportsReduser.response;

}


if (InvoicesReportsReduser.loading === "failed") {
    return (<APIErorr reduser={InvoicesReportsReduser} />)
}


if (InvoicesReportsReduser.loading === "pending" || InvoicesReportsReduser.loading === "idle") {

    return (<Progress />)
}

  return (
    <Box>


        <InvoicesComp data={Reports} shopInfo={ShopInfo}/>





    </Box>
  )
}

export default InvoicesReports