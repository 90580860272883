import React from 'react'
import { Box, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, useTheme } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SquareIcon from '@mui/icons-material/Square';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteCategory, getCategoryPagesSetting } from '../../../../redux/slice/Settings/Category/getAllCategoryPagesSettingSlices';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import { getHexColor } from '../../../hepler';
import AddEditCategory from './AddEditCategory';
import { deleteCategorySetting, reset as RestdeleteCategorySetting } from '../../../../redux/slice/Settings/Category/deleteCategorySettingSlices';
import AlertsDialog from '../../../Components/AlertsDialog';
import CardErorr from '../../../Components/CardErorr';



function TablePaginationActions(props) {


    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={t("FirstPage")}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label={t("PreviousPage")}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("NextPage")}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("LastPage")}
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function createData(id, image, name, color) {
    return { id, image, name, color }

}

const headCells = [


    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        textAlignEnd: false,
        label: 'Id',
    },
    {
        id: 'image',
        numeric: true,
        disablePadding: false,
        textAlignEnd: false,
        label: 'Image',
    },


    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        textAlignEnd: false,
        label: 'CategoryName',
    },



    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        textAlignEnd: true,
        label: 'Actions',
    },
];



function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {


        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        variant='head'
                        sx={{ textAlign: headCell.textAlignEnd && 'End' }}
                    >

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label).toUpperCase()}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}





function AddEditDialog(props) {



    const [open, setOpen] = props.control
    const categoryData = props.data

    const dispatch = useDispatch();


    const addEditCategorySettingReduser = useSelector((state) => state.addEditCategorySettingReduser);

    const [saveCategoryFun, setsaveCategoryFun] = useState(() => { });

    const handleSave = () => {

        saveCategoryFun();

    }



    const handleClose = (isEdit) => {

        if (isEdit) {


            if (addEditCategorySettingReduser.loading === 'succeeded') {
                setOpen(false);
                const [shopId, pageNumber, pageSize, sortBy, sortOrder] = props.searchParamsData
                dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))


            }



        } else {

            setOpen(false);
            const [shopId, pageNumber, pageSize, sortBy, sortOrder] = props.searchParamsData
            dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))



        }



    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => handleClose(false)}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{categoryData === null ? t("AddNew") : categoryData.name}</DialogTitle>
                <DialogContent dividers={true}>

                    <AddEditCategory control={[setsaveCategoryFun, handleClose]} Category={categoryData} searchParamsData={props.searchParamsData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave}>{t("Save")}</Button>
                    <Button onClick={() => handleClose(false)}>{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



const getAvatar = (color, name, image, isCategory) => {



    if (color === 0) {
        return <Avatar alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

    }


    if (color !== null) {



        return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(color), marginLeft: 1, marginRight: 1 }} > </Avatar>


    }

    return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}

function CategoryDesktop() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState([]);
    const pageNumber = Number(searchParams.get(("PageNumber")) || 0)
    const pageSize = Number(searchParams.get(("PageSize")) || 5)
    const sortOrder = searchParams.get(("SortOrder")) || "desc"
    const sortBy = searchParams.get(("SortBy")) || "id"
    const [dense, setDense] = useState(true);
    const shopId = searchParams.get("ShopId")
    const categoryReduser = useSelector((state) => state.categoryPagesSettingReduser);
    const [isFisrtLunch, setFisrtLunch] = useState(true)
    const categoryList = []
    const [EditRowData, setEditRowData] = useState(null);
    const [deleteCategoryPrgress, setDeleteCategoryPrgress] = useState(null);
    const deleteCategorySettingReduser = useSelector((state) => state.deleteCategorySettingReduser);

    const [openDialog, setOpenDialog] = useState(false);
    const [dalilogAlretObject, setDalilogAlretObject] = useState({
        open: false,
        msg: "",
        title: "",
        showButtontOne: false,
        buttontOneTitle: "",
        buttonOneFun: () => { },
        showButtontTow: false,
        buttontTowTitle: "",
        buttonTowFun: () => { }
    })






    useEffect(() => {

        if (deleteCategorySettingReduser.loading === 'succeeded') {

            dispatch(deleteCategory(deleteCategoryPrgress))
            setDeleteCategoryPrgress(null)
            dispatch(RestdeleteCategorySetting())

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCategorySettingReduser.loading])






    useEffect(() => {
        if (!isFisrtLunch) {
            dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, pageSize, sortBy, sortOrder])

    useEffect(() => {
        dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isFisrtLunch) {
            const pageNumber = 0;
            const pageSize = 5;
            const sortBy = 'id';
            const sortOrder = 'desc';
            dispatch(getCategoryPagesSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
        }
        setFisrtLunch(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId])







    if (categoryReduser.loading === "failed") {
        return (<APIErorr reduser={categoryReduser} />)

    }



    if (categoryReduser.loading === "pending" || categoryReduser.loading === "idle") {


        return (<Progress />)
    }


    const totalElements = categoryReduser.response.totalElements


    const setPageNumber = (value) => {
        searchParams.set("PageNumber", value)
        setSearchParams(searchParams);
    }
    const setPageSize = (value) => {
        searchParams.set("PageSize", value)
        setSearchParams(searchParams);
    }

    const setSortOrder = (value) => {
        searchParams.set("SortOrder", value)
        setSearchParams(searchParams);
    }
    const setSortBy = (value) => {
        searchParams.set("SortBy", value)
        setSearchParams(searchParams);
    }


    categoryReduser.response.content.forEach((data) => {
        categoryList.push(createData(data.id, data.image, data.name, data.color))
    })





    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = categoryList.map((n) => n.name);


            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNumber(0);

    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const emptyRows = pageNumber > 0 ? Math.max(0, (1 + pageNumber) * pageSize - totalElements) : 0;



    const handleRequestSort = (event, property) => {

        if (property !== "actions") {
            const isAsc = sortBy === property && sortOrder === 'asc';

            setSortOrder(isAsc ? 'desc' : 'asc');
            setSortBy(property);
        }
    };


    const handEditButton = (index) => {
        setEditRowData(categoryReduser.response.content[index]);

        setOpenDialog(true)



    }


    const handeleDeleteButtonAsync = (index) => async (dispatch) => {
        const categoryObject = categoryReduser.response.content[index]
        setDalilogAlretObject({ ...dalilogAlretObject, "open": false })
        setDeleteCategoryPrgress(categoryObject.id)
        await dispatch(deleteCategorySetting({ shopId, categoryObject }))






    }

    const handeleDeleteButton = (index) => {


        setDalilogAlretObject({
            ...dalilogAlretObject,
            "open": true,
            "title": t("Delete"),
            "msg": t("MSG4"),
            "showButtontTow": true,
            "buttontTowTitle": t("Yes"),
            "buttonTowFun": () => dispatch(handeleDeleteButtonAsync(index)),
            "showButtontOne": true,
            "buttontOneTitle": t("No"),
            "buttonOneFun": () => setDalilogAlretObject({ ...dalilogAlretObject, "open": false }),
        })


    }




    const handAddNewButton = () => {
        setEditRowData(null);
        setOpenDialog(true)
    }


    return (


        <Box sx={{ width: '100%', padding: 2 }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={sortOrder}
                            orderBy={sortBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={categoryList.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {categoryList
                                .map((row, index) => {

                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell >{row.id}</TableCell>
                                                <TableCell >{getAvatar(row.color, row.name, row.image, false)}</TableCell>
                                                <TableCell >{row.name}</TableCell>
                                                <TableCell sx={{ textAlign: "end" }}>
                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>
                                                        <IconButton edge="start" aria-label="edit" onClick={() => handEditButton(index)} sx={{ display: 'flex', flexDirection: "column" }}>
                                                            <Avatar sx={{ bgcolor: "success.main" }} variant="square" >
                                                                <EditIcon />
                                                            </Avatar>
                                                            <Typography variant='body2'>{t("Edit")}</Typography>
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => handeleDeleteButton(index)} sx={{ display: 'flex', flexDirection: "column" }}>
                                                            <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >
                                                                <DeleteIcon />
                                                            </Avatar>
                                                            <Typography variant='body2'>{t("Delete")}</Typography>
                                                        </IconButton>


                                                    </Box>


                                                    {(deleteCategoryPrgress === row.id && deleteCategorySettingReduser.loading === 'pending') && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                                                        <Progress />

                                                    </Box>
                                                    }



                                                    {(deleteCategoryPrgress === row.id && deleteCategorySettingReduser.loading === 'failed' && deleteCategorySettingReduser.httpCode !== 409) && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                                                        <APIErorr reduser={deleteCategorySettingReduser} />
                                                    </Box>
                                                    }


                                                    {(deleteCategoryPrgress === row.id && deleteCategorySettingReduser.loading === 'failed' && deleteCategorySettingReduser.httpCode === 409) && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "end", flexFlow: "wrap" }}>

                                                        <CardErorr Msg={t("MSG6")} />
                                                    </Box>
                                                    }


                                                </TableCell>

                                            </TableRow>

                                        </React.Fragment>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={pageSize}

                    page={pageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    SelectProps={{
                        inputProps: {
                            'aria-label': t('RowsPerPage'),
                        },
                        native: true,
                    }}

                    ActionsComponent={TablePaginationActions}


                />
            </Paper>
            <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignContent: "end" }}>



                <Box sx={{ display: "flex" }}>



                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label={t("Densepadding")}
                    />
                    <Button onClick={handAddNewButton} variant='contained' sx={{ width: 100, height: 40, marginTop: 2 }}>{t("AddNew")}</Button>

                </Box>

            </Box>
            <AddEditDialog control={[openDialog, setOpenDialog]} searchParamsData={[shopId, pageNumber, pageSize, sortBy, sortOrder]} data={EditRowData} />
            <AlertsDialog controlObject={dalilogAlretObject} setFunc={setDalilogAlretObject} />

        </Box>

    )
}

export default CategoryDesktop