import React from 'react'
import { useSelector } from 'react-redux';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import StoreLogLitsComp from '../Components/StoreLogLitsComp'

function StoreLogsReports(props) {


  



  let Reports = {};
  const ShopInfo = props.ShopInfo;

  const StoreLogsReportsReduser = useSelector((state) => state.storeLogsReportsReduser)



  if (StoreLogsReportsReduser.httpCode === 200) {

    Reports = StoreLogsReportsReduser.response;

  }


  if (StoreLogsReportsReduser.loading === "failed") {
    return (<APIErorr reduser={StoreLogsReportsReduser} />)
  }


  if (StoreLogsReportsReduser.loading === "pending" || StoreLogsReportsReduser.loading === "idle") {

    return (<Progress />)
  }




  return (
    <StoreLogLitsComp data={Reports} shopInfo={ShopInfo}></StoreLogLitsComp>
  )
}

export default StoreLogsReports