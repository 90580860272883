import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react'
import { getColorItems, getHexColor, hexToRgbA } from '../../../hepler';
import CheckIcon from '@mui/icons-material/Check';

import { t } from 'i18next';


function ColorDialog(props) {

  const [open, setOpen] = props.control
  const [itemObject, setItemObject] = props.data
  const [selectIndex,setSelectIndex] = useState(itemObject.color)


  const handleClose = () => {
    setOpen(false);
  };

  const handelSelectColor =(data)=>{
    setSelectIndex(hexToRgbA(data.split("#")[1]))
  }
  const handleSave = () => {

    setItemObject({ ...itemObject, color :selectIndex });

    setOpen(false)

  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{t("SelectColor")}</DialogTitle>
        <DialogContent dividers={true}>

          <Box sx={{  display: "flex", flexFlow: 'wrap', flexDirection: "row-reverse", justifyContent: "center" ,rowGap:2 ,columnGap: 4}}>

            {getColorItems.map((data,index) => (
               <Avatar variant='square' alt={'color'} key={index}  onClick={()=>handelSelectColor(data,index)} sx={{ width:  40, height:  40, backgroundColor: data,boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}  >
                  {getHexColor(selectIndex).toUpperCase() === data.toUpperCase() &&  <CheckIcon />}
                 </Avatar>





            ))}

          </Box>

        </DialogContent>
        <DialogActions>
          <Button  onClick={handleSave}>{t("Save")}</Button>
          <Button  onClick={handleClose}>{t("Cancel")}</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ColorDialog


