
import { Paper, Typography } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


const SuccessMsg = ({ msg }) => {

    const myRef = useRef(null)


    useEffect(() => {
        myRef.current.scrollIntoView()    
      }, [])


    return <Paper ref={myRef}  color="secondary" elevation={4} margin={2} sx={{ minWidth:"90%" , paddingTop:"4px",display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'start', bgcolor: 'success.light', marginTop: "10px" , marginBottom: "10px" }} ><Typography color={"#FFFAFA"}   sx={{maxWidth:"90%" , maxHeight: "90%" , marginRight:2 ,  marginLeft:2 }}variant='body1'> <TaskAltIcon />  {msg}</Typography></Paper>



}


export default SuccessMsg; 